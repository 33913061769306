import React from 'react'
import { useTranslation } from 'react-i18next'

import HighRadar from '@/assets/DigitalReport/HighRadar.svg?react'
import LowRadar from '@/assets/DigitalReport/LowRadar.svg?react'
import MediumRadar from '@/assets/DigitalReport/MediumRadar.svg?react'
import { Card } from '@/components/ui/card'
import { EContentRadar } from '@/store/api/Person/Person.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface ContentRadarWidgetTypes {
  contentRadar?: EContentRadar
}

export const ContentRadarWidget = ({
  contentRadar,
}: ContentRadarWidgetTypes) => {
  const { t } = useTranslation()
  return (
    <Card className="bg-baseColor rounded-2xl p-4 flex flex-col gap-3">
      <p className="text-neutralColor text-xl font-medium">
        {t('dashboard.reportGeneralInfo.contentRadar')}
      </p>
      <div className="flex justify-between items-center max-md:gap-3">
        <LowRadar
          color={
            contentRadar === EContentRadar.LOW
              ? weCheckPalette.dynamicGreen
              : weCheckPalette.disabled
          }
        />
        <MediumRadar
          color={
            contentRadar === EContentRadar.MEDIUM
              ? weCheckPalette.dynamicYellow
              : weCheckPalette.disabled
          }
        />

        <HighRadar
          color={
            contentRadar === EContentRadar.HIGH
              ? weCheckPalette.dynamicRed
              : weCheckPalette.disabled
          }
        />
      </div>
      <p className="font-medium text-xs neutralColor80">
        {contentRadar === EContentRadar.LOW &&
          t('dashboard.reportGeneralInfo.minimalOrNoInteraction')}
        {contentRadar === EContentRadar.MEDIUM &&
          t('dashboard.reportGeneralInfo.occasionalInteraction')}
        {contentRadar === EContentRadar.HIGH &&
          t('dashboard.reportGeneralInfo.significantOrImpactfulInteraction')}
      </p>
    </Card>
  )
}
