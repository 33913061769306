import { Sort } from 'iconsax-react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { Separator } from '@/components/ui/separator'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface TopicsPopoverProps {
  coreBehaviors: { name: string; count: number; isFocusedTopic: boolean }[]
  focusedTopics: { name: string; count: number; isFocusedTopic: boolean }[]
  selectedTopic: string | 'all'
  onChangeTopic: (topic: string | 'all') => void
}

export const TopicsPopover = ({
  coreBehaviors,
  focusedTopics,
  selectedTopic,
  onChangeTopic,
}: TopicsPopoverProps) => {
  const { t } = useTranslation()

  const handleChangeTopic = (topic: string | 'all') => {
    if (topic !== selectedTopic) {
      onChangeTopic(topic)
    }
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          type="button"
          size={'icon'}
          asChild
          className="w-10 h-10 bg-transparent"
        >
          <div>
            <Sort color={weCheckPalette.neutralColor} />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-2 bg-baseColor rounded-lg flex flex-col gap-2">
        {coreBehaviors.length > 0 && (
          <div className="flex flex-col gap-2">
            <div className="tracking-widest border-b border-backgroundHighlight text-sm font-medium text-neutralColor80 text-nowrap pr-2 pl-2 pt-2">
              {t('Core Behavior Detection')}
            </div>
            <Separator className="border-neutralColor30 border-b-[0.5px]" />

            {coreBehaviors.map((behavior) => (
              <div
                key={behavior.name}
                role="button"
                tabIndex={0}
                onClick={() => handleChangeTopic(behavior.name)}
                className={`p-2 text-sm px-2 py-1.5 rounded ${
                  selectedTopic === behavior.name
                    ? 'bg-primaryColor30 font-semibold text-neutralColor'
                    : 'text-neutralColor60 font-normal hover:bg-primaryColor5'
                }`}
              >
                {t(`${behavior.name}`)}
              </div>
            ))}
          </div>
        )}
        {focusedTopics.length > 0 && (
          <div className="flex flex-col gap-2">
            <div className="tracking-widest border-b border-backgroundHighlight text-sm font-medium text-neutralColor80 text-nowrap pr-2 pl-2 pt-2">
              {t('Focused Topics')}
            </div>
            <Separator className="border-neutralColor30 border-b-[0.5px]" />
            {focusedTopics.map((topic) => (
              <div
                key={topic.name}
                role="button"
                tabIndex={0}
                onClick={() => handleChangeTopic(topic.name)}
                className={`p-2 text-sm px-2 py-1.5 rounded ${
                  selectedTopic === topic.name
                    ? 'bg-primaryColor30 font-semibold text-neutralColor'
                    : 'text-neutralColor60 font-normal hover:bg-primaryColor5'
                }`}
              >
                {t(`${topic.name}`)}
              </div>
            ))}
          </div>
        )}
        <Separator className="border-neutralColor30 border-b-[0.5px]" />
        <div
          role="button"
          tabIndex={0}
          onClick={() => handleChangeTopic('all')}
          className={`p-2 text-sm px-2 py-1.5 rounded  ${
            selectedTopic === 'all'
              ? 'bg-primaryColor30 font-semibold text-neutralColor'
              : 'text-neutralColor60 font-normal hover:bg-primaryColor5'
          }`}
        >
          {t('dashboard.postList.all')}
        </div>
      </PopoverContent>
    </Popover>
  )
}
