import React from 'react'

import { ESocialMedia } from '@/store/api/NewSearch/NewSearch.types'
import type {
  getSubjectBasicInformationResponse,
  ISentimentAnalysisResponse,
} from '@/store/api/Person/Person.types'
import { EReportType } from '@/store/api/Person/Person.types'

import { AvailableRecordsWidget } from '../Components/AvailableRecordsWidget'
import { ContentRadarWidget } from '../Components/ContentRadarWidget'
import { ContentSummaryWidget } from '../Components/ContentSummaryWidget'
import { PersonalityInsightsWidget } from '../Components/PersonalityInsightsWidget'
import { ProfessionalCirclesWidget } from '../Components/ProfessionalCirclesWidget'
import { SummaryWidget } from '../Components/SummaryWidget'

interface DigitalProfileTypes {
  data: getSubjectBasicInformationResponse
  reportType: EReportType
}

export const Analysis = ({ data, reportType }: DigitalProfileTypes) => {
  const summary = data?.info?.summary as ISentimentAnalysisResponse | null
  const isNewReport = data?.info?.summary
    ? 'contentRadar' in data?.info?.summary
    : false
  return (
    <div className="flex flex-col py-5 gap-5">
      {reportType !== EReportType.AdverseMedia && (
        <ContentSummaryWidget
          positivePosts={data?.info?.positivePosts || 0}
          negativePosts={data?.info?.negativePosts || 0}
          flaggedPosts={data?.info?.flaggedPosts || 0}
          webSearchPosts={
            data?.info?.channelsValue.find(
              (element) => element.channel === ESocialMedia.WEBSEARCH,
            )?.posts || 0
          }
        />
      )}
      {isNewReport && (
        <ContentRadarWidget
          contentRadar={isNewReport ? summary?.contentRadar : undefined}
        />
      )}
      {(data?.info.workEmails.length !== 0 ||
        data?.info.personalEmails.length !== 0 ||
        data?.info.phones.length !== 0 ||
        data?.info.address.length !== 0) && (
        <AvailableRecordsWidget data={data} />
      )}
      <ProfessionalCirclesWidget
        career={data.info.career}
        name={data.info.inputs.name}
      />
      {summary?.personalityInsights && (
        <PersonalityInsightsWidget
          personalityInsights={
            isNewReport ? summary.personalityInsights : undefined
          }
        />
      )}
      {summary && isNewReport && <SummaryWidget summary={summary} />}
    </div>
  )
}
