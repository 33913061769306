import { zodResolver } from '@hookform/resolvers/zod'
import { Copy, CloseSquare } from 'iconsax-react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import {
  AlertDialogHeader,
  AlertDialog,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertDialogCancel,
  AlertDialogAction,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useCreateApiKeyMutation } from '@/store/api/ApiKey/ApiKey.query'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import type { apiKeySchemaType } from '@/utils/schemas'
import { apiKeySchema } from '@/utils/schemas'

export const CreateApiKeyPopUp = () => {
  const { t } = useTranslation()

  const [createApiKey, { data: createApiKeyData, isLoading }] =
    useCreateApiKeyMutation()

  const [isOpen, setIsOpen] = useState(false)
  const [isSecondStep, setIsSecondStep] = useState(false)
  const [newApiKey, setNewApiKey] = useState<{
    name: string
    webhookUrl: string
  } | null>(null)

  const form = useForm<apiKeySchemaType>({
    resolver: zodResolver(apiKeySchema),
    defaultValues: {
      name: '',
      webhookUrl: '',
    },
    mode: 'onChange',
  })
  const { handleSubmit, control } = form

  const onSubmit = async (data: any) => {
    setNewApiKey({
      name: data.name,
      webhookUrl: data.webhookUrl,
    })

    try {
      await createApiKey({
        name: data.name,
        webhookUrl: data.webhookUrl,
      }).unwrap()
      toast.success(t('dashboard.apiKey.create.succes'))
      form.reset()

      setIsSecondStep(true)
    } catch (error: any) {
      if (error.data.code === 'API_KEY_ALREADY_EXISTS') {
        toast.error(t('dashboard.apiKey.create.alreadyExists'))
      } else {
        toast.error(t('dashboard.apiKey.create.error'))
        form.reset()
      }

      catchWithSentry(error)
    }
  }

  const handleCopy = () => {
    if (createApiKeyData?.data.apiKey) {
      navigator.clipboard.writeText(createApiKeyData.data.apiKey).then(() => {
        toast.success(t('dashboard.apiKey.create.copySuccess'))
      })
    }
  }

  return (
    <AlertDialog
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open)
        if (!open) {
          setIsSecondStep(false)
        }
      }}
    >
      <AlertDialogTrigger>
        <Button className="gap-2 rounded-3xl px-5 py-3" variant="outline">
          {t('dashboard.apiKey.create.createApiKey')}
        </Button>
      </AlertDialogTrigger>

      {!isSecondStep ? (
        <AlertDialogContent className="flex flex-col w-2/6 max-md:w-11/12">
          <AlertDialogHeader>
            <AlertDialogTitle className="flex flex-row rounded-3xl justify-between text-xl font-medium tracking-wide max-md:text-left max-md:text-[30px]">
              {t('dashboard.apiKey.create.createSecretKey')}
              <Button
                variant="ghost"
                onClick={() => {
                  setIsOpen(false)
                  setIsSecondStep(false)
                }}
                className="p-0 hover:bg-transparent"
              >
                <CloseSquare
                  size={24}
                  color={weCheckPalette.neutralColor}
                  className="cursor-pointer"
                />
              </Button>
            </AlertDialogTitle>

            <div>
              <p>{t('dashboard.apiKey.create.thisKeyIsAssigned')}</p>
            </div>
          </AlertDialogHeader>

          <Form {...form}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-5"
            >
              <div className="flex flex-col justify-start">
                <FormField
                  control={control}
                  name="name"
                  render={({ field, formState }) => (
                    <FormItem className="w-full rounded-md flex flex-col mb-6">
                      <FormLabel className="text-textNormal text-base font-medium tracking-tight">
                        {t('dashboard.company.renamePopUp.name')}
                      </FormLabel>

                      <FormControl className="focus:outline-none">
                        <Input
                          className="bg-transparent rounded-3xl"
                          {...field}
                          placeholder={t(
                            'dashboard.company.renamePopUp.newName',
                          )}
                        />
                      </FormControl>
                      {formState.errors.name && (
                        <FormMessage className="text-dynamicRed text-sm mt-1">
                          {formState.errors.name.message}
                        </FormMessage>
                      )}
                    </FormItem>
                  )}
                />
                <FormField
                  control={control}
                  name="webhookUrl"
                  render={({ field, formState }) => (
                    <FormItem className="w-full rounded-md flex flex-col mb-1">
                      <FormLabel className="text-textNormal text-base font-medium tracking-tight">
                        {t('dashboard.apiKey.create.webhookUrl')}
                      </FormLabel>

                      <FormControl className="focus:outline-none">
                        <Input
                          className="bg-transparent rounded-3xl"
                          {...field}
                          placeholder="Webhook URL"
                        />
                      </FormControl>
                      {formState.errors.webhookUrl && (
                        <FormMessage className="text-dynamicRed text-sm mt-1">
                          {formState.errors.webhookUrl.message}
                        </FormMessage>
                      )}
                    </FormItem>
                  )}
                />
                <p className="text-xs text-neutralColor60 pt-2">
                  {t('dashboard.apiKey.create.defineWebhookUrl')}
                </p>
              </div>

              <div className="flex flex-row justify-end gap-3">
                <AlertDialogCancel
                  className="rounded-3xl"
                  onClick={() => setIsOpen(false)}
                >
                  {t('dashboard.apiKey.create.cancel')}
                </AlertDialogCancel>

                <Button
                  className="rounded-3xl"
                  isLoading={isLoading}
                  type="submit"
                >
                  {t('dashboard.apiKey.create.createSecretKeyButton')}
                </Button>
              </div>
            </form>
          </Form>
        </AlertDialogContent>
      ) : (
        <AlertDialogContent className="flex flex-col w-2/6 max-md:w-11/12">
          <AlertDialogHeader>
            <AlertDialogTitle className="flex flex-row rounded-3xl justify-between text-xl font-medium tracking-wide max-md:text-left max-md:text-[30px]">
              {t('dashboard.apiKey.create.saveYourKey')}
              <Button variant="ghost" onClick={() => setIsOpen(false)}>
                x
              </Button>
            </AlertDialogTitle>

            <div>
              <p>{t('dashboard.apiKey.create.makeSureToStore')}</p>
            </div>
          </AlertDialogHeader>

          <div className="flex flex-row w-full gap-3 items-center">
            <div className="flex-grow">
              <Input
                className="bg-transparent rounded-3xl w-full"
                placeholder="Webhook Url"
                value={createApiKeyData?.data.apiKey}
                readOnly
              />
            </div>
            <Button
              className="gap-1 py-5 rounded-3xl w-1/4"
              onClick={handleCopy}
            >
              {t('dashboard.apiKey.create.copy')}
              <Copy size="21" color={weCheckPalette.neutralColor} />
            </Button>
          </div>

          <div className="flex flex-col gap-3">
            <p>{t('dashboard.apiKey.create.keyNameEditable')}</p>

            <p className="text-neutralColor60">
              {t('dashboard.apiKey.create.secretKeyName')}{' '}
              <p className="overflow-hidden text-ellipsis">{newApiKey?.name}</p>
            </p>

            <p className="text-neutralColor60">
              {t('dashboard.apiKey.create.webhookUrlExample')}{' '}
              <p className="overflow-hidden text-ellipsis">
                {newApiKey?.webhookUrl}
              </p>
            </p>
          </div>

          <div className="flex flex-row justify-end gap-3">
            <AlertDialogCancel
              className="rounded-3xl"
              onClick={() => {
                setIsOpen(false)
                setIsSecondStep(false)
              }}
            >
              {t('dashboard.apiKey.create.cancel')}
            </AlertDialogCancel>

            <AlertDialogAction
              className="rounded-3xl"
              type="button"
              onClick={() => setIsSecondStep(false)}
            >
              {t('dashboard.apiKey.create.done')}
            </AlertDialogAction>
          </div>
        </AlertDialogContent>
      )}
    </AlertDialog>
  )
}
