import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import AnalysisIcon from '@/assets/DigitalReport/AnalysisIcon.svg?react'
import DigitalProfileIcon from '@/assets/DigitalReport/DigitalProfileIcon.svg?react'
import MediaMentionsIcon from '@/assets/DigitalReport/MediaMentionsIcon.svg?react'
import PostsIcon from '@/assets/DigitalReport/PostsIcon.svg?react'
import emptyProfilePhoto from '@/assets/reportInfo/emptyProfilePhoto.png'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { Events } from '@/constants/events'
import useMediumMobileMediaQuery from '@/hooks/useMediumMobileMediaQuery'
import { useRudderStack } from '@/hooks/useRudderStack'
import { selectDigitalReportInfo } from '@/store/api/DigitalReport/digitalReport.selector'
import {
  setExternalFlagFilter,
  setExternalPlatformFilter,
  setExternalSentimentFilter,
  setSelectedTab,
} from '@/store/api/DigitalReport/digitalReport.slice'
import { useGetSubjectBasicInformationQuery } from '@/store/api/Person/Person.query'
import { EReportType } from '@/store/api/Person/Person.types'
import { selectUserInfo } from '@/store/api/Users/Users.selector'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { Actions } from './Components/Actions'
import { Header } from './Components/Header'
import { Analysis } from './Tabs/Analysis'
import { DigitalProfile } from './Tabs/DigitalProfile'
import { Posts } from './Tabs/Posts'

export const DigitalReport = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const reportId = state.id
  const subjectId = state.subjectId
  const { data, isLoading, isError } = useGetSubjectBasicInformationQuery({
    id: reportId,
  })

  const digitalReportInfo = useSelector(selectDigitalReportInfo)

  const isMobile = useMediumMobileMediaQuery()

  const userInfo = useSelector(selectUserInfo)

  const { trackEvent } = useRudderStack()

  useEffect(() => {
    dispatch(setSelectedTab('digitalProfile'))
    trackEvent(Events.REPORTS_VIEW, userInfo.userId, {
      email: userInfo.email,
    })
  }, [])

  useEffect(() => {
    if (
      digitalReportInfo.selectedTab === 'digitalProfile' ||
      digitalReportInfo.selectedTab === 'analysis'
    ) {
      dispatch(setExternalSentimentFilter('all'))
      dispatch(setExternalPlatformFilter('all'))
      dispatch(setExternalFlagFilter('all'))
    }
  }, [digitalReportInfo, dispatch])

  return (
    <WeScreen
      useNextButton={false}
      isLoading={isLoading}
      isError={isError}
      onClickBack={() => navigate(AppRoutes.reports)}
    >
      <div className="flex w-full items-center justify-center">
        <div
          className={`flex flex-col max-lg:w-full ${
            isMobile ? 'w-full' : 'max-lg:w-full max-xl:w-3/4 w-1/2'
          } gap-8`}
        >
          <Header
            fullName={data?.info.fullName ? data?.info.fullName : 'N/A'}
            followers={data?.info.followers}
            following={data?.info.following}
            posts={data?.info.posts}
            profileImage={
              data?.info.profileImgUrl
                ? data?.info.profileImgUrl
                : emptyProfilePhoto
            }
            children={
              <Actions
                id={reportId}
                pdf={data?.info.pdf}
                complianceSigned={data?.info.complianceSigned}
                summarySigned={data?.info.summarySigned}
                disclosureSigned={data?.info.disclosureSigned}
                dynamicSigned={data?.info.dynamicSigned}
                otherAuthorizationSigned={data?.info.otherAuthorizationSigned}
                subjectId={subjectId}
                anonymousPdf={data?.info.anonymousPdf}
              />
            }
            reportType={data?.info.reportType as EReportType}
          />
          <Tabs
            defaultValue={digitalReportInfo.selectedTab}
            onValueChange={(value) => dispatch(setSelectedTab(value))}
            value={digitalReportInfo.selectedTab}
          >
            <TabsList className="bg-transparent w-full flex">
              <TabsTrigger
                value="digitalProfile"
                className={`py-2 px-3 gap-2 ${
                  isMobile
                    ? 'text-xs font-medium h-8'
                    : 'text-base font-normal h-[38px]'
                } text-neutralColor60 data-[state=active]:text-neutralColor data-[state=active]:font-bold border-0 rounded-none w-full bg-transparent data-[state=active]:border-b data-[state=active]:border-neutralColor data-[state=active]:bg-transparent data-[state=active]:shadow-none`}
              >
                <DigitalProfileIcon
                  color={
                    digitalReportInfo.selectedTab === 'digitalProfile'
                      ? weCheckPalette.neutralColor
                      : weCheckPalette.disabled
                  }
                  className={`${isMobile ? 'w-3 h-3' : 'w-5 h-5'}`}
                />
                {t('dashboard.reportGeneralInfo.digitalProfile')}
              </TabsTrigger>
              <TabsTrigger
                value="analysis"
                className={`py-2 px-3 gap-2 ${
                  isMobile
                    ? 'text-xs font-medium h-8'
                    : 'text-base font-normal h-[38px]'
                } text-neutralColor60 data-[state=active]:text-neutralColor data-[state=active]:font-bold border-0 rounded-none w-full bg-transparent data-[state=active]:border-b data-[state=active]:border-neutralColor data-[state=active]:bg-transparent data-[state=active]:shadow-none`}
              >
                <AnalysisIcon
                  color={
                    digitalReportInfo.selectedTab === 'analysis'
                      ? weCheckPalette.neutralColor
                      : weCheckPalette.disabled
                  }
                  className={`${isMobile ? 'w-3 h-3' : 'w-5 h-5'}`}
                />
                {t('dashboard.reportGeneralInfo.analysis')}
              </TabsTrigger>
              <TabsTrigger
                value="posts"
                className={`py-2 px-3 gap-2 ${
                  isMobile
                    ? 'text-xs font-medium h-8'
                    : 'text-base font-normal h-[38px]'
                } text-neutralColor60 data-[state=active]:text-neutralColor data-[state=active]:font-bold border-0 rounded-none w-full bg-transparent data-[state=active]:border-b data-[state=active]:border-neutralColor data-[state=active]:bg-transparent data-[state=active]:shadow-none`}
              >
                {data?.info.reportType === EReportType.AdverseMedia ? (
                  <>
                    <MediaMentionsIcon
                      color={
                        digitalReportInfo.selectedTab === 'posts'
                          ? weCheckPalette.neutralColor
                          : weCheckPalette.disabled
                      }
                      className={`${isMobile ? 'w-3 h-3' : 'w-5 h-5'}`}
                    />
                    {t('dashboard.reportGeneralInfo.mediaMentions')}
                  </>
                ) : (
                  <>
                    <PostsIcon
                      color={
                        digitalReportInfo.selectedTab === 'posts'
                          ? weCheckPalette.neutralColor
                          : weCheckPalette.disabled
                      }
                      className={`${isMobile ? 'w-3 h-3' : 'w-5 h-5'}`}
                    />
                    {t('dashboard.reportGeneralInfo.posts')}
                  </>
                )}
              </TabsTrigger>
            </TabsList>
            {data && (
              <>
                <TabsContent value="digitalProfile">
                  <DigitalProfile
                    data={data}
                    reportId={reportId}
                    reportType={data?.info.reportType}
                    subjectId={subjectId}
                  />
                </TabsContent>
                <TabsContent value="analysis">
                  <Analysis data={data} reportType={data?.info.reportType} />
                </TabsContent>
                <TabsContent value="posts">
                  <Posts
                    reportId={reportId}
                    risks={data?.info.risks}
                    reportType={data?.info.reportType}
                  />
                </TabsContent>
              </>
            )}
          </Tabs>
        </div>
      </div>
    </WeScreen>
  )
}
