import { zodResolver } from '@hookform/resolvers/zod'
import { Edit } from 'iconsax-react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import {
  AlertDialogHeader,
  AlertDialog,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogTrigger,
  AlertDialogCancel,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useEditApiKeyMutation } from '@/store/api/ApiKey/ApiKey.query'
import type { ApiKey } from '@/store/api/ApiKey/ApiKey.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import type { apiKeySchemaType } from '@/utils/schemas'
import { apiKeySchema } from '@/utils/schemas'

export const EditApiKeyPopUp = ({ apiKey }: { apiKey: ApiKey }) => {
  const { t } = useTranslation()
  const [editApiKey, { isLoading }] = useEditApiKeyMutation()
  const [isOpen, setIsOpen] = useState(false)

  const form = useForm<apiKeySchemaType>({
    resolver: zodResolver(apiKeySchema),
    defaultValues: {
      name: apiKey.name,
      webhookUrl: apiKey.webhookUrl,
    },
    mode: 'onChange',
  })
  const { handleSubmit, control } = form

  const onSubmit = async (data: any) => {
    try {
      await editApiKey({
        name: data.name || apiKey.name,
        webhookUrl: data.webhookUrl || apiKey.webhookUrl,
        apiKeyId: apiKey._id,
      }).unwrap()
      toast.success(t('dashboard.apiKey.edit.succes'))
      setIsOpen(false)
    } catch (error: any) {
      if (error.data.code === 'API_KEY_ALREADY_EXISTS') {
        toast.error(t('dashboard.apiKey.edit.alreadyExists'))
      } else {
        toast.error(t('dashboard.apiKey.edit.error'))
      }

      catchWithSentry(error)
    }
  }

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogTrigger>
        <Button className="gap-2" variant="ghost">
          <Edit size="21" color={weCheckPalette.neutralColor} variant="Bold" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="flex flex-col w-2/6 max-md:w-11/12">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-[32px] font-medium tracking-wide max-md:text-left max-md:text-[30px]">
            {t('dashboard.apiKey.edit.editSecretKey')}
          </AlertDialogTitle>

          <AlertDialogDescription className="text-base font-medium  tracking-tight max-md:text-left">
            {t('dashboard.apiKey.edit.editSecretKeyText')}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <Form {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5"
          >
            <div className="flex flex-col justify-start">
              <FormField
                control={control}
                name="name"
                render={({ field, formState }) => (
                  <FormItem className="w-full rounded-md flex flex-col mb-6">
                    <FormLabel className="text-textNormal text-base font-medium tracking-tight">
                      {t('dashboard.company.renamePopUp.name')}
                    </FormLabel>

                    <FormControl className="focus:outline-none">
                      <Input
                        className="bg-transparent rounded-3xl"
                        {...field}
                        placeholder={t('dashboard.company.renamePopUp.newName')}
                      />
                    </FormControl>
                    {formState.errors.name && (
                      <FormMessage className="text-dynamicRed text-sm mt-1">
                        {formState.errors.name.message}
                      </FormMessage>
                    )}
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="webhookUrl"
                render={({ field, formState }) => (
                  <FormItem className="w-full rounded-md flex flex-col mb-1">
                    <FormLabel className="text-textNormal text-base font-medium tracking-tight">
                      {t('dashboard.apiKey.edit.webhookURL')}
                    </FormLabel>

                    <FormControl className="focus:outline-none">
                      <Input
                        className="bg-transparent rounded-3xl"
                        {...field}
                        placeholder="Webhook URL"
                      />
                    </FormControl>
                    {formState.errors.webhookUrl && (
                      <FormMessage className="text-dynamicRed text-sm mt-1">
                        {formState.errors.webhookUrl.message}
                      </FormMessage>
                    )}
                  </FormItem>
                )}
              />
              <p className="text-xs text-neutralColor60 pt-2">
                {t('dashboard.apiKey.edit.defineWebhookURL')}
              </p>
            </div>

            <div className="flex flex-row justify-end gap-3">
              <AlertDialogCancel
                className="rounded-3xl"
                onClick={() => setIsOpen(false)}
              >
                {t('dashboard.apiKey.edit.cancel')}
              </AlertDialogCancel>

              <Button
                className="rounded-3xl"
                type="submit"
                isLoading={isLoading}
              >
                {t('dashboard.apiKey.edit.continue')}
              </Button>
            </div>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  )
}
