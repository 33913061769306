import { createSlice } from '@reduxjs/toolkit'

export interface digitalReportState {
  externalSentimentFilter: string
  externalPlatformFilter: string
  selectedTab: string
  externalFlagFilter: string
}

export const digitalReportInitialState: digitalReportState = {
  externalSentimentFilter: 'all',
  externalPlatformFilter: 'all',
  selectedTab: 'digitalProfile',
  externalFlagFilter: 'all',
}

const digitalReport = createSlice({
  name: 'digitalReport',
  initialState: digitalReportInitialState,
  reducers: {
    setExternalSentimentFilter: (state, action) => {
      state.externalSentimentFilter = action.payload
    },
    setExternalPlatformFilter: (state, action) => {
      state.externalPlatformFilter = action.payload
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload
    },
    setExternalFlagFilter: (state, action) => {
      state.externalFlagFilter = action.payload
    },
  },
})
export const {
  setExternalSentimentFilter,
  setExternalPlatformFilter,
  setSelectedTab,
  setExternalFlagFilter,
} = digitalReport.actions

export default digitalReport.reducer
