import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { Events } from '@/constants/events'
import { useRudderStack } from '@/hooks/useRudderStack'
import {
  setExternalPlatformFilter,
  setExternalSentimentFilter,
  setSelectedTab,
} from '@/store/api/DigitalReport/digitalReport.slice'
import {
  FilterByTypeEnum,
  PlatformTypes,
} from '@/store/api/Person/Person.types'
import { selectUserInfo } from '@/store/api/Users/Users.selector'

import { TotalPostsCard } from './TotalPostsCard'

interface ContentSummaryWidgetTypes {
  positivePosts: number
  negativePosts: number
  flaggedPosts: number
  webSearchPosts: number
}

export const ContentSummaryWidget = ({
  negativePosts,
  positivePosts,
  flaggedPosts,
  webSearchPosts,
}: ContentSummaryWidgetTypes) => {
  const { t } = useTranslation()
  const userInfo = useSelector(selectUserInfo)
  const { trackEvent } = useRudderStack()
  const dispatch = useDispatch()

  const handleTrackEvent = (postType: string) => {
    trackEvent(Events.REPORTS_VIEW_POST_CATEGORY, userInfo.userId, {
      email: userInfo.email,
      post_category: postType,
    })

    dispatch(setSelectedTab('posts'))

    const filterBy =
      postType === 'Negative'
        ? FilterByTypeEnum.NEGATIVE
        : postType === 'Positive'
          ? FilterByTypeEnum.POSITIVE
          : FilterByTypeEnum.FLAGGED
    dispatch(setExternalPlatformFilter('all'))

    dispatch(setExternalSentimentFilter(filterBy))
  }

  const handleTrackEventWebNews = (postType: string) => {
    trackEvent(Events.REPORTS_VIEW_POST_CATEGORY, userInfo.userId, {
      email: userInfo.email,
      post_category: postType,
    })

    dispatch(setSelectedTab('posts'))
    dispatch(setExternalSentimentFilter('all'))
    dispatch(setExternalPlatformFilter(PlatformTypes.WEBSEARCH))
  }

  return (
    <div className="flex gap-5 flex-wrap justify-center">
      <TotalPostsCard
        sentiment={t('dashboard.postsInsights.flaggedPosts')}
        onClick={() => handleTrackEvent('Flagged')}
        totalPosts={flaggedPosts}
        isFlagged
      />
      <TotalPostsCard
        sentiment={t('dashboard.postsInsights.negativePosts')}
        onClick={() => handleTrackEvent('Negative')}
        totalPosts={negativePosts}
      />
      <TotalPostsCard
        sentiment={t('dashboard.postsInsights.positivePosts')}
        onClick={() => handleTrackEvent('Positive')}
        totalPosts={positivePosts}
      />
      <TotalPostsCard
        sentiment={t('Web/News Posts')}
        onClick={() => handleTrackEventWebNews('Web/News')}
        totalPosts={webSearchPosts}
      />
    </div>
  )
}
