import { AddCircle } from 'iconsax-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import {
  EUserPermission,
  EUserRole,
} from '@/hooks/usePermissions/permissions.type'
import { usePermissions } from '@/hooks/usePermissions/usePermissions'
import { selectEnterprise } from '@/store/api/Users/Users.selector'
import { useGetWorkSpacesQuery } from '@/store/api/workspace/workspace.query'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { ManageCompany } from './components/ManageCompany'
import { EWorkSpaceCardType, WorkSpaceCard } from './components/WorkSpaceCard'

export const Enterprise = () => {
  const { t } = useTranslation()
  const { hasPermission } = usePermissions()
  const navigate = useNavigate()

  const [isOpenManageEnterprise, setIsOpenManageEnterprise] = useState(false)

  const enterprise = useSelector(selectEnterprise)

  const { data, isLoading } = useGetWorkSpacesQuery()
  const workSpaces = data?.info.workspaces

  return (
    <WeScreen
      title={enterprise?.name ?? ''}
      useNextButton={false}
      useHeaderBackButton={false}
      isLoading={isLoading}
      rightSideHeaderContent={
        <>
          <Button
            variant="link"
            className="text-primaryColor"
            onClick={() => navigate(AppRoutes.roleGuide)}
          >
            {t('dashboard.company.roleGuide')}
          </Button>
          {hasPermission(
            EUserPermission.HANDLE_WORKSPACES,
            EUserRole.CO_OWNER,
          ) && (
            <Button onClick={() => setIsOpenManageEnterprise(true)}>
              {t('dashboard.company.manageCompany')}
            </Button>
          )}
        </>
      }
    >
      <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4">
        {workSpaces?.map((workSpace) => {
          const firstTeam = workSpace.teams[0]

          const nameOne =
            firstTeam.members[0]?.user?.firstname ??
            firstTeam.members[0]?.userEmail
          const nameTwo =
            firstTeam.members[1]?.user?.firstname ??
            firstTeam.members[1]?.userEmail

          return (
            <WorkSpaceCard
              title={workSpace.name}
              type={EWorkSpaceCardType.WORKSPACE}
              key={workSpace._id}
              teamNumber={workSpace.teams.length}
              totalWorkspaceMembers={workSpace.teams.reduce(
                (total, team) => total + team.members.length,
                0,
              )}
              nameOne={nameOne}
              nameTwo={nameTwo}
              primaryButtonText={t(
                'dashboard.company.workspaceCard.manageTeams',
              )}
              primaryButtonOnClick={() =>
                navigate(AppRoutes.teamSelection, { state: workSpace._id })
              }
              secondaryButtonText={t(
                'dashboard.company.workspaceCard.settings',
              )}
              secondaryButtonOnClick={() =>
                navigate(AppRoutes.workSpaceSettings, { state: workSpace._id })
              }
            />
          )
        })}

        {hasPermission(
          EUserPermission.HANDLE_WORKSPACES,
          EUserRole.CO_OWNER,
        ) && (
          <Button
            onClick={() => {
              navigate(AppRoutes.workSpaceCreation)
            }}
            className="bg-baseColor flex flex-col justify-center items-center p-4 gap-4 rounded-lg w-full h-[157px]"
          >
            <AddCircle size="32" color={weCheckPalette.neutralColor} />
            <p className=" text-sm text-neutralColor60">
              {t('dashboard.company.addOffice')}
            </p>
          </Button>
        )}
      </div>

      <ManageCompany
        isOpen={isOpenManageEnterprise}
        setIsOpen={setIsOpenManageEnterprise}
        name={enterprise?.name}
      />
    </WeScreen>
  )
}
