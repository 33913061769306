import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'

import { ConsentFileItem } from './ConsentFileItem'

interface ConsentFilesSheetTypes {
  isLoading?: boolean
  variant:
    | 'link'
    | 'default'
    | 'blue'
    | 'destructive'
    | 'outline'
    | 'secondary'
    | 'ghost'
    | null
    | undefined
  paddingLeft?: number
  customWidth?: string
  customJustifyContent?: string
  complianceSigned?: string | null
  summarySigned?: string | null
  disclosureSigned?: string | null
  dynamicSigned?: string | null
  otherAuthorizationSigned?: string | null
  isActionSelector?: boolean
}

export const ConsentFilesSheet = ({
  isLoading,
  variant,
  paddingLeft,
  customWidth,
  customJustifyContent,
  complianceSigned,
  summarySigned,
  disclosureSigned,
  dynamicSigned,
  otherAuthorizationSigned,
  isActionSelector = false,
}: ConsentFilesSheetTypes) => {
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()

  const handleClick = async (url: string | undefined) => {
    if (url) {
      window.open(url, '_blank')
    }
  }

  const files = [
    complianceSigned && {
      url: complianceSigned,
    },
    summarySigned && { url: summarySigned },
    disclosureSigned && {
      url: disclosureSigned,
    },
    dynamicSigned && { url: dynamicSigned },
    otherAuthorizationSigned && { url: otherAuthorizationSigned },
  ].filter(Boolean) as { url: string }[]

  return (
    <Sheet>
      <SheetTrigger
        disabled={isLoading}
        style={{ width: customWidth }}
        className={`${isActionSelector && 'w-full'}`}
      >
        <Button
          asChild
          variant={variant}
          className={`${
            isActionSelector
              ? 'border-none shadow-none p-0 w-full'
              : 'w-full rounded-none pl-2 justify-start cursor-pointer'
          }`}
          style={{
            paddingLeft: paddingLeft,
            width: customWidth,
            justifyContent: customJustifyContent,
          }}
        >
          <div>
            <p
              className={`${
                isActionSelector
                  ? 'relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2  outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5 text-left'
                  : ''
              }`}
            >
              {t('dashboard.reportsScreen.viewConsent')}
            </p>
          </div>
        </Button>
      </SheetTrigger>
      <SheetContent
        className="flex flex-col gap-8"
        side={isMobile ? 'bottom' : 'right'}
      >
        <SheetHeader className="flex flex-col gap-5">
          <SheetTitle className="text-textNormal text-start text-xl font-medium tracking-wide">
            {t('dashboard.reportsScreen.sheetTitle')}
          </SheetTitle>
          <SheetDescription className="text-textAccent text-start text-base font-normal tracking-tight">
            {t('dashboard.reportsScreen.sheetSubtitle')}
          </SheetDescription>
        </SheetHeader>
        <div className="flex flex-col justify-center items-start">
          {files.map((file, index) => (
            <ConsentFileItem
              key={index}
              fileName={`${t('dashboard.reportsScreen.file')}${index + 1}`}
              onClick={async () => handleClick(file.url)}
              withoutDivider={index === 0}
            />
          ))}
        </div>
      </SheetContent>
    </Sheet>
  )
}
