import { api, RTKTags } from '@/store/api'

import Env from '../../../env/env'

import type {
  createApiKeyRequest,
  CreateApiKeysResponse,
  deleteApiKeyRequest,
  EditApiKeyRequest,
  EditApKeyResponse,
  GetApiKeysResponse,
} from './ApiKey.types'

export const createTeamApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createApiKey: builder.mutation<CreateApiKeysResponse, createApiKeyRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/api-key`,
        method: 'POST',
        body: {
          name: request.name,
          webhookUrl: request.webhookUrl,
        },
      }),
      invalidatesTags: [RTKTags.apiKey],
    }),
    deleteApiKey: builder.mutation<void, deleteApiKeyRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/api-keys/${request.apiKeyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTags.apiKey],
    }),

    getApiKey: builder.query<GetApiKeysResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/api-keys`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: [RTKTags.apiKey],
    }),
    editApiKey: builder.mutation<EditApKeyResponse, EditApiKeyRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/api-key/edit`,
        method: 'POST',
        body: {
          name: request.name,
          webhookUrl: request.webhookUrl,
          apiKeyId: request.apiKeyId,
        },
      }),
      invalidatesTags: [RTKTags.apiKey],
    }),
  }),
})

export const {
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
  useGetApiKeyQuery,
  useEditApiKeyMutation,
} = createTeamApi
