export const ChartPallette = [
  '#F09436', // Orange
  '#F7CB45', // Yellow
  '#5CC8BE', // Mint
  '#357AF6', // Blue
  '#5856D6', // Indigo
  '#AF52DE', // Purple
  '#EA3354', // Pink
  '#FDBB6C', // Light Orange
  '#E6772A', // Dark Orange
  '#A9D985', // Lime Green
  '#50A690', // Emerald Green
  '#458588', // Teal
  '#81CEEB', // Sky Blue
  '#284B63', // Royal Blue
  '#C3B5E8', // Lavender
  '#D95F9B', // Magenta
  '#D53E4F', // Rose
  '#EEEEEE', // Light Gray
  '#333333', // Dark Gray
  '#FAF9F6', // Off-White/Cream
]
