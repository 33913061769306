import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import {
  EUserRole,
  EUserPermission,
} from '@/hooks/usePermissions/permissions.type'
import { usePermissions } from '@/hooks/usePermissions/usePermissions'
import { useUserInfoQuery } from '@/store/api/Users/Users.query'

import { Apikey } from './components/ApiKey/Apikey'
import { Enterprise } from './components/Enterprise/Enterprise'
import { Profile } from './components/Profile/Profile'
import { ReportSettings } from './components/ReportSettings/ReportSettings'

export const Settings = () => {
  const { data } = useUserInfoQuery()
  const { t } = useTranslation()
  const { hasPermission } = usePermissions()

  return (
    <WeScreen useHeader={false}>
      <div className="h-full pb-12 lg:pb-0 ">
        <Card>
          <Tabs defaultValue="account" className="rounded-2xl p-6">
            <TabsList className="flex lg:flex-row flex-col gap-2 lg:gap-0 items-start justify-start bg-transparent p-0 mb-5">
              <TabsTrigger
                value="account"
                className="px-4 py-3 mr-5 text-sm font-medium"
              >
                {t('dashboard.account.account')}
              </TabsTrigger>

              {data?.permissions.map(
                (item) =>
                  item === 'handle_enterprises' && (
                    <TabsTrigger
                      value="company"
                      className="px-4 py-3 mr-5 text-sm font-medium"
                    >
                      {t('dashboard.account.company')}
                    </TabsTrigger>
                  ),
              )}
              {hasPermission(
                EUserPermission.HANDLE_REPORTS,
                EUserRole.TEAM_ANALYST,
              ) && (
                <TabsTrigger
                  value="reportSettings"
                  className="px-4 py-3 mr-5 text-sm font-medium"
                >
                  {t('dashboard.account.reportSettingsTitle')}
                </TabsTrigger>
              )}

              <TabsTrigger
                value="apikey"
                disabled={
                  !hasPermission(
                    EUserPermission.HANDLE_CREDITS,
                    EUserRole.CO_OWNER,
                  )
                }
                className="px-4 py-3 mr-5 text-sm font-medium"
              >
                Api Key
              </TabsTrigger>
            </TabsList>
            <TabsContent value="account">
              <div className="mb-6">
                <Profile />
              </div>
            </TabsContent>
            <TabsContent value="company">
              <div className="mb-6">
                <Enterprise />
              </div>
            </TabsContent>
            <TabsContent value="reportSettings">
              <div className="mb-6">
                <ReportSettings />
              </div>
            </TabsContent>
            <TabsContent value="apikey">
              <div className="mb-6">
                <Apikey />
              </div>
            </TabsContent>
          </Tabs>
        </Card>
      </div>
    </WeScreen>
  )
}
