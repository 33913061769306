import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

import { useAppSelector } from '@/hooks/redux'
import type { ICareer } from '@/store/api/Person/Person.types'
import { selectIsDarkMode } from '@/store/api/ui/ui.selector'
import { ChartPallette } from '@/utils/palette/chartPallette'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface CareerChartTypes {
  career: ICareer[]
  durations: string[]
}

ChartJS.register(ArcElement, Tooltip, Legend)

export const CareerChart = ({ career, durations }: CareerChartTypes) => {
  const isDarkMode = useAppSelector(selectIsDarkMode)

  if (!Array.isArray(career)) return null

  const chartData = {
    labels: [],
    datasets: [
      {
        data: durations.map((duration) => duration),
        backgroundColor: ChartPallette,
        borderColor: isDarkMode ? '#ffffff' : weCheckPalette.neutralColor,
      },
    ],
  }

  return (
    <div className="flex justify-center items-center w-48 h-48">
      <Doughnut data={chartData} />
    </div>
  )
}
