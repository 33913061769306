import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import emptyProfilePhoto from '@/assets/reportInfo/emptyProfilePhoto.png'
import { EmptyState } from '@/components/EmptyState/EmptyState'
import { LottieSpinner } from '@/components/LottieSpinner/LottieSpinner'
import { Button } from '@/components/ui/button'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import useMediumMobileMediaQuery from '@/hooks/useMediumMobileMediaQuery'
import { useSmallDesktopQuery } from '@/hooks/useSmallDesktopQuery'
import { selectDigitalReportInfo } from '@/store/api/DigitalReport/digitalReport.selector'
import { setExternalSentimentFilter } from '@/store/api/DigitalReport/digitalReport.slice'
import {
  useGetSubjectBasicInformationQuery,
  useLazySocialMediaPostQuery,
} from '@/store/api/Person/Person.query'
import type {
  FilterByType,
  PlatformType,
  SocialMediaPost,
} from '@/store/api/Person/Person.types'

import type { FlaggedPopoverFilter } from './Components/FlaggedPopover'
import { FlaggedPopover } from './Components/FlaggedPopover'
import { Header } from './Components/Header'
import { PostCard } from './Components/PostCard'

export const SocialMediaProfile = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const reportId: string = state.reportId
  const subjectId: string = state.subjectId
  const platformFilter: PlatformType = state.socialMedia
  const socialMediaId: string = state.socialMediaId
  const { t } = useTranslation()
  const isSmallDesktop = useSmallDesktopQuery()
  const isMobile = useMediumMobileMediaQuery()
  const dispatch = useDispatch()

  const digitalReportInfo = useSelector(selectDigitalReportInfo)

  const [currentPage, setCurrentPage] = useState(1)

  const {
    data: subjectData,
    isLoading: isLoadingSubjectData,
    isError: isErrorSubjectData,
    isSuccess: isSuccessSubjectData,
    isFetching: isFetchingSubjectData,
  } = useGetSubjectBasicInformationQuery({
    id: reportId,
  })

  const [
    makeRequest,
    {
      data: postData,
      isLoading: isLoadingPostFirstTime,
      isFetching: isFechingPost,
      isError: isErrorPost,
    },
  ] = useLazySocialMediaPostQuery()

  const coreBehaviors =
    subjectData?.info.risks !== null
      ? subjectData?.info.risks.filter(
          (risk) => !risk.isFocusedTopic && risk.count > 0,
        )
      : []

  const isCoreBehavior = coreBehaviors?.some(
    (behavior) => behavior.name === digitalReportInfo.externalFlagFilter,
  )

  const makeBeRequest = useCallback(
    (page: number, currentFilterBy?: FilterByType) => {
      makeRequest({
        id: reportId,
        page: page,
        pageSize: 10,
        plataform: platformFilter as PlatformType,
        filterBy: currentFilterBy,
        socialMediaId: socialMediaId,
      })
    },
    [reportId, makeRequest],
  )

  useEffect(() => {
    setCurrentPage(1)

    const currentFilterBy: FilterByType | undefined =
      digitalReportInfo.externalSentimentFilter === 'all'
        ? undefined
        : (digitalReportInfo.externalSentimentFilter as FilterByType)

    makeBeRequest(currentPage, currentFilterBy)
  }, [digitalReportInfo.externalSentimentFilter])

  const hasMoreData = useMemo(
    () => (postData?.posts.offset && postData?.posts.offset > 0) as boolean,
    [postData],
  )

  const fetchMoreOnPagination = () => {
    const newPage = currentPage + 1
    setCurrentPage(newPage)
    const currentFilterBy: FilterByType | undefined =
      digitalReportInfo.externalSentimentFilter === 'all'
        ? undefined
        : (digitalReportInfo.externalSentimentFilter as FilterByType)

    makeBeRequest(newPage, currentFilterBy)
  }

  const isLoading =
    (isFechingPost && currentPage === 1) || isLoadingPostFirstTime

  return (
    <WeScreen
      isError={isErrorPost || isErrorSubjectData}
      isLoading={isLoadingSubjectData}
      onClickBack={() =>
        navigate(AppRoutes.digitalReport, {
          state: { id: reportId, subjectId },
        })
      }
      useNextButton={false}
    >
      <div className="flex flex-col w-full items-center pt-6">
        <div
          className={`${
            isMobile ? 'w-full' : 'max-lg:w-full max-xl:w-3/4 w-1/2'
          } flex flex-col gap-8`}
        >
          {!isLoading && !isFetchingSubjectData && isSuccessSubjectData && (
            <Header
              fullName={postData?.userName ? postData?.userName : 'N/A'}
              followers={postData?.followers}
              following={postData?.following}
              posts={postData?.postsCount}
              profileImage={
                postData?.profileImgUrl
                  ? postData?.profileImgUrl
                  : emptyProfilePhoto
              }
              children={
                <Button
                  className="rounded-full w-min h-[32px] max-md:p-1.5"
                  onClick={() => {
                    window.open(postData?.profileLink, '_blank')
                  }}
                >
                  <p className="text-xs font-bold ">
                    {t('dashboard.postList.visitProfile')}
                  </p>
                </Button>
              }
            />
          )}
          <div className="bg-baseColorHighlight p-4 rounded-2xl">
            <div className={`flex justify-between mb-8`}>
              <p className="text-textNormal text-xl font-medium tracking-wide">
                {platformFilter === 'WebSearch' ? 'Web/News' : platformFilter}
              </p>
              <div className="flex gap-2 items-center">
                <p className="text-neutralColor text-sm font-normal">
                  {t('dashboard.postList.sortBy')}
                </p>
                <FlaggedPopover
                  sortedBy={
                    digitalReportInfo.externalSentimentFilter as FlaggedPopoverFilter
                  }
                  onChangeSort={(sortBy: FlaggedPopoverFilter) =>
                    dispatch(setExternalSentimentFilter(sortBy))
                  }
                />
              </div>
            </div>
            {!isLoading && postData && postData.posts.list.length > 0 && (
              <InfiniteScroll
                className="flex flex-col gap-6 custom-scrollbar"
                dataLength={postData.posts.list.length}
                next={fetchMoreOnPagination}
                hasMore={hasMoreData}
                loader={<p>Loading...</p>}
                height={isSmallDesktop ? 430 : 620}
              >
                {postData.posts.list.map(
                  (post: SocialMediaPost, id: number) => (
                    <PostCard
                      post={post}
                      key={id}
                      isCoreBehavior={isCoreBehavior as boolean}
                    />
                  ),
                )}
              </InfiniteScroll>
            )}
            {isLoading && (
              <div className="flex justify-center ">
                <LottieSpinner />
              </div>
            )}
            {postData?.posts.list.length === 0 && !isLoading && (
              <div className="flex justify-center">
                <EmptyState text={t('dashboard.postList.emptyState')} />
              </div>
            )}
          </div>
        </div>
      </div>
    </WeScreen>
  )
}
