import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'
import type { Risk } from '@/store/api/Person/Person.types'

import { EmptyStateCard, EmptyStateCardIcon } from './EmptyStateCard'
import { FlagCard } from './FlagCard'

interface FlagsWidgetTypes {
  risks: Risk[]
}

export const FlagsWidget = ({ risks }: FlagsWidgetTypes) => {
  const { t } = useTranslation()

  const focusedTopics = useMemo(() => {
    if (!risks) return []
    return risks.filter((risk) => risk.isFocusedTopic)
  }, [risks])

  const coreBehaviors = useMemo(() => {
    if (!risks) return []
    return risks.filter((risk) => !risk.isFocusedTopic && risk.count > 0)
  }, [risks])

  return (
    <div className="flex flex-col gap-5">
      <Card className="flex flex-col p-4 gap-4 bg-baseColor">
        <p className="text-neutralColor text-xl font-medium">
          {t('dashboard.reportGeneralInfo.core')}
        </p>
        {coreBehaviors.length !== 0 ? (
          <div className="flex flex-col gap-3">
            {coreBehaviors.map((risk, index) => (
              <FlagCard name={risk.name} count={risk.count} key={index} />
            ))}
          </div>
        ) : (
          <EmptyStateCard
            title={t('dashboard.reportGeneralInfo.coreEmpty')}
            icon={EmptyStateCardIcon.WORLD}
          />
        )}
      </Card>

      <Card className="flex flex-col p-4 gap-4 bg-baseColor">
        <p className="text-neutralColor text-xl font-medium">
          {t('dashboard.reportGeneralInfo.focused')}
        </p>
        {focusedTopics.length !== 0 ? (
          <div className="flex flex-col gap-3">
            {focusedTopics.map((risk, index) => (
              <FlagCard
                name={risk.name}
                count={risk.count}
                key={index}
                isFocusedTopic
              />
            ))}
          </div>
        ) : (
          <EmptyStateCard
            title={t('dashboard.reportGeneralInfo.focusedEmpty')}
            icon={EmptyStateCardIcon.PERSON}
          />
        )}
      </Card>
    </div>
  )
}
