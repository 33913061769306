import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Burgermenu from '@/assets/burgerMenu.svg?react'
import { LottieSpinner } from '@/components/LottieSpinner/LottieSpinner'
import { Button } from '@/components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { AppRoutes } from '@/constants/appRoutes'
import { ESocialCheckStatus } from '@/store/api/Enterprise/Enterprise.types'
import type { ReportCheckData } from '@/store/api/workspace/workspace.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { ConsentFilesSheet } from './ConsentFilesSheet'

interface BurgerMenuProps {
  data: ReportCheckData
  isLoading: boolean
  isAnonymousPdfLoading?: boolean
  name: string
  onPressDownload: () => void
  onPressDowloadPrivatePdf: () => void
  initials: string
  hiddenDigitalReport?: boolean
}

export const BurgerMenu = ({
  data,
  isLoading,
  isAnonymousPdfLoading,
  onPressDownload,
  onPressDowloadPrivatePdf,
  name,
  hiddenDigitalReport = false,
}: BurgerMenuProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <>
      {isAnonymousPdfLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="">
            <LottieSpinner
              text={t('dashboard.reportsScreen.generatingPrivateReport')}
            />
          </div>
        </div>
      )}

      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button className="flex items-center justify-center" variant="ghost">
            <Burgermenu color={weCheckPalette.neutralColor} />
          </Button>
        </PopoverTrigger>

        <PopoverContent className="w-40 p-0 border-2 border-baseColorHighlight bg-baseColor rounded-md">
          <div className="flex flex-col justify-start items-start">
            <p className="w-full pl-2 py-2 border-b-2 border-baseColorHighlight">
              {name}
            </p>
            {!hiddenDigitalReport &&
              data.status === ESocialCheckStatus.Complete && (
                <Button
                  variant="ghost"
                  onClick={() => {
                    navigate(AppRoutes.reportGeneralInfo, {
                      state: { id: data.id, subjectId: data.subjectId },
                    })
                  }}
                  className="w-full rounded-none pl-2 justify-start"
                >
                  {t('dashboard.reportsScreen.view')}
                </Button>
              )}

            {data.pdf && (
              <>
                <Button
                  variant="ghost"
                  isLoading={isLoading}
                  onClick={() => {
                    onPressDownload()
                    setOpen(false)
                  }}
                  className="w-full rounded-none pl-2 justify-start cursor-pointer"
                >
                  {t('dashboard.reportsScreen.download')}
                </Button>

                <Button
                  variant="ghost"
                  onClick={() => {
                    onPressDowloadPrivatePdf()
                    setOpen(false)
                  }}
                  className="w-full rounded-none pl-2 justify-start cursor-pointer"
                >
                  {t('dashboard.reportsScreen.generatePrivatePDdf')}
                </Button>
              </>
            )}

            {data.complianceSigned && (
              <div className="w-full">
                <ConsentFilesSheet
                  isLoading={isLoading}
                  variant={'ghost'}
                  paddingLeft={8}
                  customWidth="100%"
                  customJustifyContent="flex-start"
                  complianceSigned={data?.complianceSigned}
                  summarySigned={data?.summarySigned}
                  disclosureSigned={data?.disclosureSigned}
                  dynamicSigned={data?.dynamicSigned}
                  otherAuthorizationSigned={data?.otherAuthorizationSigned}
                />
              </div>
            )}
          </div>
        </PopoverContent>
      </Popover>
    </>
  )
}
