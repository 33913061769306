import { Sort } from 'iconsax-react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import type { FilterByType } from '@/store/api/Person/Person.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export type FlaggedPopoverFilter = FilterByType | 'all'

export const FlaggedPopover = ({
  sortedBy,
  onChangeSort,
}: {
  sortedBy: FlaggedPopoverFilter
  onChangeSort: (sortBy: FlaggedPopoverFilter) => void
}) => {
  const { t } = useTranslation()

  const handleChangeSort = (sort: FlaggedPopoverFilter) => {
    if (sort !== sortedBy) {
      onChangeSort(sort)
    }
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          type="button"
          size={'icon'}
          asChild
          className="w-10 h-10 bg-transparent"
        >
          <div>
            <Sort color={weCheckPalette.neutralColor} />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[100px] p-2 bg-baseColor rounded-lg flex flex-col gap-2">
        <div
          role="button"
          tabIndex={0}
          onClick={() => handleChangeSort('all')}
          className={`p-2 text-sm px-2 py-1.5 rounded ${
            !sortedBy && 'bg-primaryColor30 font-semibold text-neutralColor'
          }`}
        >
          {t('dashboard.postList.all')}
        </div>
        {(['Flagged', 'Negative', 'Positive', 'Neutral'] as FilterByType[]).map(
          (filter) => (
            <div
              key={filter}
              role="button"
              tabIndex={0}
              onClick={() => handleChangeSort(filter)}
              className={`p-2 text-sm px-2 py-1.5 rounded ${
                sortedBy === filter
                  ? 'bg-primaryColor30 font-semibold text-neutralColor'
                  : 'text-neutralColor60 font-normal hover:bg-primaryColor5'
              }`}
            >
              {t(`${filter}`)}
            </div>
          ),
        )}
      </PopoverContent>
    </Popover>
  )
}
