import React, { useState } from 'react'

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import { useSmallDesktopQuery } from '@/hooks/useSmallDesktopQuery'
import { stripHtmlTags, textEllipsis } from '@/utils/strings'

import EmptyImage from '../../../assets/emptyState/emptyImage.svg'

interface infoDisplayItem {
  topText: string
  bottomText?: string
  imgUrl?: string
  imgAlt: string
  profileUrl: string
  disabled?: boolean
  hoverInfo?: string
}

export const InfoDisplayItem = ({
  topText,
  bottomText,
  imgUrl,
  imgAlt,
  profileUrl,
  disabled,
  hoverInfo,
}: infoDisplayItem) => {
  const isSmallDesktop = useSmallDesktopQuery()
  const isMobile = useMobileMediaQuery()
  const [imageSrc, setImageSrc] = useState(imgUrl)

  const onPressItem = async () => {
    if (profileUrl) window.open(profileUrl, '_blank')
  }

  return (
    <HoverCard>
      <HoverCardTrigger>
        <div
          className={`relative flex h-[72px] p-3 bg-baseColor ${
            disabled ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'
          } justify-between items-center rounded-xl hover:bg-primaryColor5`}
          onClick={disabled ? undefined : onPressItem}
        >
          <div className="flex flex-row gap-3">
            {imgUrl && (
              <img
                style={{ borderRadius: '0.75rem' }}
                src={imageSrc}
                alt={imgAlt}
                className="w-12 h-12"
                onError={() => setImageSrc(EmptyImage)}
              />
            )}

            <div className="flex flex-col gap-1">
              <p className="text-neutralColor text-base font-medium">
                {topText}
              </p>
              <p className="text-neutralColor60 text-sm font-medium tracking-tight">
                {textEllipsis(
                  bottomText || '',
                  isMobile ? 20 : isSmallDesktop ? 35 : 60,
                )}
              </p>
            </div>
          </div>
        </div>
      </HoverCardTrigger>
      {hoverInfo && (
        <HoverCardContent className="md:w-96 bg-baseColor rounded-xl border-none">
          <p className="text-neutralColor60 text-base font-medium tracking-tight">
            {stripHtmlTags(hoverInfo)}
          </p>
        </HoverCardContent>
      )}
    </HoverCard>
  )
}
