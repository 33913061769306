import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'
import type { ICareer } from '@/store/api/Person/Person.types'

import { EmptyStateCard, EmptyStateCardIcon } from './EmptyStateCard'
import { InfoDisplayItem } from './InfoDisplayItem'

interface CareerWidgetTypes {
  career: ICareer[]
}

export const CareerWidget = ({ career }: CareerWidgetTypes) => {
  const { t } = useTranslation()
  return (
    <Card className="p-4 flex flex-col gap-5 max-h-[335px] overflow-y-scroll custom-scrollbar rounded-2xl">
      <p className="text-neutralColor text-xl font-medium">
        {t('dashboard.reportGeneralInfo.career')}
      </p>
      {career.length !== 0 ? (
        career.map((item, index) => (
          <InfoDisplayItem
            topText={item.title}
            bottomText={`${item.company} - ${t(
              'dashboard.reportGeneralInfo.fullTime',
            )} ${item.startDate.month ? `- ${item.startDate.month}` : ''}${
              item.startDate.year ? `/${item.startDate.year}` : ''
            } 
            ${
              item.endDate.month
                ? ` - ${item.endDate.month}`
                : item.startDate.month
                  ? `- ${t('dashboard.reportGeneralInfo.present')}`
                  : ''
            }${item.endDate.year ? `/${item.endDate.year}` : ''}`}
            imgUrl={item.companyLogoUrl || 'useEmptyState'}
            imgAlt={item.company}
            profileUrl={item.companyLinkedinURL}
            key={index}
          />
        ))
      ) : (
        <EmptyStateCard
          title={t('dashboard.reportGeneralInfo.careerEmpty')}
          icon={EmptyStateCardIcon.JOB}
        />
      )}
    </Card>
  )
}
