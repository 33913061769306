import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface ChipTypes {
  isTag?: boolean
  green?: boolean
  blue?: boolean
  primaryBlue?: boolean
  yellow?: boolean
  text?: string
  disabled?: boolean
  red?: boolean
  icon?: React.ReactNode
  dashed?: boolean
  transparent?: boolean
  isBigChip?: boolean
  borderRadius?: string
  paddingLeft?: string
  paddingRight?: string
  isMediumChip?: boolean
}

export const Chip = ({
  isTag,
  green,
  blue,
  primaryBlue,
  yellow,
  red,
  text,
  disabled,
  icon,
  dashed,
  transparent,
  isBigChip,
  borderRadius,
  paddingLeft,
  paddingRight,
  isMediumChip,
}: ChipTypes) => {
  const colorMap = {
    blue: {
      bgColor: '#C6F5FF',
      textColor: '#037797',
      border: 'transparent',
    },
    green: {
      bgColor: '#C8FFD4',
      textColor: '#1E924D',
      border: 'transparent',
    },
    primaryBlue: {
      bgColor: '#087FFF',
      textColor: '#F5F5F5',
      border: '#087FFF',
    },
    disabled: {
      bgColor: 'neutral-200',
      textColor: 'neutral-400',
      border: 'transparent',
    },
    default: {
      bgColor: '',
      textColor: '',
      border: '',
    },
    yellow: {
      bgColor: '#FFFDCB',
      textColor: '#DFB014',
      border: 'transparent',
    },
    red: {
      bgColor: weCheckPalette.alertRedBase,
      textColor: weCheckPalette.dynamicRed,
      border: weCheckPalette.alertRedBase,
    },
    dashed: {
      bgColor: 'transparent',
      textColor: weCheckPalette.neutralColor60,
      border: weCheckPalette.neutralColor60,
    },
    transparent: {
      bgColor: weCheckPalette.baseColorHighlight,
      textColor: weCheckPalette.neutralColor,
      border: weCheckPalette.neutralColor60,
    },
  }

  const { bgColor, textColor, border } =
    colorMap[
      blue
        ? 'blue'
        : green
          ? 'green'
          : primaryBlue
            ? 'primaryBlue'
            : yellow
              ? 'yellow'
              : red
                ? 'red'
                : dashed
                  ? 'dashed'
                  : disabled
                    ? 'disabled'
                    : transparent
                      ? 'transparent'
                      : 'default'
    ]

  return (
    <div
      className={`flex 
        ${
          isTag
            ? 'h-6 px-3 py-1 rounded-[200px]'
            : isBigChip
              ? 'h-10 px-3 py-1 rounded-[200px]'
              : isMediumChip
                ? 'h-[35px] px-4 py-2 rounded-[200px]'
                : 'h-5 px-1 py-0.5 rounded'
        }
        items-center justify-center gap-1
      `}
      style={{
        backgroundColor: bgColor,
        borderRadius: borderRadius,
        paddingRight: paddingRight,
        paddingLeft: paddingLeft,
        border: `0.5px ${dashed ? 'dashed' : 'solid'} ${border}`,
      }}
    >
      <p
        className="text-xs font-medium tracking-tight text-nowrap"
        style={{ color: textColor }}
      >
        {text}
      </p>
      {icon && icon}
    </div>
  )
}
