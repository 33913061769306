import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'
import type { IEducation } from '@/store/api/Person/Person.types'

import { EmptyStateCard, EmptyStateCardIcon } from './EmptyStateCard'
import { InfoDisplayItem } from './InfoDisplayItem'

interface EducationWidgetType {
  education: IEducation[]
}

export const EducationWidget = ({ education }: EducationWidgetType) => {
  const { t } = useTranslation()
  return (
    <Card className="p-4 flex flex-col gap-5 max-h-[335px] overflow-y-scroll custom-scrollbar rounded-2xl">
      <p className="text-neutralColor text-xl font-medium">
        {t('dashboard.reportGeneralInfo.education')}
      </p>
      {education.length !== 0 ? (
        education.map((item, index) => (
          <InfoDisplayItem
            topText={item.title || t('dashboard.reportGeneralInfo.noTitle')}
            bottomText={`${item.school}
            ${item.endDate.year ? ` - ${item.endDate.year}` : ''}`}
            imgAlt={item.school}
            profileUrl={item.schoolUrl}
            key={index}
          />
        ))
      ) : (
        <EmptyStateCard
          title={t('dashboard.reportGeneralInfo.educationEmpty')}
          icon={EmptyStateCardIcon.EDUCATION}
        />
      )}
    </Card>
  )
}
