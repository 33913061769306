import { Trash, CloseSquare } from 'iconsax-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import {
  AlertDialogHeader,
  AlertDialog,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertDialogCancel,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useDeleteApiKeyMutation } from '@/store/api/ApiKey/ApiKey.query'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export const DeleteApiKeyPopUp = ({
  apiKeyId,
  secretKey,
}: {
  apiKeyId: string
  secretKey: string
}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [deleteApiKey, { isLoading }] = useDeleteApiKeyMutation()

  const handleDeleteApiKey = async () => {
    try {
      await deleteApiKey({
        apiKeyId: apiKeyId,
      }).unwrap()
      toast.success(t('dashboard.apiKey.delete.succes'))
      setIsOpen(false)
    } catch (error) {
      catchWithSentry(error)
      toast.error(t('dashboard.apiKey.delete.error'))
    }
  }
  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogTrigger>
        <Button className="gap-2" variant="ghost">
          <Trash size="21" variant="Bold" color={weCheckPalette.dynamicRed} />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="flex flex-col w-2/6 max-md:w-11/12">
        <AlertDialogHeader>
          <AlertDialogTitle className="flex flex-row text-xl font-medium justify-between tracking-wide max-md:text-left max-md:text-[30px]">
            {t('dashboard.apiKey.delete.revokeSecretKey')}
            <Button
              variant="ghost"
              onClick={() => setIsOpen(false)}
              className="p-0 hover:bg-transparent"
            >
              <CloseSquare
                size={24}
                color={weCheckPalette.neutralColor}
                className="cursor-pointer"
              />
            </Button>
          </AlertDialogTitle>

          <div className="flex flex-col gap-3">
            <p>{t('dashboard.apiKey.delete.thisApiKey')}</p>
            <p>{t('dashboard.apiKey.delete.secretKeyName')}</p>
            <div>
              <Input
                type="text"
                value={secretKey}
                className="rounded-3xl"
                readOnly
              />
            </div>
          </div>
        </AlertDialogHeader>

        <div className="flex flex-row justify-end gap-3">
          <AlertDialogCancel
            className="rounded-3xl"
            onClick={() => setIsOpen(false)}
          >
            {t('dashboard.apiKey.delete.cancel')}
          </AlertDialogCancel>

          <Button
            className="rounded-3xl bg-dynamicRed"
            type="submit"
            isLoading={isLoading}
            onClick={handleDeleteApiKey}
          >
            {t('dashboard.apiKey.delete.revokeKey')}
          </Button>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
