import React from 'react'
import { useTranslation } from 'react-i18next'

import Burgermenu from '@/assets/burgerMenu.svg?react'
import { LottieSpinner } from '@/components/LottieSpinner/LottieSpinner'
import { Button } from '@/components/ui/button'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectTrigger,
} from '@/components/ui/select'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { ConsentFilesSheet } from '@/pages/Reports/Components/ConsentFilesSheet'
import {
  useGenerateAnonymousPdfMutation,
  useMakeBackgroundCheckOpenedMutation,
} from '@/store/api/Person/Person.query'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface ActionsTypes {
  pdf?: string
  complianceSigned?: string
  summarySigned?: string
  disclosureSigned?: string
  dynamicSigned?: string
  otherAuthorizationSigned?: string
  id: string
  anonymousPdf?: string
  subjectId: string
}

export const Actions = ({
  pdf,
  complianceSigned,
  summarySigned,
  disclosureSigned,
  dynamicSigned,
  otherAuthorizationSigned,
  id,
  anonymousPdf,
  subjectId,
}: ActionsTypes) => {
  const { t } = useTranslation()
  const [makeBackgroundCheckOpened] = useMakeBackgroundCheckOpenedMutation()
  const [generateAnonymousPdf, { isLoading: isLoadingAnonymousPdf }] =
    useGenerateAnonymousPdfMutation()
  const onPressDowload = async () => {
    if (pdf) {
      window.open(pdf, '_blank')
    }
    try {
      await makeBackgroundCheckOpened({ id: id }).unwrap()
    } catch (e) {
      catchWithSentry(e)
      console.error(e)
    }
  }

  const onPressDowloadPrivatePdf = async () => {
    if (anonymousPdf) {
      window.open(anonymousPdf, '_blank')
    } else {
      try {
        const res = await generateAnonymousPdf({ id: subjectId }).unwrap()
        if (res.info.pdf) {
          window.open(res.info.pdf, '_blank')
        }
      } catch (e) {
        catchWithSentry(e)
        console.error(e)
      }
    }
    try {
      await makeBackgroundCheckOpened({ id }).unwrap()
    } catch (e) {
      catchWithSentry(e)
      console.error(e)
    }
  }
  return (
    <>
      {isLoadingAnonymousPdf && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="">
            <LottieSpinner
              text={t('dashboard.reportsScreen.generatingPrivateReport')}
            />
          </div>
        </div>
      )}
      <Select>
        {
          <SelectTrigger
            className={`w-10 h-10 border-none shadow-none p-1 bg-transparent items-center flex justify-center ${
              !complianceSigned && !pdf && 'hidden'
            }`}
            hiddenArrow
          >
            <Burgermenu color={weCheckPalette.neutralColor} />
          </SelectTrigger>
        }

        <SelectContent className="w-[224px]">
          <SelectGroup>
            {pdf && (
              <Button
                variant={'outline'}
                className="border-none shadow-none p-0 w-full"
                onClick={onPressDowload}
              >
                <p className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2  outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5 text-left">
                  {t('dashboard.reportGeneralInfo.download')}
                </p>
              </Button>
            )}
            {complianceSigned && (
              <ConsentFilesSheet
                complianceSigned={complianceSigned}
                summarySigned={summarySigned}
                disclosureSigned={disclosureSigned}
                dynamicSigned={dynamicSigned}
                otherAuthorizationSigned={otherAuthorizationSigned}
                variant={'outline'}
                isActionSelector
              />
            )}
            <Button
              variant={'outline'}
              className="border-none shadow-none p-0 w-full"
              onClick={onPressDowloadPrivatePdf}
            >
              <p className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2  outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5 text-left">
                {t('dashboard.reportsScreen.generatePrivatePDdf')}
              </p>
            </Button>
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  )
}
