/* eslint-disable import/order */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import { HistoryRouter } from 'redux-first-history/rr6'

import MaintenanceScreenImage from '@/assets/NewSearchErrorImage.svg'
import { Toaster } from '@/components/ui/sonner'

import { Error404 } from './components/ErrorState/Error404'
import { ReportInProgress } from './components/ReportInProgress/ReportInProgress'
import { useRemoteConfigInit } from './hooks/useRemoteConfigInit'
import useTheme from './hooks/useSwitchTheme'
import { initializeSentry } from './initializeSentry'
import { ContactUs } from './pages/ContactUs/ContactUs'
import { FeedBackPayment } from './pages/Home/components/FeedBackPayment/FeedBackPayment'
import { FeedBackScreen } from './pages/Home/components/FeedBackScreen/FeedBackScreen'
import { Home } from './pages/Home/Home'
import { LeftSideBar } from './pages/LeftSideBar/LeftSideBar'
import { CreatePasswordForm } from './pages/Login/CreatePasswordForm'
import { ForgotPasswordForm } from './pages/Login/ForgotPasswordForm'
import { ForgotPaswordCheckYourInbox } from './pages/Login/ForgotPaswordCheckYourInbox'
import { InviteScreenFormFirstStep } from './pages/Login/InviteScreen/InviteScreenFormFirstStep'
import { InviteScreenFormSecondStep } from './pages/Login/InviteScreen/InviteScreenFormSecondStep'
import { Login } from './pages/Login/Login'
import { LoginForm } from './pages/Login/LoginForm'

import { FaceMatchResultsScreen } from './pages/Reports/FaceMatchResultsScreen'
import { ReportsScreen } from './pages/Reports/ReportsScreen'
import { NewSearchConsent } from './pages/Search/NewSearchConsent'
import { NewSearchLegalDisclaimer } from './pages/Search/NewSearchLegalDisclaimer'
import { Settings } from './pages/Settings/Settings'
import { EmailVerification } from './pages/SignUp/EmailVerification'
import { SignUp } from './pages/SignUp/SignUp'
import { SignUpFinalStep } from './pages/SignUp/SignUpFinalStep'
import { SignUpFirstStep } from './pages/SignUp/SignUpFirstStep'
import { SignUpSecondStep } from './pages/SignUp/SignUpSecondStep'
import { SignUpStart } from './pages/SignUp/SignUpStart'
import { selectInMaintenance } from './store/api/Config/Config.selector'
import { history } from './store/store'
import './i18n/config'
import { NewSearchInitialReportSetup } from './pages/Search/NewSearchInitialReportSetup'
import { NewSearchSubjectInfo } from './pages/Search/NewSearchSubjectInfo'
import { NewSearchCompanyInfo } from './pages/Search/NewSearchCompanyInfo'
import { NewSearchFocusedTopics } from './pages/Search/NewSearchFocusedTopics'
import { InvitationExpiredScreen } from './pages/Login/InviteScreen/InvitationExpiredScreen'
import { FaceSearchFatherScreen } from './pages/FaceSearch/FaceSearchFatherScreen'
import { Lab } from './pages/Lab/Lab'
import { SearchResults } from './pages/Search/SearchResults'
import { AdvancedSearch } from './pages/Search/AdvancedSearch'
import { GeneratingReport } from './pages/Search/GeneratingReport'
import { AIReportFocusedTopics } from './pages/Search/AIReportFocusedTopics'

import { Enterprise } from './pages/Enterprise/Enterprise'
import { TeamSelection } from './pages/Enterprise/TeamSelection'
import { WorkSpaceCreation } from './pages/Enterprise/WorkSpaceCreation'
import { WorkSpaceCreationSecondStep } from './pages/Enterprise/WorkSpaceCreationSecondStep'
import { WorkSpaceCreationThirdStep } from './pages/Enterprise/WorkSpaceCreationThirdStep'
import { WorkSpaceSettings } from './pages/Enterprise/WorkSpaceSettings'
import { Biling } from './pages/Biling/Biling'
import { RoleGuideScreen } from './pages/Enterprise/RoleGuideScreen'
import { CreateTeamFirstStep } from './pages/Enterprise/CreateTeamFirstStep'
import { CreateTeamSecondStep } from './pages/Enterprise/CreateTeamSecondStep'
import { AiGeneratedReport } from './pages/AiGeneratedReport/AiGeneratedReport'
import { EnterpriseReportsScreen } from './pages/Reports/EnterpriseReports'
import { DigitalReport } from './pages/DigitalReport/DigitalReport'
import { SocialMediaProfile } from './pages/DigitalReport/SocialMediaProfile'

initializeSentry()

const App: React.FC = () => {
  useRemoteConfigInit()
  useTheme()
  const showMaintenanceScreen = useSelector(selectInMaintenance)
  const { t } = useTranslation()

  return (
    <>
      <HistoryRouter history={history}>
        {showMaintenanceScreen ? (
          <FeedBackScreen
            title={t('feedback.inMaintenanceScreen.title')}
            message={t('feedback.inMaintenanceScreen.message')}
            image={MaintenanceScreenImage}
            cardStyles={'shadow-none border-none h-screen w-screen'}
          />
        ) : (
          <Routes>
            <Route path="/" element={<Navigate to="/login/login-form" />} />
            <Route path="login" element={<Login />}>
              <Route path="login-form" element={<LoginForm />} />
              <Route path="forgot-password" element={<ForgotPasswordForm />} />
              <Route path="create-password" element={<CreatePasswordForm />} />
              <Route
                path="forgot-pasword-check-your-inbox"
                element={<ForgotPaswordCheckYourInbox />}
              />
            </Route>

            <Route path="sign-up" element={<SignUp />}>
              <Route path="sign-up-start" element={<SignUpStart />} />
              <Route
                path="email-verification"
                element={<EmailVerification />}
              />
              <Route path="first-step" element={<SignUpFirstStep />} />
              <Route path="second-step" element={<SignUpSecondStep />} />
              <Route path="final-step" element={<SignUpFinalStep />} />
              <Route
                path="invitation-expired"
                element={<InvitationExpiredScreen />}
              />
              <Route
                path="invite-first-step"
                element={<InviteScreenFormFirstStep />}
              />
              <Route
                path="invite-second-step"
                element={<InviteScreenFormSecondStep />}
              />
            </Route>

            <Route path="dashboard" element={<LeftSideBar />}>
              <Route path="error-404" element={<Error404 />} />
              <Route path="feedback-screen" element={<FeedBackScreen />} />
              <Route path="dashboard-home" index element={<Home />} />

              <Route path="contact-us" element={<ContactUs />} />
              <Route path="lab" element={<Lab />} />
              <Route path="settings" element={<Settings />} />
              <Route path="feedback-payment" element={<FeedBackPayment />} />
              <Route path="reports" element={<ReportsScreen />} />
              <Route
                path="enterprise-reports"
                element={<EnterpriseReportsScreen />}
              />
              <Route path="enterprise" element={<Enterprise />} />
              <Route path="team-selection" element={<TeamSelection />} />
              <Route path="role-guide" element={<RoleGuideScreen />} />
              <Route
                path="ai-generated-report"
                element={<AiGeneratedReport />}
              />
              <Route path="biling" element={<Biling />} />
              <Route
                path="work-space-settings"
                element={<WorkSpaceSettings />}
              />
              <Route
                path="work-space-creation"
                element={<WorkSpaceCreation />}
              />
              <Route
                path="work-space-creation-second-step"
                element={<WorkSpaceCreationSecondStep />}
              />
              <Route
                path="work-space-creation-third-step"
                element={<WorkSpaceCreationThirdStep />}
              />
              <Route
                path="create-team-first-step"
                element={<CreateTeamFirstStep />}
              />
              <Route
                path="create-team-second-step"
                element={<CreateTeamSecondStep />}
              />

              <Route
                path="face-match-results"
                element={<FaceMatchResultsScreen />}
              />
              <Route path="face-search" element={<FaceSearchFatherScreen />} />
              <Route
                path="social-media-profile"
                element={<SocialMediaProfile />}
              />

              <Route path="digital-report" element={<DigitalReport />} />
              <Route
                path="new-search-initial-report-setup"
                element={<NewSearchInitialReportSetup />}
              />
              <Route path="new-search-consent" element={<NewSearchConsent />} />

              <Route
                path="new-search-focused-topics"
                element={<NewSearchFocusedTopics />}
              />
              <Route
                path="new-search-subject-info"
                element={<NewSearchSubjectInfo />}
              />
              <Route
                path="new-search-company-info"
                element={<NewSearchCompanyInfo />}
              />
              <Route
                path="new-search-legal-disclaimer"
                element={<NewSearchLegalDisclaimer />}
              />
              <Route path="report-in-progress" element={<ReportInProgress />} />
              <Route path="search-results" element={<SearchResults />} />
              <Route path="advanced-search" element={<AdvancedSearch />} />
              <Route path="generating-report" element={<GeneratingReport />} />
              <Route
                path="focused-topics"
                element={<AIReportFocusedTopics />}
              />
            </Route>

            <Route path="*" element={<Error404 />} />
          </Routes>
        )}
      </HistoryRouter>

      <Toaster richColors />
    </>
  )
}

export default App
