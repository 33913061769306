import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'
import type { digitalPersonSnapshotTypes } from '@/store/api/Person/Person.types'

import { PersonaSnapshotCard } from './PersonaSnapshotCard'

interface DigitalPersonaSnapshotWidgetTypes {
  attributes?: digitalPersonSnapshotTypes
}

export const DigitalPersonaSnapshotWidget = ({
  attributes,
}: DigitalPersonaSnapshotWidgetTypes) => {
  const { t } = useTranslation()
  const personaSnapshotData = [
    {
      title: t('dashboard.reportGeneralInfo.positiveAttributes'),
      text: attributes?.positiveAttributes,
      itemColor: 'bg-dynamicGreen',
    },
    {
      title: t('dashboard.reportGeneralInfo.neutralAttributes'),
      text: attributes?.neutralAttributes,
      itemColor: 'bg-dynamicYellow',
    },
    {
      title: t('dashboard.reportGeneralInfo.negativeAttributes'),
      text: attributes?.negativeAttributes,
      itemColor: 'bg-dynamicRed',
    },
  ]
  return (
    <Card className="flex flex-col bg-baseColor p-4 rounded-2xl gap-4">
      <p className="text-neutralColor text-xl font-medium">
        {t('dashboard.reportGeneralInfo.digitalPersonaSnapshot')}
      </p>
      <div className="flex flex-col gap-5">
        {personaSnapshotData.map((attribute, index) => (
          <PersonaSnapshotCard
            title={attribute.title}
            text={attribute.text || []}
            itemColor={attribute.itemColor}
            key={index}
          />
        ))}
      </div>
    </Card>
  )
}
