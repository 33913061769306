import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'
import { Chip } from '@/pages/Home/components/Chip/Chip'
import type { IPersonalityInsight } from '@/store/api/Person/Person.types'

import { EmptyStateCard, EmptyStateCardIcon } from './EmptyStateCard'

interface PersonalityInsightsWidgetTypes {
  personalityInsights?: IPersonalityInsight[]
}

export const PersonalityInsightsWidget = ({
  personalityInsights,
}: PersonalityInsightsWidgetTypes) => {
  const { t } = useTranslation()

  return (
    <Card className="flex flex-col bg-baseColor p-4 gap-4">
      <p className="flex text-neutralColor text-xl font-medium">
        {t('Personality Insights')}
      </p>
      {personalityInsights?.length !== 0 ? (
        <div className="flex gap-3 flex-wrap items-start">
          {personalityInsights?.map((insight, index) => (
            <Chip text={insight.trait} key={index} blue isMediumChip />
          ))}
        </div>
      ) : (
        <EmptyStateCard
          title={t('dashboard.reportGeneralInfo.personalityInsightsEmpty')}
          icon={EmptyStateCardIcon.PERSON}
        />
      )}
    </Card>
  )
}
