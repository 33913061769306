import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { LottieSpinner } from '@/components/LottieSpinner/LottieSpinner'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useUpdateEnterpriseDataMutation } from '@/store/api/Enterprise/Enterprise.query'
import { useUserInfoQuery } from '@/store/api/Users/Users.query'
import type { enterpriseType } from '@/utils/schemas'
import { enterpriseSchema } from '@/utils/schemas'

export const Enterprise = () => {
  const { t } = useTranslation()
  const { data, isLoading: isLoadingUserInfo } = useUserInfoQuery()

  const [updateEnterpriseData, { isLoading: isLoadingUpdateData }] =
    useUpdateEnterpriseDataMutation()

  const form = useForm<enterpriseType>({
    resolver: zodResolver(enterpriseSchema),
    defaultValues: {
      companyName: data?.enterprise.name || '',
      companyAddress: data?.enterprise.address || '',
    },
    mode: 'onChange',
  })
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = form

  const handleUpdateUserData = async (formData: enterpriseType) => {
    try {
      if (data?.enterprise.name) {
        await updateEnterpriseData({
          enterpriseId: data?.enterprise.id,
          companyName:
            formData.companyName === data?.enterprise.name
              ? undefined
              : formData.companyName,
          companyAddress:
            formData.companyAddress === data?.enterprise.address
              ? undefined
              : formData.companyAddress,
        }).unwrap()
        toast.success(t('feedback.toast.changesSaved'))
      }
    } catch (error) {
      toast.error(t('feedback.toast.errorSavingChanges'))
      catchWithSentry(error)
    }
  }

  const onSubmit = (formData: enterpriseType) => {
    handleUpdateUserData(formData)
  }

  if (isLoadingUserInfo) {
    return <LottieSpinner width={320} height={320} text="Loading.." />
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col ">
            <p className="text-neutralColor text-xl font-semibold tracking-wide font-satoshi mb-4">
              {t('dashboard.account.enterprise.companyDetails')}
            </p>
            <div className="flex lg:flex-row flex-col gap-4 lg:gap-0 lg:justify-between mb-6">
              <FormField
                disabled={false}
                control={control}
                name="companyName"
                render={({ field }) => (
                  <FormItem className="lg:w-[48%] w-full">
                    <FormLabel className="text-textNormal text-neutralColor80 text-sm font-medium tracking-tight">
                      {t('dashboard.account.enterprise.companyName')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder={data?.enterprise.name}
                        className="rounded-3xl"
                      />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                disabled={false}
                control={control}
                name="companyAddress"
                render={({ field }) => (
                  <FormItem className="rounded-3xl lg:w-[48%] w-full">
                    <FormLabel className="text-textNormal text-neutralColor80 text-sm font-medium tracking-tight">
                      {t('dashboard.account.enterprise.companyAddress')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="rounded-3xl"
                        placeholder={
                          data?.enterprise.address
                            ? data?.enterprise.address
                            : t(
                                'dashboard.account.enterprise.noAddressAvalible',
                              )
                        }
                      />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="flex lg:justify-end lg:items-end">
            <Button
              type="submit"
              disabled={!isDirty}
              className="mt-6 w-full lg:w-[69px] px-4 py-2"
              isLoading={isLoadingUpdateData}
            >
              {t('dashboard.account.enterprise.save')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
