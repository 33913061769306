import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'
import { DigitalFootPrintCard } from '@/pages/DigitalReport/Components/DigitalFootPrintCard'
import type { IDigitalFootprints } from '@/store/api/Person/Person.types'

import { EmptyStateCard, EmptyStateCardIcon } from './EmptyStateCard'

interface DigitalFootprintWidgetTypes {
  digitalFootprints: IDigitalFootprints[]
}

export const DigitalFootprintWidget = ({
  digitalFootprints,
}: DigitalFootprintWidgetTypes) => {
  const { t } = useTranslation()

  const verifiedFootprints = digitalFootprints.filter(
    (footprint) =>
      footprint.status === 'auto-checked' || footprint.status === 'checked',
  )

  const unverifiedFootprints = digitalFootprints.filter(
    (footprint) =>
      footprint.status !== 'auto-checked' && footprint.status !== 'checked',
  )

  return (
    <Card className="p-4 flex flex-col gap-6 custom-scrollbar rounded-2xl">
      <p className="text-neutralColor text-xl font-medium">
        {t('dashboard.reportGeneralInfo.digitalFoot')}
      </p>
      <div className="flex flex-col gap-4">
        <p className="text-neutralColor80 text-base font-medium">
          {t('dashboard.reportGeneralInfo.verified')}
        </p>
        {verifiedFootprints.length !== 0 ? (
          verifiedFootprints.map((footprint) => (
            <DigitalFootPrintCard
              key={footprint.url}
              image={footprint.image}
              url={footprint.url}
              socialMedia={footprint.platform}
              text={footprint.platform ?? 'No Platform'}
              chipText={t('dashboard.reportGeneralInfo.verified')}
            />
          ))
        ) : (
          <EmptyStateCard
            title={t('dashboard.reportGeneralInfo.verifiedEmpty')}
            icon={EmptyStateCardIcon.WORLD}
          />
        )}
      </div>

      <div className="flex flex-col gap-4">
        <p className="text-neutralColor80 text-base font-medium ">
          {t('dashboard.reportGeneralInfo.unverified')}
        </p>
        {unverifiedFootprints.length !== 0 ? (
          unverifiedFootprints.map((footprint) => (
            <DigitalFootPrintCard
              key={footprint.url}
              image={footprint.image}
              url={footprint.url}
              socialMedia={footprint.platform}
              text={footprint.platform ?? 'No Platform'}
              chipText={t('dashboard.reportGeneralInfo.unverified')}
            />
          ))
        ) : (
          <EmptyStateCard
            title={t('dashboard.reportGeneralInfo.unverifiedEmpty')}
            icon={EmptyStateCardIcon.WORLD}
          />
        )}
      </div>
    </Card>
  )
}
