import { ArrowDown2 } from 'iconsax-react'
import { useSelector } from 'react-redux'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { selectWorkspacebyId } from '@/store/api/Users/Users.selector'
import { useGetWorkSpacesQuery } from '@/store/api/workspace/workspace.query'
import type { WorkSpaceInfo } from '@/store/api/workspace/workspace.types'
import type { RootState } from '@/store/store'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

const WorkspaceSelector = ({
  setSelectedOfficeAndTeam,
  selectedOfficeAndTeam,
}: {
  setSelectedOfficeAndTeam: ({
    workspace,
    team,
  }: {
    workspace: string | null
    team: string | null
  }) => void
  selectedOfficeAndTeam: {
    workspace: string | null
    team: string | null
  }
}) => {
  const { data: workspaceData } = useGetWorkSpacesQuery()

  const workspace = useSelector((state: RootState) =>
    selectWorkspacebyId(state, selectedOfficeAndTeam.workspace),
  )

  const handleWorkspaceSelect = (workspaceId: string) => {
    if (workspaceId === 'all') {
      setSelectedOfficeAndTeam({ workspace: null, team: null })
    } else {
      setSelectedOfficeAndTeam({ workspace: workspaceId, team: null })
    }
  }

  return (
    <div className="flex w-full justify-center">
      <Select
        value={selectedOfficeAndTeam.workspace || 'all'}
        onValueChange={handleWorkspaceSelect}
      >
        <SelectTrigger
          className="w-min gap-3 items-center justify-center border-none bg-transparent focus:ring-0 focus:ring-offset-0 text-xl font-medium"
          hiddenArrow
        >
          <SelectValue placeholder={workspace?.name || 'All'} />
          <div className="rounded-full bg-baseColor justify-center items-center p-[2px]">
            <ArrowDown2 size="17" color={weCheckPalette.neutralColor30} />
          </div>
        </SelectTrigger>
        <SelectContent>
          <SelectItem key="all" value="all">
            All Offices
          </SelectItem>

          {workspaceData?.info.workspaces.map((workspace: WorkSpaceInfo) => (
            <SelectItem key={workspace._id} value={workspace._id}>
              {workspace.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}

export default WorkspaceSelector
