import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import type { getSubjectBasicInformationResponse } from '@/store/api/Person/Person.types'

import { RecordCard } from './RecordCard'

interface AvailableRecordsWidgetTypes {
  data: getSubjectBasicInformationResponse
}

export const AvailableRecordsWidget = ({
  data,
}: AvailableRecordsWidgetTypes) => {
  const { t } = useTranslation()
  return (
    <Card className="flex flex-col gap-5 p-4 rounded-2xl bg-baseColor">
      <p className="text-neutralColor text-xl font-medium">
        {t('dashboard.reportGeneralInfo.available')}
      </p>
      <div className="flex flex-col gap-3">
        {data?.info.workEmails.length !== 0 &&
          data?.info.workEmails.map((workEmail, index) => (
            <RecordCard
              key={index}
              title={t('dashboard.reportGeneralInfo.work')}
              content={workEmail}
            />
          ))}
        {data?.info.personalEmails.length !== 0 &&
          data?.info.personalEmails.map((personalEmail, index) => (
            <RecordCard
              key={index}
              title={t('dashboard.reportGeneralInfo.personal')}
              content={personalEmail}
            />
          ))}
        {data?.info.phones.length !== 0 && (
          <Separator className="border-t-[0.5px] border-neutralColor30 my-1" />
        )}
        {data?.info.phones.length !== 0 &&
          data?.info.phones.map((phone, index) => (
            <RecordCard
              key={index}
              title={t('dashboard.reportGeneralInfo.phone')}
              content={phone}
            />
          ))}
        {data?.info.address.length !== 0 && (
          <Separator className="border-t-[0.5px] border-neutralColor30 my-1" />
        )}

        {data?.info.address.length !== 0 &&
          data?.info.address.map((adress, index) => (
            <RecordCard
              key={index}
              title={t('dashboard.reportGeneralInfo.address')}
              content={adress}
            />
          ))}
      </div>
    </Card>
  )
}
