import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'
import useMediumMobileMediaQuery from '@/hooks/useMediumMobileMediaQuery'
import type { ICareer } from '@/store/api/Person/Person.types'
import { ChartPallette } from '@/utils/palette/chartPallette'

import { CareerChart } from './CareerChart'
import { ChipChart } from './ChipChart'
import { EmptyStateCard, EmptyStateCardIcon } from './EmptyStateCard'

interface ProfessionalCirclesWidgetTypes {
  career: ICareer[]
  name: string
}

export const ProfessionalCirclesWidget = ({
  career,
  name,
}: ProfessionalCirclesWidgetTypes) => {
  const { t } = useTranslation()
  const isMobile = useMediumMobileMediaQuery()
  const generateCareerText = (career: any[], name: string) => {
    const companies = [...new Set(career.map((job) => job.company))]
    if (companies.length === 1) {
      return t('dashboard.reportGeneralInfo.singleCompany', {
        name,
        company: companies[0],
      })
    }
    if (companies.length === 2) {
      return t('dashboard.reportGeneralInfo.twoCompanies', {
        name,
        company1: companies[0],
        company2: companies[1],
      })
    }
    return t('dashboard.reportGeneralInfo.multipleCompanies', {
      name,
      company1: companies[0],
      company2: companies[1],
      company3: companies[2],
    })
  }

  const currentDate = new Date()

  const sortedCareer = [...career].sort((a, b) => {
    return (
      b.startDate.year - a.startDate.year ||
      b.startDate.month - a.startDate.month
    )
  })

  const companies = sortedCareer.map((job) => ({
    name: job.company,
    title: job.title,
    startDate: new Date(job.startDate.year, job.startDate.month - 1),
    endDate:
      job.endDate.year === 0
        ? currentDate
        : new Date(job.endDate.year, job.endDate.month - 1),
    description: job.description,
  }))

  const durations = companies.map((job) => {
    const months = Math.ceil(
      (job.endDate.getTime() - job.startDate.getTime()) /
        (1000 * 60 * 60 * 24 * 30.44),
    )
    return months
  })

  const totalMonths = durations.reduce((acc, curr) => acc + curr, 0)

  const durationsWithPercentage = durations.map((months) => {
    const percentage = ((months / totalMonths) * 100).toFixed(1)
    return percentage
  })

  const calculateTotalExperienceYears = (career: ICareer[]) => {
    const currentDate = new Date()

    const oldestDate = career.reduce((oldest, job) => {
      const jobDate = new Date(job.startDate.year, job.startDate.month - 1)
      return jobDate < oldest ? jobDate : oldest
    }, currentDate)

    const mostRecentDate = career.reduce((recent, job) => {
      const endDate =
        job.endDate.year === 0
          ? currentDate
          : new Date(job.endDate.year, job.endDate.month - 1)

      return endDate > recent ? endDate : recent
    }, new Date(0))

    const diffInMonths =
      (mostRecentDate.getFullYear() - oldestDate.getFullYear()) * 12 +
      (mostRecentDate.getMonth() - oldestDate.getMonth())

    return Math.round(diffInMonths / 12)
  }
  const totalYears = calculateTotalExperienceYears(career)
  return (
    <div className="flex flex-col gap-5">
      <Card className="bg-baseColor p-4 gap-6 flex flex-col rounded-2xl">
        <div className="flex flex-col gap-2 ">
          <p className="text-neutralColor text-xl font-medium">
            {t('dashboard.reportGeneralInfo.professionalCircles')}
          </p>
          {career.length !== 0 && (
            <p className="text-base font-normal text-neutralColor80">
              {generateCareerText(career, name)}
            </p>
          )}
        </div>
        {career.length !== 0 ? (
          <>
            <div className="flex justify-center items-center">
              <CareerChart
                career={career}
                durations={durationsWithPercentage}
              />
            </div>
            <div className="w-full flex justify-between max-md:items-center gap-y-3 gap-x-6 flex-wrap">
              {companies.map((job, index) => (
                <ChipChart
                  key={index}
                  company={job.name}
                  color={ChartPallette[index]}
                  duration={`${durationsWithPercentage[index]}%`}
                />
              ))}
            </div>
          </>
        ) : (
          <EmptyStateCard
            title={t('dashboard.reportGeneralInfo.professionalCirclesEmpty')}
            icon={EmptyStateCardIcon.JOB}
          />
        )}
      </Card>
      {career.length !== 0 && (
        <Card className="bg-baseColor p-4 gap-2 flex flex-col rounded-2xl">
          <div
            className={`flex  justify-between ${
              isMobile ? 'flex-col gap-2' : 'items-center'
            }`}
          >
            <p className="text-neutralColor text-xl font-medium">
              {t('dashboard.reportGeneralInfo.estimatedCareerSpan')}
            </p>
            <p className="text-neutralColor text-xl font-medium">
              {`${totalYears} ${t('dashboard.reportGeneralInfo.years')}`}
            </p>
          </div>
          <p className="text-neutralColor text-base font-normal">
            {t('dashboard.reportGeneralInfo.theFollowingData')}
          </p>
        </Card>
      )}
    </div>
  )
}
