import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { WeScreen } from '@/components/WeScreen/WeScreen'
import { useWorkspaceDetailsQuery } from '@/store/api/workspace/workspace.query'

import { TeamCard } from './components/TeamCard'
import { EWorkSpaceCardType, WorkSpaceCard } from './components/WorkSpaceCard'

export const WorkSpaceSettings = () => {
  const { state } = useLocation()
  const { t } = useTranslation()

  const workspaceId = state

  const {
    data: currentWorkspace,
    isLoading: currentWorkspaceLoading,
    isError: createWorkSpaceIsError,
  } = useWorkspaceDetailsQuery({
    workspaceId: workspaceId,
  })

  const nameOne =
    currentWorkspace?.teams[0].members[0]?.user?.firstname ??
    currentWorkspace?.teams[0].members[0]?.userEmail
  const nameTwo =
    currentWorkspace?.teams[0].members[1]?.user?.firstname ??
    currentWorkspace?.teams[0].members[1]?.userEmail

  return (
    <WeScreen
      title={currentWorkspace?.name ?? ''}
      useNextButton={false}
      useHeaderBackButton={true}
      isLoading={currentWorkspaceLoading}
      isError={createWorkSpaceIsError}
    >
      <div className="flex flex-col gap-4">
        <div>
          <WorkSpaceCard
            type={EWorkSpaceCardType.SETTINGS}
            workspaceId={currentWorkspace?._id}
            title={currentWorkspace?.name}
            teamNumber={currentWorkspace?.teams.length || 0}
            nameOne={nameOne}
            nameTwo={nameTwo}
            leftButtonText={t('dashboard.company.workspaceCard.rename')}
          />
        </div>

        <div>
          <TeamCard
            teams={currentWorkspace?.teams}
            workspaceId={currentWorkspace?._id}
          />
        </div>
      </div>
    </WeScreen>
  )
}
