import React from 'react'
import { useTranslation } from 'react-i18next'

import type { ISentimentAnalysisResponse } from '@/store/api/Person/Person.types'

import { ItemsList } from './ItemsList'

interface SummaryWidger {
  summary: ISentimentAnalysisResponse
}

export const SummaryWidget = ({ summary }: SummaryWidger) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-5">
      {summary.onlineBehavior.length !== 0 && (
        <ItemsList
          title={t('dashboard.reportGeneralInfo.onlineBehavior')}
          items={summary.onlineBehavior}
          propertyKey={'behavior'}
        />
      )}
      {summary.redFlags.length !== 0 && summary.redFlags[0].flag !== '' && (
        <ItemsList
          title={t('dashboard.reportGeneralInfo.redFlags')}
          items={summary.redFlags}
          propertyKey={'flag'}
        />
      )}
      {summary.strengthsAndWeaknesses.strengths.length !== 0 && (
        <ItemsList
          title={t('dashboard.reportGeneralInfo.strengths')}
          items={summary.strengthsAndWeaknesses.strengths}
          propertyKey={'description'}
        />
      )}
      {summary.strengthsAndWeaknesses.weaknesses.length !== 0 && (
        <ItemsList
          title={t('dashboard.reportGeneralInfo.weaknesses')}
          items={summary.strengthsAndWeaknesses.weaknesses}
          propertyKey={'description'}
        />
      )}
    </div>
  )
}
