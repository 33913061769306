import React from 'react'
import { useTranslation } from 'react-i18next'

import useMediumMobileMediaQuery from '@/hooks/useMediumMobileMediaQuery'

interface SocialMetricsProps {
  following: number
  followers: number
  posts: number
  platform?: string
}

export const SocialMetrics = ({
  following,
  followers,
  posts,
  platform,
}: SocialMetricsProps) => {
  const { t } = useTranslation()
  const isMobile = useMediumMobileMediaQuery()

  return (
    <div className={`flex flex-row gap-8 justify-between w-full`}>
      {platform === 'WebSearch' ? (
        <>
          <div
            className={`flex gap-1 ${
              isMobile
                ? 'flex-col items-center'
                : 'max-md:flex-col max-sm:items-center'
            }`}
          >
            <p className={`text-textNormal text-base font-bold tracking-tight`}>
              {posts}
            </p>
            <p
              className={`text-textAccent text-base font-normal tracking-tight`}
            >
              {t('dashboard.header.posts')}
            </p>
          </div>
        </>
      ) : (
        <>
          <div
            className={`flex ${
              isMobile ? 'flex-col items-start w-[32%]' : 'gap-3 items-center'
            }`}
          >
            <p
              className={`text-neutralColor ${
                isMobile ? 'text-sm' : 'text-xl'
              } font-bold`}
            >
              {posts}
            </p>
            <p
              className={`text-neutralColor80 ${
                isMobile ? 'text-xs' : 'text-xl'
              } font-medium`}
            >
              {t('dashboard.header.posts')}
            </p>
          </div>
          <div
            className={`flex ${
              isMobile ? 'flex-col items-start w-[32%]' : 'gap-3 items-center'
            }`}
          >
            <p
              className={`text-neutralColor ${
                isMobile ? 'text-sm' : 'text-xl'
              } font-bold`}
            >
              {followers}
            </p>
            <p
              className={`text-neutralColor80 ${
                isMobile ? 'text-xs' : 'text-xl'
              } font-medium`}
            >
              {t('dashboard.header.followers')}
            </p>
          </div>
          <div
            className={`flex ${
              isMobile ? 'flex-col items-start w-[32%]' : 'gap-3 items-center'
            }`}
          >
            <p
              className={`text-neutralColor ${
                isMobile ? 'text-sm' : 'text-xl'
              } font-bold`}
            >
              {following}
            </p>
            <p
              className={`text-neutralColor80 ${
                isMobile ? 'text-xs' : 'text-xl'
              } font-medium`}
            >
              {t('dashboard.header.following')}
            </p>
          </div>
        </>
      )}
    </div>
  )
}
