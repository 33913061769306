import { isEmpty } from 'ramda'

import { RTKTags, api } from '@/store/api'

import Env from '../../../env/env'
import type {
  SocialCheckResponse,
  socialCheckCrudResponse,
} from '../Enterprise/Enterprise.types'
import type { BECrudResponse } from '../GenericInterface'

import type {
  BackgroundCheckCrudBEResponse,
  BackgroundCheckPdfRequest,
  BackgroundCheckPdfResponse,
  BackgroundCheckRequest,
  BackgroundCheckResponse,
  ISocialMediaPostRequest,
  ISocialMediaPostResponse,
  PersonHistoryCrudBeResponse,
  PersonHistoryResponse,
  ReDowloadPdfResponse,
  SearchPersonCrudResponse,
  SearchPersonRequest,
  SearchPersonResponse,
  getSubjectBasicInformationResponse,
  getSubjectBasicInformationRequest,
  CancelReportRequest,
  CancelReportResponse,
  GenarateAnonymousPdfResponse,
  GenarateAnonymousPdfRequest,
} from './Person.types'

export const personsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchPerson: builder.mutation<SearchPersonResponse, SearchPersonRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/socials/person`,
        method: 'POST',
        body: { ...request },
      }),
      transformResponse: (response: SearchPersonCrudResponse) => {
        return response.info
      },
      invalidatesTags: [RTKTags.userInfo],
    }),
    backgroundCheck: builder.mutation<
      BackgroundCheckResponse,
      BackgroundCheckRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/persons/background-check/${request.id}`,
        method: 'GET',
      }),
      transformResponse: (response: BackgroundCheckCrudBEResponse) => {
        return response.info
      },
      invalidatesTags: [RTKTags.userInfo],
    }),
    personHistory: builder.query<PersonHistoryResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/socials/history`,
        method: 'GET',
      }),
      transformResponse: (response: PersonHistoryCrudBeResponse) => {
        return response.info
      },
      providesTags: [RTKTags.userHistory],
    }),
    clearCache: builder.mutation<void, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/persons/clear-history`,
        method: 'GET',
      }),
    }),
    backgroundCheckPdf: builder.mutation<
      BackgroundCheckPdfResponse,
      BackgroundCheckPdfRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/persons/background-check/${request.id}/report`,
        method: 'GET',
      }),
    }),
    makeBackgroundCheckOpened: builder.mutation<void, BackgroundCheckRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/socials/pdf-viewed/${request.id}`,
        method: 'PUT',
      }),
    }),
    reDowloadPdf: builder.mutation<
      ReDowloadPdfResponse,
      BackgroundCheckPdfRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/socials/re-download-pdf/${request.id}`,
        method: 'GET',
      }),
    }),
    socialChecksUser: builder.query<SocialCheckResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/socials/history`,
        method: 'GET',
      }),
      transformResponse: (response: socialCheckCrudResponse) => {
        return response.info.map((element) => {
          return {
            type: 'socialCheck',
            creation_date: element.reportDate,
            data: element,
          }
        })
      },
      providesTags: [RTKTags.monitoringCenter],
      keepUnusedDataFor: 0,
    }),
    cancelReport: builder.mutation<CancelReportResponse, CancelReportRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/subjects/${request.id}/cancel-report`,
        method: 'POST',
      }),
      invalidatesTags: [RTKTags.monitoringCenter, RTKTags.workspaceDetails],
    }),
    getSubjectBasicInformation: builder.query<
      getSubjectBasicInformationResponse,
      getSubjectBasicInformationRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/socials/subjects/${request.id}`,
        method: 'GET',
      }),
      providesTags: [RTKTags.monitoringCenter],
    }),

    socialMediaPost: builder.query<
      ISocialMediaPostResponse,
      ISocialMediaPostRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/socials/subjects/${request.id}/posts`,
        method: 'GET',
        params: {
          page: request.page,
          pageSize: request.pageSize,
          platform: request.plataform,
          filterBy: request.filterBy,
          flag: request.flag,
          socialMediaId: request.socialMediaId,
        },
      }),
      transformResponse: (
        response: BECrudResponse<ISocialMediaPostResponse[]>,
      ) => {
        return response.info[0]
      },
      keepUnusedDataFor: 0,
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        // This will serialize the endpoint and query arguments into a unique cache key
        const { id, pageSize, filterBy, plataform, flag } = queryArgs
        return `${endpointName}:${id}:${pageSize}:${plataform}:${filterBy}:${flag}`
      },
      merge: (currentCache, newItems) => {
        const { posts } = newItems

        // if offset is not zero, we have to merge only if newItems has a new page
        // if offset is zero, always return newItems.results (replacing with data from backend)
        if (isEmpty(posts.list)) {
          return currentCache
        } else if (isEmpty(currentCache)) {
          return newItems
        }
        posts.list = currentCache.posts.list.concat(posts.list)

        return newItems
      },
    }),
    generateAnonymousPdf: builder.mutation<
      GenarateAnonymousPdfResponse,
      GenarateAnonymousPdfRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/socials/generate-anonymous-pdf/${request.id}`,
        method: 'GET',
      }),
      invalidatesTags: [RTKTags.monitoringCenter],
    }),
  }),
})

export const {
  useSearchPersonMutation,
  useBackgroundCheckMutation,
  usePersonHistoryQuery,
  useBackgroundCheckPdfMutation,
  useMakeBackgroundCheckOpenedMutation,
  useReDowloadPdfMutation,
  useSocialChecksUserQuery,
  useGetSubjectBasicInformationQuery,
  useLazySocialMediaPostQuery,
  useCancelReportMutation,
  useGenerateAnonymousPdfMutation,
} = personsApi
