import { createApi } from '@reduxjs/toolkit/query/react'
import { values } from 'ramda'

import { baseQueryWithReauth } from './baseQuery'

// TODO Here we will track ALL RTQ TAGS
export const RTKTags = {
  Home: 'Home',
  userHistory: 'UserHistory',
  userInfo: 'UserInfo',
  enterpriseUsers: 'EnterpriseUsers',
  monitoringCenter: 'MonitoringCenter',
  enterpriseInfo: 'EnterpriseInfo',
  historyEnterprice: 'HistoryEnterprice',
  subjectPost: 'SubjectPost',
  createWorkspace: 'createWorkspace',
  workspaceDetails: 'workspaceDetails',
  getWorkSpaces: 'getWorkSpaces',
  workspaceCredits: 'workspaceCredits',
  apiKey: 'apiKey',
}

export const RTKTagsAsArray = () => values(RTKTags)

export const api = createApi({
  reducerPath: 'api',
  tagTypes: RTKTagsAsArray(),
  baseQuery: baseQueryWithReauth,
  /**
   * By default the invalidation Cache time is 60 seg.
   */
  keepUnusedDataFor: 30,
  /**
   * Re-validate all data when user comes from off-line mode.
   */
  refetchOnReconnect: true,
  endpoints: () => ({}),
})
