import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { ESocialMedias } from '@/constants/sanitizeSocialMediaConstants'
import { identifySocialMedia } from '@/utils/identifySocialMedia'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import Clip from '../../../assets/socialMedia/Clip.svg?react'
import Facebook from '../../../assets/socialMedia/Facebook.svg?react'
import Instagram from '../../../assets/socialMedia/Instagram.svg?react'
import Linkedin from '../../../assets/socialMedia/LinkedIn.svg?react'
import Pinterest from '../../../assets/socialMedia/Pinterest.svg?react'
import Reddit from '../../../assets/socialMedia/Reddit.svg?react'
import Tiktok from '../../../assets/socialMedia/TikTok.svg?react'
import X from '../../../assets/socialMedia/X.svg?react'

interface SocialMediaItemTypes {
  userName: string
  profileUrl: string
  onClick?: () => void
}

export const SocialMediaItem = ({
  userName,
  profileUrl,
  onClick,
}: SocialMediaItemTypes) => {
  const { t } = useTranslation()
  const SOCIAL_MEDIA_ICONS = {
    [ESocialMedias.LinkedIn]: Linkedin,
    [ESocialMedias.Instagram]: Instagram,
    [ESocialMedias.Twitter]: X,
    [ESocialMedias.Facebook]: Facebook,
    [ESocialMedias.Reddit]: Reddit,
    [ESocialMedias.Pinterest]: Pinterest,
    [ESocialMedias.TikTok]: Tiktok,
  }
  const SocialMediaIcon =
    SOCIAL_MEDIA_ICONS[identifySocialMedia(profileUrl) as ESocialMedias] || Clip

  return (
    <div className={'flex h-8 bg-baseColor  justify-between items-center'}>
      <div className="flex flex-row gap-3 items-center">
        <div className="rounded-full bg-neutralColor h-[32px] w-[32px] flex items-center justify-center">
          <SocialMediaIcon color={weCheckPalette.baseColor} />
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-neutralColor text-base font-medium">{userName}</p>
        </div>
      </div>

      <Button variant={'ghost'} onClick={onClick}>
        <p className="text-dynamicBlue">
          {t('dashboard.reportGeneralInfo.viewPosts')}
        </p>
      </Button>
    </div>
  )
}
