// schemas.ts
import { differenceInYears } from 'date-fns/differenceInYears'
import * as z from 'zod'

import { EUserRole } from '@/hooks/usePermissions/permissions.type'

import { sanitizeSocialMediaUrl } from './sanitizeSocialMediaUrl'

// Regex:
const phoneRegex = /^(.*)$/

const emailRegex = /^(\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+)?$/

const nameRegex = /^(?!.*([áéíóúÁÉÍÓÚñÑ'’`])\1{2,})([a-zA-ZáéíóúÁÉÍÓÚñÑ\s'-])+$/

const middleNameRegex =
  /^(?!.*([áéíóúÁÉÍÓÚñÑ'’`])\1{2,})([a-zA-ZáéíóúÁÉÍÓÚñÑ\s'-]*)$/

const alpanumericWithSpaces = /^([a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s'-])*$/

const alpanumericWithSpacesAndPoints =
  /^(?!.*([a-zA-Z0-9áéíóúÁÉÍÓÚñÑ])\1{2,})([a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s'-]*(\.[^.\s]*){0,2})*$/

const addressRegex =
  /^(?!.*([áéíóúÁÉÍÓÚüÜñÑ])\1{2,})([\w\d\s.,\-áéíóúÁÉÍÓÚüÜñÑ]+)$/

const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&_*-]).{8,}$/

const cityRegex =
  /^(?!.*([a-zA-ZáéíóúÁÉÍÓÚñÑ])\1{2,})([a-zA-ZáéíóúÁÉÍÓÚñÑ\s.']*)$/
const state = /^(?!.*([a-zA-ZáéíóúÁÉÍÓÚñÑ])\1{2,})([a-zA-ZáéíóúÁÉÍÓÚñÑ ,.'-]*)$/

const numbers = /^(\d{1,6})?$/

const phoneOptional = /^(.*)$/

const organizationName =
  /^(?!.*([a-zA-Z0-9áéíóúÁÉÍÓÚñÑ])\1{2,})([A-Za-z0-9áéíóúÁÉÍÓÚñÑ_.,;:¡!¿?"'@#~$%&*()\-+=\[\]{}<>\s]*)$/

const socialMediaRegex =
  /^(https?:\/\/)?(www\.)?(x\.com|tiktok\.com|linkedin\.com|facebook\.com|instagram\.com|pinterest\.com)?\/?.*$/

const optionalCollageRegex =
  /^(?!.*([a-zA-Z0-9áéíóúÁÉÍÓÚñÑ])\1{2,})([a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s,'-]{1,})?$/

const websiteRegex =
  /^(https?:\/\/)?([\w\-]+\.)+[\w\-]{2,}(\/[\w\-._~:/?#[\]@!$&'()*+,;=]*)?$|^$/

export const linkedInUrlRegex =
  /^(https?:\/\/)?(www\.)?linkedin\.com\/[a-zA-Z0-9-_/]*$/

export const linkedInCompanyUrlRegex =
  /^(https?:\/\/)?(www\.)?linkedin\.com\/(company|school|organization)\/[a-zA-Z0-9-_/]*$/

// Validation Functions
const webhookURLValidation = z
  .string()
  .trim()
  .url({
    message: 'Must be a valid URL.',
  })
  .refine((value) => !value.includes(' ') && !value.includes('\n'), {
    message: 'URL cannot contain spaces or line breaks',
  })

const booleanValidation = z
  .boolean()
  .refine((val) => val === true || val === false, {
    message: 'feedback.validations.booleanValidation',
  })

const roleValidation = z.enum(
  [
    EUserRole.OWNER,
    EUserRole.CO_OWNER,
    EUserRole.SUPERVISOR,
    EUserRole.WORKSPACE_ADMIN,
    EUserRole.MANAGER,
    EUserRole.TEAM_MANAGER,
    EUserRole.TEAM_ANALYST,
    EUserRole.OPERATIONAL_USER,
    EUserRole.VIEW_USER,
    EUserRole.READER,
  ],
  {
    errorMap: () => ({ message: 'feedback.validations.invalidRole' }),
  },
)

const pdfLanguageValidation = z
  .string()
  .min(1, { message: 'feedback.validations.pdfLanguageValidation' })

const emailValidation = z
  .string()
  .min(1, { message: 'feedback.validations.emailMinValidation' })
  .regex(emailRegex, 'feedback.validations.emailRegexValidation')

const creditsValidation = z.number()

const passwordValidation = z
  .string()
  .min(8, { message: 'feedback.validations.passwordMinValidation' })
  .max(128, {
    message: 'feedback.validations.passwordMaxValidation',
  })
  .regex(passwordRegex, 'feedback.validations.passwordRegexValidation')

const nameValidation = z
  .string()
  .min(2, { message: 'feedback.validations.nameMinValidation' })
  .regex(nameRegex, 'feedback.validations.nameRegexValidation')

const subjectValidation = z
  .string()
  .min(2, { message: 'feedback.validations.subjectMinValidation' })
  .regex(nameRegex, 'feedback.validations.subjectRegexValidation')

const phoneValidation = z
  .string()
  .min(1, { message: 'feedback.validations.phoneMinValidation' })
  .regex(phoneRegex, 'feedback.validations.phoneRegexValidation')

const commentValidation = z
  .string()
  .min(4, { message: 'feedback.validations.commentMinValidation' })
  .max(200, {
    message: 'feedback.validations.commentMaxValidation',
  })

const commentOptionalValidation = z
  .string()
  .max(200, {
    message: 'feedback.validations.commentMaxValidation',
  })
  .optional()

const optionalNameValidation = z
  .string()
  .regex(optionalCollageRegex, 'feedback.validations.nameRegexValidation')
  .optional()

const firstAndLastNameValidation = z
  .string()
  .min(2, { message: 'feedback.validations.nameMinValidation' })
  .max(50, { message: 'feedback.validations.firstAndLastNameMaxValidation' })
  .regex(nameRegex, 'feedback.validations.nameRegexValidation')

const middleNameValidation = z
  .string()
  .regex(middleNameRegex, 'feedback.validations.nameRegexValidation')
  .optional()

const companyNameValidation = z
  .string()
  .min(2, { message: 'feedback.validations.companyNameMinValidation' })
  .max(50, { message: 'feedback.validations.companyNameMaxValidation' })
  .regex(alpanumericWithSpaces, 'feedback.validations.nameRegexValidation')

const workspaceNameValidation = z
  .string()
  .min(2, { message: 'feedback.validations.companyNameMinValidation' })
  .max(50, { message: 'feedback.validations.companyNameMaxValidation' })
  .regex(alpanumericWithSpacesAndPoints, {
    message: 'feedback.validations.nameRegexValidation',
  })

const optionalCompanyNameValidation = z
  .string()
  .max(30, { message: 'feedback.validations.companyNameMaxValidation' })
  .regex(alpanumericWithSpaces, 'feedback.validations.nameRegexValidation')
  .optional()

const companyAddressValidation = z
  .string()
  .min(2, { message: 'feedback.validations.companyAddressMinValidation' })
  .max(120, { message: 'feedback.validations.companyAddressMaxValidation' })
  .regex(addressRegex, 'feedback.validations.companyAddressRegexValidation')

const cityValidation = z
  .string()
  .optional()
  .refine((value) => !value || cityRegex.test(value), {
    message: 'feedback.validations.cityRegexValidation',
  })

const countryValidation = z
  .string()
  .min(3, { message: 'feedback.validations.countryMinValidation' })
  .regex(cityRegex, 'feedback.validations.countryRegexValidation')
const optionalCityValidation = z
  .string()
  .regex(cityRegex, 'feedback.validations.cityRegexValidation')
  .optional()

const stateValidation = z
  .string()
  .optional()
  .refine((value) => !value || state.test(value), {
    message: 'feedback.validations.stateRegexValidation',
  })

const optionalStateValidation = z
  .string()
  .regex(state, 'feedback.validations.stateRegexValidation')
  .optional()

const pinValidation = z.string().min(6)

const newSearchPhone = z.preprocess(
  (a) => {
    if (a) {
      return a
    } else {
      return ''
    }
  },
  z.string().regex(phoneOptional, {
    message: 'feedback.validations.newSearchPhoneRegexValidation',
  }),
)

const requiredPhone = z
  .string({ description: 'feedback.validations.requiredPhoneValidation' })
  .min(9)

const backgroundChecksNumber = z
  .string()
  .max(6, 'feedback.validations.backgroundChecksNumberMaxValidation')
  .regex(numbers, 'feedback.validations.backgroundChecksNumberRegexValidation')

const optionalEmail = z
  .string()
  .regex(emailRegex, 'feedback.validations.optionalEmailRegexValidation')

const organizationNameValidation = z
  .string()
  .regex(
    organizationName,
    'feedback.validations.organizationNameRegexValidation',
  )
  .min(2, 'feedback.validations.organizationNameMinValidation')
  .max(128, 'feedback.validations.organizationNameMaxValidation')
const optionalWebsiteValidation = z
  .string()
  .regex(websiteRegex, 'feedback.validations.optionalEmailwebsiteValidation')
  .optional()

const searchInputValidation = z
  .string()
  .min(1)
  .refine(
    (value) => {
      if (linkedInCompanyUrlRegex.test(value)) {
        return false
      }
      return nameRegex.test(value) || linkedInUrlRegex.test(value)
    },
    (value) => {
      if (linkedInCompanyUrlRegex.test(value)) {
        return { message: 'feedback.validations.companyLinkedInError' }
      }
      return { message: 'feedback.validations.invalidNameOrLinkedIn' }
    },
  )

const linkedinSearchResultsValidation = z
  .string()
  .refine(
    (value) => {
      if (!value) return true
      if (linkedInCompanyUrlRegex.test(value)) {
        return false
      }
      return linkedInUrlRegex.test(value)
    },
    (value) => {
      if (linkedInCompanyUrlRegex.test(value)) {
        return { message: 'feedback.validations.companyLinkedInError' }
      }
      return { message: 'feedback.validations.invalidLinkedIn' }
    },
  )
  .optional()

const imageFileValidation = z
  .instanceof(File)
  .refine((file) => file.type.startsWith('image/'), {
    message: 'The file must be an image.',
  })

const imageUrlValidation = z.string().url({
  message: 'Must be a valid URL.',
})

const imageValidation = z.union([
  imageFileValidation,
  imageUrlValidation,
  z.null(),
])

// Schemas
export const loginSchema = z.object({
  email: emailValidation,
  password: passwordValidation,
})

export const contactUsSchema = z.object({
  organizationName: organizationNameValidation,
  name: nameValidation,
  email: emailValidation,
  phoneNumber: newSearchPhone,
  comment: commentValidation,
})

export const letsTalkSchema = z.object({
  name: nameValidation,
  email: emailValidation,
  comment: commentValidation,
})

export const BackgroundCheckContactUsSchema = z.object({
  subject: subjectValidation,
  comment: commentValidation,
})

export const signUpSchema = z.object({
  email: emailValidation,
  createPassword: passwordValidation,
  confirmPassword: passwordValidation,
})

export const signUpFirstStepSchema = z.object({
  firstName: firstAndLastNameValidation,
  lastName: firstAndLastNameValidation,
  phoneNumber: phoneValidation,
})

export const signUpSecondStepSchema = z.object({
  companyName: organizationNameValidation,
  companyAddress: companyAddressValidation,
})

export const signUpFinalStepSchema = z.object({
  backgroundChecksNumber: backgroundChecksNumber,
  comment: commentOptionalValidation,
})

export const inviteMembersSchema = z.object({
  email: emailValidation,
})

export const socialUrlSchema = z
  .string()
  .max(150, 'feedback.validations.socialUrlSchemaMaxValidation')
  .regex(
    socialMediaRegex,
    'feedback.validations.socialUrlSchemaRegexValidation',
  )

const isValidDateRange = (date: string, canPassMinor: boolean) => {
  if (!date || date === '' || (date && date.length < 10)) {
    return true
  }

  const years = differenceInYears(new Date(), new Date(date))

  if (canPassMinor) {
    return years >= 1 && years <= 130
  } else {
    return years >= 18 && years <= 130
  }
}

const isValidDate = (date: string) => {
  if (
    !date ||
    date === '' ||
    (date && date.length < 2) ||
    (date && date.length > 2 && date.length < 10)
  ) {
    return false
  }
  return true
}

const optionalIsValidDate = (date: string | undefined | null) => {
  if (!date) {
    return true
  }
  if (date.length < 2 || (date.length > 2 && date.length < 10)) {
    return false
  }
  return true
}

const isValidNumericAge = (date: string, canPassMinor: boolean) => {
  if (
    !date ||
    date === '' ||
    (date && date.length < 2) ||
    (date && date.length > 2 && date.length <= 10)
  ) {
    return true
  }
  const castedAge = Number(date)
  if (canPassMinor) {
    return castedAge > 9
  } else {
    return castedAge > 17
  }
}

export const dob = z
  .string()
  .refine((data) => isValidDateRange(data, false), {
    message: 'feedback.validations.dobIsValidDateRange',
  })
  .refine((data) => isValidDate(data), {
    message: 'feedback.validations.dobIsValidDate',
  })
  .refine((data) => isValidNumericAge(data, false), {
    message: 'feedback.validations.dobIsValidNumericAge',
  })

export const optionalDob = z
  .string()
  .refine((data) => isValidDateRange(data, true), {
    message: 'feedback.validations.dobWithMinorIsValidDateRange',
  })
  .refine((data) => optionalIsValidDate(data), {
    message: 'feedback.validations.dobWithMinorIsValidDate',
  })
  .refine((data) => isValidNumericAge(data, true), {
    message: 'feedback.validations.dobWithMinorIsValidNumericAge',
  })
  .optional()

export const dobWithMinor = z
  .string()
  .refine((data) => isValidDateRange(data, true), {
    message: 'feedback.validations.dobWithMinorIsValidDateRange',
  })
  .refine((data) => isValidDate(data), {
    message: 'feedback.validations.dobWithMinorIsValidDate',
  })
  .refine((data) => isValidNumericAge(data, true), {
    message: 'feedback.validations.dobWithMinorIsValidNumericAge',
  })

export const newSearchSchema = z.object({
  firstName: firstAndLastNameValidation,
  middleName: optionalNameValidation,
  lastName: firstAndLastNameValidation,
  alias: optionalNameValidation,
  dob: dob,
  addressCity: cityValidation,
  addressCountry: z.optional(countryValidation),
  addressState: z.optional(stateValidation),
  email: z.optional(optionalEmail),
  phoneNumber: newSearchPhone,
  companyName: companyNameValidation,
  collage: optionalNameValidation,
})

export const searchFormSchema = z.object({
  firstName: firstAndLastNameValidation,
  lastName: firstAndLastNameValidation,
  email: emailValidation,
  phoneNumber: newSearchPhone.optional(),
  countryLongName: countryValidation,
  countryShortName: z.string().optional(),
  stateLongName: stateValidation.optional(),
  stateShortName: z.string().optional(),
  cityLongName: cityValidation.optional(),
  cityShortName: z.string().optional(),
  middleName: middleNameValidation.optional(),
  alias: optionalNameValidation.optional(),
  dob: optionalDob.optional(),
  companyName: optionalCompanyNameValidation.optional(),
  collage: optionalNameValidation.optional(),
  image: imageValidation.optional().nullable(),
})

export const prefillSearchFormSchema = z.object({
  firstName: firstAndLastNameValidation,
  lastName: firstAndLastNameValidation,
  email: optionalEmail,
  phoneNumber: newSearchPhone.optional(),
  countryLongName: countryValidation,
  countryShortName: z.string().optional(),
  stateLongName: stateValidation.optional(),
  stateShortName: z.string().optional(),
  cityLongName: cityValidation.optional(),
  cityShortName: z.string().optional(),
  middleName: middleNameValidation.optional(),
  alias: optionalNameValidation.optional(),
  dob: optionalDob.optional(),
  companyName: optionalCompanyNameValidation.optional(),
  collage: optionalNameValidation.optional(),
  image: imageValidation.optional().nullable(),
})

export const newSearchStep3Schema = z.object({
  middleName: optionalNameValidation,
  alias: optionalNameValidation,
  phoneNumber: newSearchPhone,
  collage: optionalNameValidation,
  addressCity: optionalCityValidation,
  addressState: optionalStateValidation,
  dob: optionalDob,
  companyName: optionalCompanyNameValidation,
})

export const newSearchSchemaWithMinor = z.object({
  firstName: firstAndLastNameValidation,
  middleName: optionalNameValidation,
  lastName: firstAndLastNameValidation,
  alias: optionalNameValidation,
  dob: dobWithMinor,
  addressCity: cityValidation,
  addressCountry: z.optional(countryValidation),
  addressState: z.optional(stateValidation),
  email: z.optional(optionalEmail),
  phoneNumber: newSearchPhone,
  companyName: companyNameValidation,
  collage: optionalNameValidation,
})

export const loadSocialMediaUrlSchema = z.object({
  socialMediaUrl: z.string().transform((url) => sanitizeSocialMediaUrl(url)),
})

export const newQuickSearchSchema = z.object({
  firstName: optionalNameValidation,
  middleName: optionalNameValidation,
  lastName: optionalNameValidation,
  phoneNumber: requiredPhone,
})

export const profileSchema = z.object({
  firstName: nameValidation,
  lastName: nameValidation,
  phoneNumber: requiredPhone,
  mail: optionalNameValidation,
})

export const enterpriseSchema = z.object({
  companyName: companyNameValidation,
  companyAddress: companyAddressValidation,
})

export const forgotPasswordSchema = z.object({
  email: emailValidation,
})

export const invitePaswordFlowSchema = z.object({
  createPassword: passwordValidation,
  confirmPassword: passwordValidation,
})

export const inviteNameFlowSchema = z.object({
  firstname: nameValidation,
  lastname: nameValidation,
})

export const createPasswordSchema = z.object({
  createPassword: passwordValidation,
  confirmPassword: passwordValidation,
})

export const emailValidationSchema = z.object({
  pin: pinValidation,
})

export const pDFReportSectionSchema = z.object({
  coreFindings: booleanValidation,
  flaggedBehaviors: booleanValidation,
  language: pdfLanguageValidation,
  negativePosts: booleanValidation,
  neutralPosts: booleanValidation,
  positivePosts: booleanValidation,
  sentimentAnalysis: booleanValidation,
  socialMediaAccounts: booleanValidation,
  webNewsPosts: booleanValidation,
  personalityAndSkills: booleanValidation,
  educationAndCareer: booleanValidation,
})

export const switchSectionSchema = z.record(z.boolean().optional())

export const consentSchema = z.object({
  email: optionalEmail,
})

export const workSpaceSchema = z.object({
  workSpaceName: workspaceNameValidation,
})

export const workSpaceSecondStepSchema = z.object({
  createTeamName: workspaceNameValidation,
})

export const workSpaceThirdStepSchema = z.object({
  email: emailValidation,
})

export const createTeamSecondStepSchema = z.object({
  email_0: emailValidation,
  email_1: emailValidation.optional(),
  email_2: emailValidation.optional(),
  email_3: emailValidation.optional(),
  email_4: emailValidation.optional(),
})

export const creditsSchema = z.object({
  ai_report: creditsValidation,
  ai_analyst_report: creditsValidation,
  adverse_media_report: creditsValidation,
  assisted_report: creditsValidation,
  facial_matching: creditsValidation,
})

export const apiKeySchema = z.object({
  name: companyNameValidation,
  webhookUrl: webhookURLValidation,
})

export const renameSchema = z.object({
  name: companyNameValidation,
})

export const companyInfoSchema = z.object({
  companyName: companyNameValidation,
  companyWebsite: optionalWebsiteValidation,
  industry: optionalNameValidation,
  country: countryValidation,
  state: stateValidation,
  city: cityValidation,
})

export const searchInputSchema = z.object({
  searchInput: searchInputValidation,
})

export const inviteWorkspaceSchema = z.object({
  email: emailValidation,
})

export const searchDetailsSchema = z.object({
  firstName: nameValidation.optional(),
  lastName: nameValidation.optional(),
  school: optionalNameValidation.optional(),
  company: z.string(),
  linkedInUrl: linkedinSearchResultsValidation.optional(),
})

export const inviteMemberValidation = z.object({
  email: emailValidation,
  role: roleValidation,
})

export const manageTeamsFormSchema = z.object({
  teams: z.record(z.array(z.string())),
  role: z.string().optional(),
})

export type switchSectionType = z.TypeOf<typeof switchSectionSchema>
export type LoginType = z.TypeOf<typeof loginSchema>
export type ContactUsType = z.TypeOf<typeof contactUsSchema>
export type letsTalkType = z.TypeOf<typeof letsTalkSchema>
export type BackgroundCheckContactUsType = z.TypeOf<
  typeof BackgroundCheckContactUsSchema
>
export type NewSearchType = z.TypeOf<typeof newSearchSchema>
export type LoadSocialMediaUrlSchemaType = z.TypeOf<
  typeof loadSocialMediaUrlSchema
>
export type signUpType = z.TypeOf<typeof signUpSchema>
export type signUpFirstStepType = z.TypeOf<typeof signUpFirstStepSchema>
export type signUpSecondStepType = z.TypeOf<typeof signUpSecondStepSchema>
export type signUpFinalStepType = z.TypeOf<typeof signUpFinalStepSchema>
export type forgotPasswordType = z.TypeOf<typeof forgotPasswordSchema>
export type invitePaswordFlowType = z.TypeOf<typeof invitePaswordFlowSchema>
export type inviteNameFlowType = z.TypeOf<typeof inviteNameFlowSchema>
export type createPasswordType = z.TypeOf<typeof createPasswordSchema>
export type inviteMembersType = z.TypeOf<typeof inviteMembersSchema>
export type emailValidationType = z.TypeOf<typeof emailValidationSchema>
export type profileType = z.TypeOf<typeof profileSchema>
export type enterpriseType = z.TypeOf<typeof enterpriseSchema>
export type searchFormSchemaTypes = z.TypeOf<typeof searchFormSchema>
export type searchInputSchemaTypes = z.TypeOf<typeof searchInputSchema>
export type searchDetailsSchemaType = z.TypeOf<typeof searchDetailsSchema>
export type prefillSearchFormSchemaTypes = z.TypeOf<
  typeof prefillSearchFormSchema
>

export type newSearchStep3Type = z.TypeOf<typeof newSearchStep3Schema>
export type pDFReportSectionType = z.TypeOf<typeof pDFReportSectionSchema>
export type consentSchemaType = z.TypeOf<typeof consentSchema>
export type companyInfoSchemaType = z.TypeOf<typeof companyInfoSchema>
export type workSpaceSchemaType = z.TypeOf<typeof workSpaceSchema>
export type creditsSchemaType = z.TypeOf<typeof creditsSchema>
export type apiKeySchemaType = z.TypeOf<typeof apiKeySchema>
export type renameSchemaType = z.TypeOf<typeof renameSchema>
export type inviteWorkspaceSchemaType = z.TypeOf<typeof inviteWorkspaceSchema>
export type workSpaceSecondStepSchemaType = z.TypeOf<
  typeof workSpaceSecondStepSchema
>
export type workSpaceThirdStepSchemaType = z.TypeOf<
  typeof workSpaceThirdStepSchema
>
export type createTeamSecondStepSchemaType = z.TypeOf<
  typeof createTeamSecondStepSchema
>
export type inviteMemberValidationType = z.TypeOf<typeof inviteMemberValidation>
export type ManageTeamsFormValues = z.infer<typeof manageTeamsFormSchema>
