import { zodResolver } from '@hookform/resolvers/zod'
import { CloseSquare, SearchNormal1 } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import AvatarPending from '@/assets/Avatar-pending.svg?react'
import { AlertDialog, AlertDialogContent } from '@/components/ui/alert-dialog'
import { Avatar } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import {
  EUserPermission,
  EUserRole,
  type EUserRoleSelector,
} from '@/hooks/usePermissions/permissions.type'
import { usePermissions } from '@/hooks/usePermissions/usePermissions'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import {
  useDeleteEnterpriseUserMutation,
  useUpdateEnterpriseUserRoleMutation,
} from '@/store/api/Enterprise/Enterprise.query'
import {
  useGetWorkSpacesQuery,
  useInviteMemberToATeamMutation,
  useRemoveMemberFromTeamMutation,
} from '@/store/api/workspace/workspace.query'
import type {
  MemberToAssign,
  UserData,
} from '@/store/api/workspace/workspace.types'
import { getColorByName } from '@/utils/colorByName'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import type { ManageTeamsFormValues } from '@/utils/schemas'
import { manageTeamsFormSchema } from '@/utils/schemas'

import { DobleAvatarPlusNameCard } from './DobleAvatarPlusNameCard'
import { EditMemberCard } from './EditMemberCard'
import { MemberItem } from './MemberItem'
import { PopUpTopHeader } from './PopUpTopHeader'

export const ManageCompany = ({
  isOpen,
  setIsOpen,
  name,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  name?: string
}) => {
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()
  const isSmallMobile = useSmallMobileMediaQuery()

  const [showMore, setShowMore] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const [selectedUser, setSelectedUser] = useState<UserData | null>(null)

  const [selectedRole, setSelectedRole] = useState<
    EUserRoleSelector | 'remove' | null
  >(null)

  const [selectedValuesArray, setSelectedValuesArray] = useState<{
    [key: string]: any[]
  }>({})

  const [unselectedValuesArray, setUnselectedValuesArray] = useState<{
    [key: string]: any[]
  }>({})

  const { data } = useGetWorkSpacesQuery()
  const { workspaces, allMembers } = data?.info ?? {}

  const [inviteMemberToATeam, { isLoading: isInvitingMember }] =
    useInviteMemberToATeamMutation()

  const [removeMemberFromTeam, { isLoading: isRemovingMember }] =
    useRemoveMemberFromTeamMutation()

  const [deleteEnterpriseUser, { isLoading: isLoadingDeleteEnterpriseUser }] =
    useDeleteEnterpriseUserMutation()

  const handleRoleChange = (role: EUserRoleSelector) => {
    setSelectedRole(role)
  }

  const [updateMemberRole, { isLoading: isUpdatingMemberRole }] =
    useUpdateEnterpriseUserRoleMutation()

  const { hasPermission } = usePermissions()

  const form = useForm<ManageTeamsFormValues>({
    resolver: zodResolver(manageTeamsFormSchema),
    defaultValues: {
      teams: {},
      role: selectedUser?.user?.role,
    },
  })

  useEffect(() => {
    if (selectedUser?.user) {
      const currentTeams =
        workspaces?.reduce(
          (acc, workspace) => {
            const teamIds = workspace.teams
              .filter(
                (team) =>
                  team.members?.some(
                    (member) => member.userEmail === selectedUser.userEmail,
                  ),
              )
              .map((team) => team._id)

            if (teamIds.length > 0) {
              acc[workspace._id] = teamIds
            }
            return acc
          },
          {} as Record<string, string[]>,
        ) || {}

      form.reset({
        teams: currentTeams,
        role: selectedUser.user.role,
      })
    }
  }, [selectedUser, workspaces])

  const handleShowMore = () => {
    setShowMore(!showMore)
  }

  const closeModal = () => {
    setIsOpen(false)
    setShowMore(false)
    setSearchTerm('')
    setSelectedUser(null)
    setSelectedRole(null)
    setSelectedValuesArray({})
    setUnselectedValuesArray({})
  }

  const handleSelectedValueChange = (team: any) => {
    setUnselectedValuesArray((prev) => {
      const { workspaceId } = team
      const currentTeams = prev[workspaceId] || []
      const filteredTeams = currentTeams.filter((t) => t.id !== team.id)

      if (filteredTeams.length === 0) {
        const { [workspaceId]: _, ...rest } = prev
        return rest
      }

      return {
        ...prev,
        [workspaceId]: filteredTeams,
      }
    })

    setSelectedValuesArray((prev) => {
      const { workspaceId } = team
      const currentTeams = prev[workspaceId] || []
      const teamExists = currentTeams.some((t) => t.id === team.id)

      if (teamExists) return prev

      const newState = {
        ...prev,
        [workspaceId]: [...currentTeams, { ...team, workspaceId }],
      }
      return newState
    })
  }

  const handleUnselectedValueChange = (team: any) => {
    setSelectedValuesArray((prev) => {
      const { workspaceId } = team
      const currentTeams = prev[workspaceId] || []
      const filteredTeams = currentTeams.filter((t) => t.id !== team.id)

      if (filteredTeams.length === 0) {
        const { [workspaceId]: _, ...rest } = prev
        return rest
      }

      return {
        ...prev,
        [workspaceId]: filteredTeams,
      }
    })

    setUnselectedValuesArray((prev) => {
      const { workspaceId } = team
      const currentTeams = prev[workspaceId] || []
      const teamExists = currentTeams.some((t) => t.id === team.id)

      if (teamExists) return prev

      const newState = {
        ...prev,
        [workspaceId]: [...currentTeams, { ...team, workspaceId }],
      }
      return newState
    })
  }

  const handleDeleteMember = async () => {
    try {
      await deleteEnterpriseUser({
        userId: selectedUser?.user?._id ?? '',
      }).unwrap()

      toast.success(t('dashboard.company.manageModal.memberRemoved'))
      closeModal()
    } catch (error) {
      if (error.data?.code === 'USER_NOT_ALLOWED') {
        toast.error(t('dashboard.company.manageModal.cannotDeleteOwner'))
      } else {
        catchWithSentry(error)
        toast.error(t('dashboard.company.manageModal.errorRemovingMember'))
      }
    }
  }

  const handleTeamAssignment = async () => {
    if (!selectedUser?.userEmail) {
      toast.error(t('dashboard.company.manageModal.emailRequired'))
      return
    }
    try {
      const memberData: MemberToAssign = {
        email: selectedUser.userEmail,
        role: selectedUser.user?.role ?? '',
        workspaces: Object.entries(selectedValuesArray).map(
          ([workspaceId, teams]) => ({
            workspaceId,
            teamIds: teams.map((team) => team.id),
          }),
        ),
      }
      await inviteMemberToATeam(memberData).unwrap()

      if (Object.keys(unselectedValuesArray).length > 0) {
        const workspaces = Object.entries(unselectedValuesArray).map(
          ([workspaceId, teams]) => ({
            workspaceId,
            teamIds: teams.map((team) => team.id),
          }),
        )

        await removeMemberFromTeam({
          workspaces,
          memberId: selectedUser.user?._id ?? '',
        }).unwrap()
      }

      toast.success(t('dashboard.company.manageModal.memberTeamUpdated'))
    } catch (error) {
      catchWithSentry(error)
      toast.error(t('dashboard.company.manageModal.memberTeamUpdatedError'))
    } finally {
      setSelectedValuesArray({})
      setUnselectedValuesArray({})
    }
  }

  const handleUpdateMemberRole = async () => {
    if (!selectedUser?.user || !selectedRole) return

    try {
      await updateMemberRole({
        id: selectedUser.user._id,
        role: selectedRole,
      }).unwrap()
      toast.success(t('dashboard.company.manageModal.memberRoleUpdated'))
    } catch (error) {
      catchWithSentry(error)
      toast.error(t('dashboard.company.manageModal.memberRoleUpdatedError'))
    }
  }
  const handleSaveEditMember = async () => {
    if (!selectedUser?.user) return

    if (selectedRole && selectedRole !== selectedUser.user.role) {
      await handleUpdateMemberRole()
    }

    const hasTeamChanges =
      Object.keys(selectedValuesArray).length > 0 ||
      Object.keys(unselectedValuesArray).length > 0

    if (hasTeamChanges) {
      await handleTeamAssignment()
    }

    setSelectedUser(null)
    setSelectedRole(null)
    form.reset()
  }

  const handleEditMember = (selectedUser: UserData) => {
    setSelectedUser(selectedUser)
    setShowMore(true)

    if (selectedUser?.user?.role) {
      setSelectedRole(selectedUser.user.role as EUserRoleSelector)
    }
  }

  const filterMembers = (userToFilter: UserData) => {
    const firstName = userToFilter.user?.firstname ?? ''
    const lastName = userToFilter.user?.lastname ?? ''

    const fullName = `${firstName} ${lastName}`

    return (
      fullName.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      userToFilter.userEmail.toLowerCase().includes(searchTerm?.toLowerCase())
    )
  }

  const isLoading = isInvitingMember || isRemovingMember || isUpdatingMemberRole

  const nameShowInOthers =
    allMembers?.[2]?.user?.firstname ?? allMembers?.[2]?.userEmail

  const showMoreMembers =
    !selectedUser && !showMore && allMembers?.length && allMembers?.length > 2
  const showPreviewMembers = allMembers && showMore && !selectedUser

  const role = selectedUser?.user?.role as EUserRole

  const hasEditPermission = hasPermission(EUserPermission.HANDLE_USERS, role)

  return (
    <AlertDialog open={isOpen} onOpenChange={closeModal}>
      <AlertDialogContent
        className={`flex flex-col gap-3 ${
          isMobile ? 'w-[350px]' : isSmallMobile ? 'w-[250px]' : 'w-[500px]'
        }`}
      >
        <div className="flex flex-1 flex-col gap-3">
          {/* Modal Home */}
          {!showMore && (
            <>
              <div className="flex flex-row justify-between items-center">
                <p className="text-base font-bold">{name}</p>

                <div className="flex flex-row justify-end items-center border-neutralColor60">
                  <Button
                    onClick={closeModal}
                    variant="link"
                    className="text-base"
                  >
                    <CloseSquare
                      width={18}
                      height={17}
                      variant="Bold"
                      color={weCheckPalette.neutralColor}
                    />
                  </Button>
                </div>
              </div>

              {/* Preview Members */}
              {allMembers?.length ? (
                <div className="flex flex-col gap-4">
                  <div>
                    <p className="text-neutralColor60 text-sml">
                      {t('dashboard.company.manageModal.whoHasAccess')}
                    </p>
                  </div>

                  {allMembers.slice(0, 2).map((user, index) => (
                    <MemberItem
                      key={`${user.user?._id}-${index}`}
                      firstname={user.user?.firstname ?? ''}
                      lastname={user.user?.lastname ?? ''}
                      email={user.userEmail}
                      invite={user.invite}
                      role={user.user?.role ?? null}
                      color={getColorByName(
                        user.user?.firstname ?? user.userEmail,
                        user.user?.lastname ?? '',
                      )}
                      onClick={() => {
                        setSelectedUser(user)
                        setShowMore(true)
                      }}
                    />
                  ))}
                </div>
              ) : null}
            </>
          )}
          {/* Show more members */}
          {showMoreMembers ? (
            <div className="flex flex-1 flex-col">
              <button
                onClick={handleShowMore}
                className="flex flex-1 text-neutralColor"
              >
                {showMore ? (
                  ''
                ) : (
                  <div className="flex flex-1 my-4">
                    <DobleAvatarPlusNameCard
                      nameShowInOthers={nameShowInOthers}
                      countElements={allMembers?.length}
                      rightIcon
                      isTeamIcon
                    />
                  </div>
                )}
              </button>
            </div>
          ) : null}

          {/* View list of all members */}
          {showPreviewMembers && (
            <div className="flex flex-1 flex-col">
              <PopUpTopHeader
                text={`${allMembers?.length} ${t(
                  'dashboard.company.manageModal.colaboratorsEnterprise',
                )}`}
                onClick={() => {
                  setShowMore(false)
                  setSearchTerm('')
                }}
                setIsOpen={closeModal}
              />

              <Input
                placeholder={t('dashboard.company.search')}
                className="h-[46px] rounded-lg w-full p-4 mb-2 mt-2"
                icon={
                  <SearchNormal1
                    className="absolute mt-6 mr-4"
                    size="18"
                    color={weCheckPalette.neutralColor30}
                  />
                }
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <div className="flex flex-1 flex-col max-h-[330px] overflow-y-auto gap-3 mt-4 scrollbar-thin scrollbar-thumb-neutralColor60 scrollbar-track-transparent">
                {allMembers?.filter(filterMembers).map((user, index) => (
                  <div key={`${user.user?._id}-member-item-${index}`}>
                    <MemberItem
                      key={`${user.user?._id}-${index}`}
                      firstname={user?.user?.firstname ?? ''}
                      lastname={user?.user?.lastname ?? ''}
                      email={user?.userEmail}
                      role={user?.user?.role ?? null}
                      invite={user?.invite}
                      onClick={() => handleEditMember(user)}
                      color={getColorByName(
                        user?.user?.firstname ?? user?.userEmail,
                        user?.user?.lastname ?? '',
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Edit Selected User View*/}
          {selectedUser && (
            <div className="flex flex-1 flex-col gap-4">
              {!selectedUser?.invite ? (
                <>
                  <PopUpTopHeader
                    text={t('dashboard.company.manageModal.memberSettings')}
                    onClick={() => setSelectedUser(null)}
                    setIsOpen={closeModal}
                  />

                  <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(handleSaveEditMember)}>
                      <EditMemberCard
                        role={selectedUser.user?.role as EUserRole}
                        firstname={selectedUser?.user?.firstname ?? ''}
                        lastname={selectedUser?.user?.lastname ?? ''}
                        email={selectedUser?.userEmail ?? ''}
                        color={getColorByName(
                          selectedUser?.user?.firstname ??
                            selectedUser?.userEmail,
                          selectedUser?.user?.lastname ?? '',
                        )}
                        onRoleChange={handleRoleChange}
                        workspaces={workspaces}
                        handleSelectedValueChange={handleSelectedValueChange}
                        handleUnselectedValueChange={
                          handleUnselectedValueChange
                        }
                        isLoadingDeleteEnterpriseUser={
                          isLoadingDeleteEnterpriseUser
                        }
                        handleDeleteMember={handleDeleteMember}
                      />

                      <div className="flex flex-1 flex-col justify-end pt-2">
                        <div className="flex flex-row gap-3 justify-end border-t-[0.5px] border-neutralColor60 pt-3">
                          <Button
                            variant="secondary"
                            onClick={() => setSelectedUser(null)}
                            disabled={
                              isLoading ||
                              !hasEditPermission ||
                              role === EUserRole.OWNER
                            }
                            type="button"
                          >
                            {t('dashboard.company.manageModal.cancel')}
                          </Button>
                          <Button
                            variant="default"
                            type="submit"
                            isLoading={isLoading}
                            disabled={
                              !hasEditPermission ||
                              role === EUserRole.OWNER ||
                              (role === EUserRole.CO_OWNER &&
                                !hasPermission(
                                  EUserPermission.HANDLE_USERS,
                                  EUserRole.OWNER,
                                )) ||
                              (role === EUserRole.CO_OWNER && !selectedRole)
                            }
                          >
                            {t('dashboard.company.manageModal.save')}
                          </Button>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </>
              ) : (
                <>
                  <PopUpTopHeader
                    text={t('dashboard.company.editInvite.inviteSettings')}
                    onClick={() => setSelectedUser(null)}
                    setIsOpen={closeModal}
                  />

                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row items-center gap-3">
                      <Avatar>
                        <AvatarPending />
                      </Avatar>

                      <div className="flex flex-col">
                        <p className="text-sm text-neutralColor60">
                          {selectedUser?.userEmail}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
