import type { ColumnDef, VisibilityState } from '@tanstack/react-table'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { parseISO, format } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EmptyState } from '@/components/EmptyState/EmptyState'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Chip } from '@/pages/Home/components/Chip/Chip'
import { useGetApiKeyQuery } from '@/store/api/ApiKey/ApiKey.query'

import { CreateApiKeyPopUp } from '../CreateApiKeyPopUp/CreateApiKeyPopUp'
import { DeleteApiKeyPopUp } from '../DeleteApiKeyPopUp/DeleteApiKeyPopUp'
import { EditApiKeyPopUp } from '../EditApiKeyPopUp/EditApiKeyPopUp'

export const Apikey = () => {
  const { t } = useTranslation()
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = useState({})
  const { data: apiKeyData, isLoading: isLoadingApiKey } = useGetApiKeyQuery()

  const formatDate = (dateString: string) => {
    if (!dateString) return 'Without description'
    const date = parseISO(dateString)
    return format(date, 'dd/MM/yyyy')
  }

  const columns: ColumnDef<any>[] = [
    {
      accessorKey: 'name',
      header: t('dashboard.apiKey.name'),
      id: 'name',
      cell: ({ row }: { row: any }) => (
        <a href={row.original.name} target="_blank" rel="noopener noreferrer">
          {row.original.name}
        </a>
      ),
    },
    {
      accessorKey: 'key',
      header: t('dashboard.apiKey.secretKey'),
      id: 'key',
      cell: ({ row }: { row: any }) => (
        <div
          className="max-w-[200px] truncate overflow-hidden text-ellipsis whitespace-nowrap"
          title={row.original.key}
        >
          {row.original.key || 'Without description'}
        </div>
      ),
    },
    {
      accessorKey: 'createdAt',
      header: t('dashboard.apiKey.created'),
      id: 'createdAt',
      cell: ({ row }: { row: any }) => (
        <div className="inline-flex">
          <Chip
            blue
            borderRadius="24px"
            paddingLeft="12px"
            paddingRight="12px"
            text={formatDate(row.original.createdAt)}
          ></Chip>
        </div>
      ),
    },

    {
      accessorKey: 'lastUsedAt',
      header: t('dashboard.apiKey.lastUsed'),
      id: 'lastUsedAt',
      cell: ({ row }: { row: any }) => (
        <div className="inline-flex">
          <Chip
            blue
            borderRadius="24px"
            paddingLeft="12px"
            paddingRight="12px"
            text={formatDate(row.original.lastUsedAt)}
          ></Chip>
        </div>
      ),
    },
    {
      accessorKey: 'createdBy',
      header: t('dashboard.apiKey.createdBy'),
      id: 'createdBy',
      cell: ({ row }: { row: any }) =>
        row.original.createdBy || 'Without description',
    },
    {
      accessorKey: 'webhookUrl',
      header: t('dashboard.apiKey.webhookURL'),
      id: 'webhookUrl',
      cell: ({ row }: { row: any }) => (
        <div className="max-w-[300px]">
          <p className="truncate hover:text-clip hover:whitespace-normal">
            {row.original.webhookUrl || 'Without description'}
          </p>
        </div>
      ),
    },
    {
      id: 'actions',
      cell: ({ row }: { row: any }) => (
        <div className="flex justify-end">
          <EditApiKeyPopUp apiKey={row.original} />
          <DeleteApiKeyPopUp
            secretKey={row.original.key}
            apiKeyId={row.original._id}
          />
        </div>
      ),
    },
  ]

  const table = useReactTable({
    data: (apiKeyData as unknown as any[]) || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      columnVisibility,
      rowSelection,
    },
  })

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between items-center">
        <p className="text-neutralColor text-xl font-semibold tracking-wide font-satoshi mb-4">
          {t('dashboard.apiKey.apiKeyTitle')}
        </p>
        <CreateApiKeyPopUp />
      </div>
      <div>
        <p className="text-md mb-3">
          {t('dashboard.apiKey.generateAndControl')}
        </p>
        <div className="flex flex-row gap-1 items-center">
          <p>
            <span>{t('dashboard.apiKey.needHelp')} </span>
            {t('dashboard.apiKey.visitOur')}{' '}
          </p>
          <span
            className="text-linkColor "
            role="button"
            onClick={() =>
              window.open(t('dashboard.apiKey.apiDocumentationLink'), '_blank')
            }
            tabIndex={0}
          >
            {' '}
            {t('dashboard.apiKey.apiDocumentation')}
          </span>
        </div>
      </div>
      <div className="max-h-[550px] overflow-y-auto">
        <Table className="text-xs">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup: any) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => (
                  <TableHead className="text-neutralColor" key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>

          <TableBody>
            {isLoadingApiKey ? (
              <>
                {[...Array(6)].map((_, index) => (
                  <TableRow key={`loading-row-${index}`}>
                    {columns.map((column, cellIndex) => (
                      <TableCell
                        key={`loading-cell-${cellIndex}`}
                        className="text-sm font-medium tracking-tighter p-4"
                      >
                        <div className="h-4 bg-neutralColor60 animate-pulse rounded" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row: any) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell: any) => (
                    <TableCell
                      key={cell.id}
                      className="text-sm font-medium tracking-tighter p-4"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <EmptyState text={t('dashboard.apiKey.thereSeem')} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
