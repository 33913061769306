import { api, RTKTags } from '@/store/api'

import Env from '../../../env/env'

import type {
  getWorkSpaceReportsRequest,
  AssignCreditsToWorkspaceRequest,
  UpdateTeamData,
  WorkspaceDetailsRequest,
  WorkspaceDetailsResponse,
  WorkSpaceResponse,
  UpdateWorkspaceData,
  WorkspaceCreditsResponse,
  RemoveMemberFromTeamRequest,
  getWorkSpaceReportsCrudresponse,
  ReportInfo,
  MemberToAssign,
  getAllWorkSpaceReportsRequest,
} from './workspace.types'

export const workspaceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    workspaceDetails: builder.query<
      WorkspaceDetailsResponse,
      WorkspaceDetailsRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/enterprises/workspaces/${request.workspaceId}`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return response.info
      },
      providesTags: [RTKTags.workspaceDetails, RTKTags.getWorkSpaces],
    }),

    getWorkSpaceReports: builder.query<
      ReportInfo[],
      getWorkSpaceReportsRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/enterprises/workspaces/${request.id}/teams/${request.teamId}/subjects`,
        method: 'GET',
        params: {
          page: request.page,
          pageSize: request.pageSize,
          reportType: request.reportType,
          status: request.status,
          onlyUser: request.onlyUser,
          searchByString: request.searchByString,
        },
      }),
      transformResponse: (response: getWorkSpaceReportsCrudresponse) => {
        if (response.info && response.info.length) {
          return response.info
        }
        return []
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { id, teamId, searchByString, reportType, status, onlyUser } =
          queryArgs

        return `${endpointName}:${id}:${teamId}:${searchByString}:${reportType}:${status}:${onlyUser}`
      },

      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
      merge: (currentCache, newItems, { arg }) => {
        if (
          currentCache &&
          arg.searchByString !== undefined &&
          arg.searchByString !== ''
        ) {
          return newItems
        }

        if (!newItems.length) {
          return currentCache
        }

        return [...currentCache, ...newItems]
      },
      keepUnusedDataFor: 0,
      providesTags: [RTKTags.workspaceDetails, RTKTags.monitoringCenter],
    }),

    getAllWorkSpaceReports: builder.query<
      ReportInfo[],
      getAllWorkSpaceReportsRequest
    >({
      query: (request) => {
        const workspaceSegment = request.workspaceId
          ? `/${request.workspaceId}`
          : ''
        const teamSegment = request.teamId ? `/${request.teamId}` : ''

        return {
          url: `${Env.REACT_APP_BASE_API_URL}/api/v2/enterprises/reports${workspaceSegment}${teamSegment}`,
          method: 'GET',
          params: {
            page: request.page,
            pageSize: request.pageSize,
            reportType: request.reportType,
            status: request.status,
            onlyUser: request.onlyUser,
            searchByString: request.searchByString,
          },
        }
      },
      transformResponse: (response: getWorkSpaceReportsCrudresponse) => {
        if (response.info && response.info.length) {
          return response.info
        }
        return []
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const {
          workspaceId = '_',
          teamId = '_',
          searchByString,
          reportType,
          status,
          onlyUser,
        } = queryArgs

        return `${endpointName}:${workspaceId}:${teamId}:${searchByString}:${reportType}:${status}:${onlyUser}`
      },

      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
      merge: (currentCache, newItems, { arg }) => {
        if (
          currentCache &&
          arg.searchByString !== undefined &&
          arg.searchByString !== ''
        ) {
          return newItems
        }

        if (!newItems.length) {
          return currentCache
        }

        return [...currentCache, ...newItems]
      },
      keepUnusedDataFor: 0,
      providesTags: [RTKTags.workspaceDetails, RTKTags.monitoringCenter],
    }),

    getWorkSpaces: builder.query<WorkSpaceResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/enterprises/workspaces`,
        method: 'GET',
      }),
      providesTags: [RTKTags.getWorkSpaces],
    }),

    assignCreditsToWorkspace: builder.mutation<
      void,
      AssignCreditsToWorkspaceRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/workspaces/${request.workspaceId}/credits`,
        method: 'PUT',
        body: request.values,
      }),
      invalidatesTags: [
        RTKTags.workspaceDetails,
        RTKTags.getWorkSpaces,
        RTKTags.userInfo,
        RTKTags.workspaceCredits,
      ],
    }),

    updateTeamData: builder.mutation<void, UpdateTeamData>({
      query: (request: UpdateTeamData) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/workspaces/${request.id}/teams/${request.teamId}`,
        method: 'PUT',
        body: {
          name: request.name,
        },
      }),
      invalidatesTags: [RTKTags.workspaceDetails],
    }),

    removeMemberFromTeam: builder.mutation<void, RemoveMemberFromTeamRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/enterprises/workspaces/teams/members/${request.memberId}`,
        method: 'DELETE',
        body: request,
      }),
      invalidatesTags: [RTKTags.workspaceDetails, RTKTags.getWorkSpaces],
    }),

    updateWorkSpaceData: builder.mutation<void, UpdateWorkspaceData>({
      query: (request: UpdateTeamData) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/workspaces/${request.id}`,
        method: 'PUT',
        body: {
          name: request.name,
        },
      }),
      invalidatesTags: [RTKTags.workspaceDetails],
    }),

    inviteMemberToATeam: builder.mutation<void, MemberToAssign>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/enterprises/workspaces/members`,
        method: 'POST',
        body: {
          members: [request],
        },
      }),
      invalidatesTags: [RTKTags.workspaceDetails, RTKTags.getWorkSpaces],
    }),

    workspaceCredits: builder.query<
      WorkspaceCreditsResponse,
      WorkspaceDetailsRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/workspaces/${request.workspaceId}/credits`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return response.info
      },
      providesTags: [RTKTags.workspaceCredits],
    }),
  }),
})

export const {
  useWorkspaceDetailsQuery,
  useGetWorkSpacesQuery,
  useAssignCreditsToWorkspaceMutation,
  useUpdateTeamDataMutation,
  useRemoveMemberFromTeamMutation,
  useInviteMemberToATeamMutation,
  useGetWorkSpaceReportsQuery,
  useUpdateWorkSpaceDataMutation,
  useWorkspaceCreditsQuery,
  useGetAllWorkSpaceReportsQuery,
} = workspaceApi
