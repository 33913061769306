import React from 'react'

import Ellipse from '@/assets/Ellipse.svg?react'

interface IPostFilterChipProps {
  text: string
  isSelected?: boolean
  onClick: () => void
}

export const PostFilterChip = ({
  text,
  isSelected = false,
  onClick,
}: IPostFilterChipProps) => {
  return (
    <div
      className={`h-[35px] px-4 py-2 rounded-[200px] items-center justify-center cursor-pointer flex gap-2 ${
        isSelected ? 'border border-neutralColor30' : ''
      } text-neutralColor bg-baseColor`}
      onClick={onClick}
    >
      {isSelected && <Ellipse />}
      <p className="text-sm font-medium tracking-tight">{text}</p>
    </div>
  )
}
