import { ArrowRight } from 'iconsax-react'
import React from 'react'

import FlagIcon from '@/assets/DigitalReport/FlagIcon.svg?react'
import { Button } from '@/components/ui/button'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface TotalPostsCardTypes {
  sentiment: string
  totalPosts?: number
  onClick: () => void
  isFlagged?: boolean
}

export const TotalPostsCard = ({
  sentiment,
  totalPosts,
  onClick,
  isFlagged = false,
}: TotalPostsCardTypes) => {
  const isMobile = useMobileMediaQuery()
  return (
    <Button
      className={`h-[94px] p-4 rounded-2xl flex gap-2 flex-col border bg-baseColor shadow justify-between ${
        isMobile ? 'w-[100%]' : 'w-[48%]'
      }`}
      disabled={totalPosts === 0}
      onClick={onClick}
    >
      <div className="w-full flex items-center gap-3">
        <p className="flex text-neutralColor80 text-base font-medium">
          {sentiment}
        </p>
        {isFlagged && (
          <FlagIcon
            color={
              totalPosts !== 0
                ? weCheckPalette.dynamicRed
                : weCheckPalette.disabled
            }
          />
        )}
      </div>

      <div className="flex justify-between w-full">
        <p className="text-neutralColor text-2xl font-medium">{totalPosts}</p>
        <ArrowRight className="w-6 h-6" color={weCheckPalette.neutralColor} />
      </div>
    </Button>
  )
}
