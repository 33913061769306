import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import useTabletMediaQuery from '@/hooks/useTabletMediaQuery'
import {
  EReportType,
  ESocialCheckStatus,
  EWebhookEvent,
} from '@/store/api/Enterprise/Enterprise.types'
import {
  useGenerateAnonymousPdfMutation,
  useMakeBackgroundCheckOpenedMutation,
  useReDowloadPdfMutation,
} from '@/store/api/Person/Person.query'
import type {
  ReportCheckData,
  SubjectInputs,
} from '@/store/api/workspace/workspace.types'
import { formatDate } from '@/utils/date'
import { textEllipsis } from '@/utils/strings'

import { Chip } from '../../Home/components/Chip/Chip'

import { BurgerMenu } from './BurgerMenu'
import { CancelReportDialog } from './CancelReportDialog'

interface SocialAnalysisItemTypes {
  data: ReportCheckData
}

export const SocialAnalysisItem = ({ data }: SocialAnalysisItemTypes) => {
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()
  const isSmallMobile = useSmallMobileMediaQuery()
  const isTablet = useTabletMediaQuery()
  const dontIsDesktop = isTablet || isSmallMobile || isMobile
  const [reDowloadPDF, { isLoading }] = useReDowloadPdfMutation()
  const [generateAnonymousPdf, { isLoading: isLoadingAnonymousPdf }] =
    useGenerateAnonymousPdfMutation()
  const [makeBackgroundCheckOpened] = useMakeBackgroundCheckOpenedMutation()

  const extractInputInitials = (input: SubjectInputs): string => {
    const fullname = input.name + input.lastName
    const words = fullname.split(' ')
    return (
      words?.[0]?.slice(0, 1) +
      ' ' +
      words?.[words?.length - 1]?.slice(0, 1)
    )?.toUpperCase()
  }

  const initials = useMemo(() => {
    if (!data.inputs.name) {
      return ''
    }
    const fullname = data.inputs.name
    const initials = fullname.split(' ')
    const firstInitial = initials[0] ? initials[0][0].toUpperCase() : ''
    const secondInitial = initials[2] ? initials[2][0].toUpperCase() : ''
    return `${firstInitial} ${secondInitial}`
  }, [data.inputs.name])

  const onPressDowload = async () => {
    if (data.pdf) {
      window.open(data.pdf, '_blank')
    } else if (data.status === 'PendingDownloadPdf') {
      try {
        const res = await reDowloadPDF({ id: data.id }).unwrap()
        if (res.info.url) {
          window.open(res.info.url, '_blank')
        }
      } catch (e) {
        catchWithSentry(e)
        console.error(e)
      }
    }
    try {
      await makeBackgroundCheckOpened({ id: data.id }).unwrap()
    } catch (e) {
      catchWithSentry(e)
      console.error(e)
    }
  }

  const onPressDowloadPrivatePdf = async () => {
    if (data.anonymousPdf) {
      window.open(data.anonymousPdf, '_blank')
    } else {
      try {
        const res = await generateAnonymousPdf({ id: data.subjectId }).unwrap()
        if (res.info.pdf) {
          window.open(res.info.pdf, '_blank')
        }
      } catch (e) {
        catchWithSentry(e)
        console.error(e)
      }
    }
    try {
      await makeBackgroundCheckOpened({ id: data.id }).unwrap()
    } catch (e) {
      catchWithSentry(e)
      console.error(e)
    }
  }

  const isPending =
    data.status !== ESocialCheckStatus.Complete &&
    data.status !== ESocialCheckStatus.NotReleased

  const isConcent =
    data.status === ESocialCheckStatus.NotReleased &&
    data.complianceStatus !== EWebhookEvent.COMPLETED

  const isCancelled = data.status === ESocialCheckStatus.Cancelled

  const getReportName = (reportType: EReportType) => {
    switch (reportType) {
      case EReportType.AdverseMedia:
        return t('dashboard.reportsScreen.adverseMedia')
      case EReportType.Ai:
        return t('dashboard.reportsScreen.aIReport')
      case EReportType.AiAnalyst:
        return t('dashboard.reportsScreen.aIAnalystReport')
      case EReportType.Assited:
        return t('dashboard.reportsScreen.weCheckAssisted')
    }
  }

  return (
    <div className="bg-baseColor rounded-2xl flex flex-row justify-normal p-3 h-[65px] w-full max-lg:h-[120px] max-lg:flex-col max-lg:gap-4 lg:items-center">
      <div className="flex w-1/2 max-lg:justify-between max-lg:w-full ">
        <div className="flex items-center gap-3">
          <Avatar>
            <AvatarImage />
            <AvatarFallback className="bg-baseColor text-neutralColor">
              {extractInputInitials(data.inputs)}
            </AvatarFallback>
          </Avatar>
          <div className="flex flex-col">
            <div className="flex items-center gap-2 max-md:flex-col max-sm:w-min">
              <p className="text-neutralColor text-base font-medium text-nowrap">
                {textEllipsis(data.inputs.name, isMobile ? 14 : 50)}
              </p>

              {isPending && !isCancelled && !dontIsDesktop && (
                <Chip
                  blue
                  text={t('dashboard.reportsScreen.inProgress')}
                  isTag
                />
              )}
              {!data.pdfViewed &&
                !isPending &&
                !isConcent &&
                !isCancelled &&
                !dontIsDesktop && (
                  <Chip green text={t('dashboard.reportsScreen.new')} isTag />
                )}
              {isConcent &&
                !dontIsDesktop &&
                !isCancelled &&
                data.complianceStatus !== EWebhookEvent.COMPLETED && (
                  <Chip
                    yellow
                    text={t('dashboard.reportsScreen.pendingConsent')}
                    isTag
                  />
                )}
              {isCancelled && !dontIsDesktop && (
                <Chip red text={t('dashboard.reportsScreen.cancelled')} isTag />
              )}
            </div>
            <p className="text-neutralColor60 text-sm font-medium tracking-tight">
              {data.inputs.email &&
                textEllipsis(data.inputs.email, isMobile ? 13 : 50)}
            </p>
          </div>
        </div>
        {isPending && !isCancelled && dontIsDesktop && (
          <Chip blue text={t('dashboard.reportsScreen.pending')} isTag />
        )}
        {!data.pdfViewed &&
          !isPending &&
          !isConcent &&
          !isCancelled &&
          dontIsDesktop && (
            <Chip green text={t('dashboard.reportsScreen.new')} isTag />
          )}
        {isConcent &&
          dontIsDesktop &&
          !isCancelled &&
          data.complianceStatus !== EWebhookEvent.COMPLETED && (
            <Chip
              yellow
              text={t('dashboard.reportsScreen.concentPending')}
              isTag
            />
          )}
        {isCancelled && dontIsDesktop && (
          <Chip red text={t('dashboard.reportsScreen.cancelled')} isTag />
        )}
      </div>

      <div className="flex lg:w-1/3 ">
        <div className="flex flex-col w-full gap-1">
          <p className="text-neutralColor text-xs font-medium text-nowrap ">
            {getReportName(data.reportType)}
          </p>
          <p className="text-neutralColor60 text-xs font-medium ">
            {formatDate(data.reportDate, 'MM/dd/yyyy')}
          </p>
        </div>

        {!dontIsDesktop && (
          <div className="mr-2 flex flex-col gap-1 w-20">
            <p className="text-neutralColor text-xs font-medium text-nowrap">
              {t('dashboard.reportsScreen.requestBy')}
            </p>
            <p className="text-neutralColor60 text-xs font-medium  text-nowrap">
              {data.requester.firstname} {data.requester.lastname}
            </p>
          </div>
        )}
        {dontIsDesktop && (
          <>
            <div className="flex justify-end gap-2">
              {!isConcent && (data.pdf || data.complianceSigned) && (
                <BurgerMenu
                  data={data}
                  isLoading={isLoading}
                  isAnonymousPdfLoading={isLoadingAnonymousPdf}
                  onPressDownload={onPressDowload}
                  onPressDowloadPrivatePdf={onPressDowloadPrivatePdf}
                  name={data.inputs.name}
                  initials={initials}
                />
              )}
              {isConcent && !isPending && (
                <CancelReportDialog
                  name={data.inputs.name}
                  address={data.inputs.email}
                  initials={initials}
                  id={data.id}
                />
              )}
            </div>
          </>
        )}
      </div>

      {!dontIsDesktop && (
        <>
          <div className="flex lg:w-1/3 md:justify-end gap-4">
            {!isConcent && (data.pdf || data.complianceSigned) && (
              <BurgerMenu
                data={data}
                isLoading={isLoading}
                isAnonymousPdfLoading={isLoadingAnonymousPdf}
                onPressDownload={onPressDowload}
                onPressDowloadPrivatePdf={onPressDowloadPrivatePdf}
                name={data.inputs.name}
                initials={initials}
              />
            )}
            {!isPending && isConcent && !dontIsDesktop && (
              <CancelReportDialog
                name={data.inputs.name}
                address={data.inputs.email}
                initials={initials}
                id={data.id}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}
