import { zodResolver } from '@hookform/resolvers/zod'
import { CloseSquare } from 'iconsax-react'
import { MultiSelect } from 'primereact/multiselect'
import React, { useState } from 'react'
import { Form, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { FormControl, FormField, FormItem } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useAppSelector } from '@/hooks/redux'
import { selectCreateWorkSpaceSecondStep } from '@/store/api/CreateWorkSpace/CreateWorkSpace.selector'
import { EEnterpriseRole } from '@/store/api/Enterprise/Enterprise.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import type { workSpaceThirdStepSchemaType } from '@/utils/schemas'
import { workSpaceThirdStepSchema } from '@/utils/schemas'

interface WorkSpaceCreationThirdStepInputsProps {
  control: any // Asegúrate de que el tipo sea correcto
  name: string
  value: string
  showDeleteIcon?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onRemove: () => void
  onRoleChange: (role: string) => void
  onTeamsChange: (teams: string[]) => void
}

export const WorkSpaceCreationThirdStepInputs = ({
  control,
  name,
  value,
  onChange,
  onRemove,
  showDeleteIcon,
  onRoleChange,
  onTeamsChange,
}: WorkSpaceCreationThirdStepInputsProps) => {
  const createWorkSpaceSecondStepData = useAppSelector(
    selectCreateWorkSpaceSecondStep,
  )
  const [selectedTeams, setSelectedTeams] = useState<string[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRole, setSelectedRole] = React.useState<string>('')
  const { t } = useTranslation()
  const form = useForm<workSpaceThirdStepSchemaType>({
    resolver: zodResolver(workSpaceThirdStepSchema),
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  })

  //console.log(createWorkSpaceSecondStepData.createTeamArray)
  const handleRoleSelect = (role: string) => {
    setSelectedRole(role)
    onRoleChange(role) // Llama a la función de callback
  }

  const handleTeamsSelect = (teams: string[]) => {
    setSelectedTeams(teams)
    onTeamsChange(teams) // Llama a la función de callback
  }

  return (
    <div className="">
      <FormProvider {...form}>
        <Form {...form}>
          <form>
            <FormField
              control={control}
              name={name}
              render={({ field }) => (
                <FormItem className="">
                  <div className="flex flex-col lg:grid lg:grid-cols-3 gap-4">
                    <FormControl>
                      <Input
                        {...field}
                        {...{
                          onChange: (e) => {
                            form.setValue('email', e.target.value)
                            onChange(e)
                          },
                          value: value,
                        }}
                        className="bg-baseColorHighlight w-full border-none"
                        placeholder={t(
                          'dashboard.company.createTeam.exampleGmail',
                        )}
                      />
                    </FormControl>
                    <MultiSelect
                      value={selectedTeams}
                      onChange={(e) => {
                        handleTeamsSelect(e.value) // Llamar a handleTeamsSelect aquí
                      }}
                      options={createWorkSpaceSecondStepData.createTeamArray}
                      placeholder={t(
                        'dashboard.company.createTeam.selectTeams',
                      )}
                      maxSelectedLabels={3}
                      showSelectAll
                      selectAllLabel="Select All"
                      className="w-full h-[45px] flex items-center p-3 bg-baseColorHighlight rounded-lg text-sm"
                      panelClassName="bg-baseColorHighlight text-neutralColor60 p-3 mt-1 rounded-lg"
                      itemTemplate={(option) => (
                        <div className="flex items-center">
                          <Checkbox
                            checked={selectedTeams.includes(option)}
                            onChange={() => {
                              const newSelectedCities = selectedTeams.includes(
                                option,
                              )
                                ? selectedTeams.filter(
                                    (city) => city !== option,
                                  )
                                : [...selectedTeams, option]
                              setSelectedTeams(newSelectedCities)
                            }}
                            className="mr-2"
                          />
                          <p className="ml-3">{option}</p>
                        </div>
                      )}
                    />

                    <div className="flex flex-row">
                      <Select onValueChange={handleRoleSelect}>
                        <SelectTrigger className="h-[45px]">
                          <SelectValue
                            placeholder={t('dashboard.company.createTeam.role')}
                          />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value={EEnterpriseRole.SUPERVISOR}>
                            {t('dashboard.company.createTeam.supervisor')}
                          </SelectItem>
                          <SelectItem value={EEnterpriseRole.WORKSPACE_ADMIN}>
                            {t('dashboard.company.createTeam.workspaceAdmin')}
                          </SelectItem>
                          <SelectItem value={EEnterpriseRole.TEAM_MANAGER}>
                            {t('dashboard.company.createTeam.teamManager')}
                          </SelectItem>
                          <SelectItem value={EEnterpriseRole.TEAM_ANALYST}>
                            {t('dashboard.company.createTeam.teamAnalyst')}
                          </SelectItem>
                          <SelectItem value={EEnterpriseRole.READER}>
                            {t('dashboard.company.createTeam.reader')}
                          </SelectItem>
                        </SelectContent>
                      </Select>

                      {showDeleteIcon && (
                        <div className="">
                          <Button
                            className="flex mt-1"
                            variant="link"
                            onClick={onRemove}
                          >
                            <CloseSquare
                              width={18}
                              height={17}
                              variant="Bold"
                              color={weCheckPalette.neutralColor}
                            />
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </FormItem>
              )}
            />
          </form>
        </Form>
      </FormProvider>
    </div>
  )
}
