import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import imageUnavailableEnglish from '@/assets/DigitalReport/ImageUnavailable/english.png'
import imageUnavailableSpanish from '@/assets/DigitalReport/ImageUnavailable/spanish.png'
import FacebookIcon from '@/assets/socialMedia/Facebook.svg?react'
import InstagramIcon from '@/assets/socialMedia/Instagram.svg?react'
import LinkedinIcon from '@/assets/socialMedia/LinkedIn.svg?react'
import PinterestIcon from '@/assets/socialMedia/Pinterest.svg?react'
import RedditIcon from '@/assets/socialMedia/Reddit.svg?react'
import TiktokIcon from '@/assets/socialMedia/TikTok.svg?react'
import WebSearch from '@/assets/socialMedia/Web.svg?react'
import TwitterIcon from '@/assets/socialMedia/X.svg?react'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import { Chip } from '@/pages/Home/components/Chip/Chip'
import type { SocialMediaPost } from '@/store/api/Person/Person.types'
import { formatDate } from '@/utils/date'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import Env from '../../../env/env'

interface PostCardTypes {
  post?: SocialMediaPost
  isCoreBehavior: boolean
}

type Platform =
  | 'Pinterest'
  | 'Tiktok'
  | 'Reddit'
  | 'Twitter'
  | 'Instagram'
  | 'Facebook'
  | 'LinkedIn'
  | 'WebSearch'

export const PostCard = ({ post, isCoreBehavior }: PostCardTypes) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const isSmallMobile = useSmallMobileMediaQuery()
  const [image, setImage] = useState<string | undefined>(undefined)
  const [video, setVideo] = useState<string | undefined>(undefined)
  const platformIcons: Record<Platform, JSX.Element> = {
    Pinterest: <PinterestIcon color={weCheckPalette.baseColor} />,
    Tiktok: <TiktokIcon color={weCheckPalette.baseColor} />,
    Reddit: <RedditIcon color={weCheckPalette.baseColor} />,
    Twitter: <TwitterIcon color={weCheckPalette.baseColor} />,
    Instagram: <InstagramIcon color={weCheckPalette.baseColor} />,
    Facebook: <FacebookIcon color={weCheckPalette.baseColor} />,
    LinkedIn: <LinkedinIcon color={weCheckPalette.baseColor} />,
    WebSearch: <WebSearch color={weCheckPalette.baseColor} />,
  }

  const isValidPlatform = (platform: string): platform is Platform => {
    return [
      'Pinterest',
      'Tiktok',
      'Reddit',
      'Twitter',
      'Instagram',
      'Facebook',
      'LinkedIn',
      'WebSearch',
    ].includes(platform)
  }

  const imageUrlCrossOrigin = post?.mediaUrl
    ? `${
        Env.REACT_APP_BASE_API_URL
      }/api/v1/internal/subject/image?url=${encodeURIComponent(post?.mediaUrl)}`
    : ''

  const finalImageUrl: string | undefined =
    post?.platform === 'Instagram' ? imageUrlCrossOrigin : post?.mediaUrl

  const imageUnavailable =
    language === 'es' ? imageUnavailableSpanish : imageUnavailableEnglish

  const checkImageStatus = async () => {
    try {
      if (!finalImageUrl) return
      const response = await fetch(finalImageUrl, {
        method: 'HEAD',
      })
      if (response.status === 200) {
        setImage(finalImageUrl)
      } else if (response.status === 401) {
        console.log('imageUnavailable', response.status)
        setImage(imageUnavailable)
      } else {
        console.log('otro error', response)
        setImage(undefined)
      }
    } catch (error) {
      console.log('otro error', error)
      setImage(undefined)
    }
  }

  useEffect(() => {
    if (finalImageUrl) {
      checkImageStatus()
    }
  }, [])

  return (
    <div className={`rounded-xl bg-baseColor flex flex-col`}>
      {(image || video) && (
        <div
          className={`flex lg:mb-0 mb-6 justify-center items-center h-[284px]`}
        >
          {image && (
            <img
              src={image}
              alt={`${post?.platform} post img`}
              className="object-cover w-full rounded-t-xl h-full"
              {...(post?.platform === 'Instagram' && {
                crossOrigin: 'anonymous',
              })}
            />
          )}
          {video && (
            <video
              src={video}
              className="object-cover w-full rounded-t-xl h-full"
              controls
              onError={() => {
                setVideo(undefined)
              }}
            >
              <track kind="captions" src={video} label="Captions" />
            </video>
          )}
        </div>
      )}

      <div className="flex flex-col gap-4 p-4 min-h-[200px]">
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center gap-3">
            <div className="flex justify-center items-center bg-neutralColor rounded-full h-10 w-10 ">
              {post?.platform &&
                isValidPlatform(post.platform) &&
                platformIcons[post.platform]}
            </div>
            <div className={`${isSmallMobile ? 'max-w-28' : ''}`}>
              <p className="text-neutralColor text-base font-medium">
                {post?.type}
              </p>
              <p className="text-sm text-neutralColor80 font-normal">
                {post?.date && formatDate(post.date, 'hh:mm a - MMM dd, yyyy')}
              </p>
            </div>
          </div>
          <a
            href={post?.link}
            className="text-xs font-medium text-dynamicBlue"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('dashboard.postList.viewPost')}
          </a>
        </div>

        <p className={`text-neutralColor text-base font-normal line-clamp-3`}>
          {post?.content}
        </p>

        <div
          className={`flex flex-row flex-wrap gap-2 ${
            post?.flagReason ? 'border-b-[0.25px]' : 'mt-12'
          } border-neutralColor80 pb-4 w-full justify-between`}
        >
          <div className="flex rounded-[200px] h-6 px-3 py-1 bg-baseColorHighlight shadow-sm shadow-neutralColor30">
            <p className="text-neutralColor80 text-xs font-medium">
              {post?.sentimentFlag}
            </p>
          </div>
          <div className="flex items-center gap-2">
            {post?.flags.map((flag, index) => (
              <Chip
                key={index}
                red={!isCoreBehavior}
                blue={isCoreBehavior}
                text={flag}
                isTag
              />
            ))}
          </div>
        </div>
        {post?.flagReason && (
          <div className="flex">
            <p className="text-neutralColor80 text-sm font-normal">
              {post?.flagReason ?? post?.sentimentReason}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
