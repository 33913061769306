import React from 'react'
import { useTranslation } from 'react-i18next'

import emptyAvatar from '@/assets/emptyState/emptyAvatar.svg'
import useMediumMobileMediaQuery from '@/hooks/useMediumMobileMediaQuery'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import { EReportType } from '@/store/api/Person/Person.types'

import { SocialMetrics } from './SocialMetrics'

export const Header = ({
  children,
  fullName,
  profileImage,
  followers,
  following,
  posts,
  isPrivate,
  hiddenFollowSection,
  platform,
  reportType,
}: {
  children?: React.JSX.Element
  fullName: string | undefined
  profileImage: string | undefined
  followers: number | undefined
  following: number | undefined
  posts: number | undefined
  isPrivate?: boolean
  hiddenFollowSection?: boolean
  platform?: string
  reportType?: EReportType
}) => {
  const isMobile = useMediumMobileMediaQuery()
  const isBigMobile = useMobileMediaQuery()
  const { t } = useTranslation()
  const isAdverseMedia = reportType === EReportType.AdverseMedia
  return (
    <div
      className={`flex justify-between flex-col items-start w-full ${
        isMobile && 'gap-2 px-3 pt-3'
      }`}
    >
      <div
        className={`flex w-full ${
          isMobile ? 'justify-between' : 'sm:flex-row'
        }`}
      >
        <div
          className={`flex flex-row  w-full ${
            isBigMobile ? 'justify-center flex-col gap-3' : 'gap-6'
          } ${hiddenFollowSection ? 'items-center' : ''}`}
        >
          <div
            className={`rounded-full items-center ${
              isMobile
                ? 'min-w-[40px] min-h-[40px]'
                : 'min-w-[83px] min-h-[83px]'
            }`}
          >
            <img
              src={profileImage || emptyAvatar}
              alt={fullName}
              className={`rounded-full object-cover ${
                isMobile ? 'w-[40px] h-[40px]' : 'w-[83px] h-[83px]'
              }`}
            />
          </div>
          <div
            className={`flex flex-col gap-6 w-full ${
              isAdverseMedia && 'justify-center'
            }`}
          >
            <div className={`flex flex-col gap-1 ${isMobile && 'items-start'}`}>
              <div
                className={`flex gap-1 items-center ${
                  isMobile ? 'flex-col items-start' : 'lg:gap-2'
                }`}
              >
                <p
                  className={`text-neutralColor font-medium ${
                    isMobile ? 'text-base font-normal' : 'text-2xl'
                  }`}
                >
                  {fullName}
                </p>
                <p className="text-textAccent text-xl font-medium">
                  {isPrivate && t('dashboard.header.private')}
                </p>
              </div>
            </div>
            {!isMobile && !hiddenFollowSection && !isAdverseMedia && (
              <SocialMetrics
                followers={followers || 0}
                following={following || 0}
                posts={posts || 0}
                platform={platform}
              />
            )}
          </div>
        </div>
        {children && children}
      </div>

      {isMobile && !hiddenFollowSection && !isAdverseMedia && (
        <SocialMetrics
          followers={followers || 0}
          following={following || 0}
          posts={posts || 0}
          platform={platform}
        />
      )}
    </div>
  )
}
