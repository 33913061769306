import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationPrevious,
  PaginationLink,
  PaginationNext,
} from '@/components/ui/pagination'

interface PaginationProps {
  currentPage: number
  totalPages: number
  totalItems: number
  onPageChange: (page: number) => void
}

export const SearchResultsPagination = ({
  currentPage,
  totalPages,
  totalItems,
  onPageChange,
}: PaginationProps) => {
  const { t } = useTranslation()
  const getPageNumbers = (): (number | string)[] => {
    const pages: (number | string)[] = []
    const maxVisiblePages = 5

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i)
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, 5)
      } else if (currentPage + 2 >= totalPages) {
        pages.push(
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        )
      } else {
        pages.push(currentPage - 1, currentPage, currentPage + 1)
      }
    }

    return pages
  }

  return (
    <Pagination className="flex flex-col gap-3 w-full items-center">
      <div className="flex">
        <p className="text-neutralColor60 text-sm font-medium tracking-tight text-center w-min text-nowrap">
          {t('dashboard.newSearch.searchResults.showing')} &nbsp;
          <strong className="text-neutralColor80 text-sm font-bold tracking-tight">
            {(currentPage - 1) * 10 + 1}
          </strong>
          &nbsp;
          {t('dashboard.newSearch.searchResults.to')}
          &nbsp;
          <strong className="text-neutralColor80 text-sm font-bold tracking-tight">
            {Math.min(currentPage * 10, totalItems)}
          </strong>
          &nbsp;
          {t('dashboard.newSearch.searchResults.of')}
          &nbsp;
          <strong className="text-neutralColor80 text-sm font-bold tracking-tight">
            {totalItems}
          </strong>
          &nbsp;
          {t('dashboard.newSearch.searchResults.entries')}
        </p>
      </div>

      <PaginationContent>
        <PaginationItem className="border-0">
          <PaginationPrevious
            href="#"
            onClick={(e) => {
              e.preventDefault()
              if (currentPage > 1) onPageChange(currentPage - 1)
            }}
            className={
              currentPage === 1 ? 'pointer-events-none opacity-50' : ''
            }
          />
        </PaginationItem>

        {getPageNumbers().map((page) => {
          return (
            <PaginationItem key={`page-${page}`}>
              <PaginationLink
                className="h-7 px-3 py-1 bg-baseColorHighlight border-neutralColor30 rounded-none"
                href="#"
                isActive={page === currentPage}
                onClick={(e) => {
                  e.preventDefault()
                  onPageChange(Number(page))
                }}
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          )
        })}

        <PaginationItem>
          <PaginationNext
            href="#"
            onClick={(e) => {
              e.preventDefault()
              if (currentPage < totalPages) onPageChange(currentPage + 1)
            }}
            className={
              currentPage === totalPages ? 'pointer-events-none opacity-50' : ''
            }
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  )
}
