import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'

import { EmptyState } from '@/components/EmptyState/EmptyState'
import { LottieSpinner } from '@/components/LottieSpinner/LottieSpinner'
import { useSmallDesktopQuery } from '@/hooks/useSmallDesktopQuery'
import { PostCard } from '@/pages/DigitalReport/Components/PostCard'
import { Chip } from '@/pages/Home/components/Chip/Chip'
import { selectDigitalReportInfo } from '@/store/api/DigitalReport/digitalReport.selector'
import {
  setExternalSentimentFilter,
  setExternalPlatformFilter,
  setExternalFlagFilter,
} from '@/store/api/DigitalReport/digitalReport.slice'
import { useLazySocialMediaPostQuery } from '@/store/api/Person/Person.query'
import type {
  FilterByType,
  PlatformType,
  Risk,
  SocialMediaPost,
} from '@/store/api/Person/Person.types'
import { EReportType } from '@/store/api/Person/Person.types'

import { PostFilterChip } from '../Components/PostsFilterChip'
import { TopicsPopover } from '../Components/TopicsPopover'

interface PostsTypes {
  reportId: string
  risks: Risk[]
  reportType: EReportType
}

export const Posts = ({ reportId, risks, reportType }: PostsTypes) => {
  const { t } = useTranslation()
  const isSmallDesktop = useSmallDesktopQuery()
  const dispatch = useDispatch()

  const digitalReportInfo = useSelector(selectDigitalReportInfo)

  const [currentPage, setCurrentPage] = useState(1)

  const [
    makeRequest,
    {
      data: postData,
      isLoading: isLoadingPostFirstTime,
      isFetching: isFechingPost,
    },
  ] = useLazySocialMediaPostQuery()

  const makeBeRequest = useCallback(
    (
      page: number,
      currentFilterBy?: FilterByType,
      currentPlatform?: string,
      flag?: string,
    ) => {
      makeRequest({
        id: reportId,
        page: page,
        pageSize: 10,
        plataform: currentPlatform as PlatformType,
        filterBy: currentFilterBy,
        flag: flag,
      })
    },
    [reportId, makeRequest],
  )

  useEffect(() => {
    setCurrentPage(1)
    if (
      digitalReportInfo.externalSentimentFilter === 'all' &&
      digitalReportInfo.externalPlatformFilter === 'all' &&
      digitalReportInfo.externalFlagFilter === 'all'
    ) {
      dispatch(setExternalSentimentFilter('all'))
      dispatch(setExternalPlatformFilter('all'))
      dispatch(setExternalFlagFilter('all'))
    }
    const currentFilterBy: FilterByType | undefined =
      digitalReportInfo.externalSentimentFilter === 'all'
        ? undefined
        : (digitalReportInfo.externalSentimentFilter as FilterByType)

    const currentPlatform =
      digitalReportInfo.externalPlatformFilter === 'all'
        ? undefined
        : digitalReportInfo.externalPlatformFilter

    const flagValue =
      digitalReportInfo.externalSentimentFilter === 'Flagged' &&
      digitalReportInfo.externalFlagFilter !== 'all'
        ? digitalReportInfo.externalFlagFilter
        : undefined

    makeBeRequest(currentPage, currentFilterBy, currentPlatform, flagValue)
  }, [
    digitalReportInfo.externalSentimentFilter,
    digitalReportInfo.externalPlatformFilter,
    digitalReportInfo.externalFlagFilter,
  ])

  const hasMoreData = useMemo(
    () => (postData?.posts.offset && postData?.posts.offset > 0) as boolean,
    [postData],
  )

  const fetchMoreOnPagination = () => {
    const newPage = currentPage + 1
    setCurrentPage(newPage)
    const currentFilterBy: FilterByType | undefined =
      digitalReportInfo.externalSentimentFilter === 'all'
        ? undefined
        : (digitalReportInfo.externalSentimentFilter as FilterByType)

    const currentPlatform =
      digitalReportInfo.externalPlatformFilter === 'all'
        ? undefined
        : digitalReportInfo.externalPlatformFilter

    const flagValue =
      digitalReportInfo.externalFlagFilter !== 'all'
        ? digitalReportInfo.externalFlagFilter
        : undefined
    makeBeRequest(newPage, currentFilterBy, currentPlatform, flagValue)
  }

  const isLoading =
    (isFechingPost && currentPage === 1) || isLoadingPostFirstTime

  const focusedTopics =
    risks !== null ? risks.filter((risk) => risk.isFocusedTopic) : []
  const coreBehaviors =
    risks !== null
      ? risks.filter((risk) => !risk.isFocusedTopic && risk.count > 0)
      : []

  const isCoreBehavior = coreBehaviors.some(
    (behavior) => behavior.name === digitalReportInfo.externalFlagFilter,
  )

  return (
    <>
      <div className="flex gap-2 items-center my-6 max-md:flex-wrap">
        {reportType === EReportType.AdverseMedia ? (
          <>
            <PostFilterChip
              text={t('dashboard.postList.all')}
              isSelected={digitalReportInfo.externalSentimentFilter === 'all'}
              onClick={() => {
                dispatch(setExternalSentimentFilter('all'))
              }}
            />
            <PostFilterChip
              text={t('dashboard.postList.adverseMedia')}
              isSelected={
                digitalReportInfo.externalSentimentFilter === 'Negative'
              }
              onClick={() => {
                dispatch(setExternalSentimentFilter('Negative'))
              }}
            />
            <PostFilterChip
              text={t('dashboard.postList.webNews')}
              isSelected={
                digitalReportInfo.externalSentimentFilter === 'excludeNegative'
              }
              onClick={() => {
                dispatch(setExternalSentimentFilter('excludeNegative'))
              }}
            />
          </>
        ) : (
          <>
            <PostFilterChip
              text={t('dashboard.postList.all')}
              isSelected={
                digitalReportInfo.externalSentimentFilter === 'all' &&
                (digitalReportInfo.externalPlatformFilter === undefined ||
                  digitalReportInfo.externalPlatformFilter === 'all')
              }
              onClick={() => {
                dispatch(setExternalSentimentFilter('all'))
                dispatch(setExternalPlatformFilter(undefined))
              }}
            />
            {(
              ['Flagged', 'Negative', 'Positive', 'Neutral'] as FilterByType[]
            ).map((filter) => (
              <PostFilterChip
                key={filter}
                text={t(`dashboard.postList.${filter}`)}
                isSelected={
                  digitalReportInfo.externalSentimentFilter === filter
                }
                onClick={() => {
                  dispatch(setExternalSentimentFilter(filter))
                  dispatch(setExternalPlatformFilter(undefined))
                }}
              />
            ))}
            <PostFilterChip
              text={t('dashboard.postList.webNews')}
              isSelected={
                digitalReportInfo.externalPlatformFilter === 'WebSearch'
              }
              onClick={() => {
                dispatch(
                  setExternalPlatformFilter(
                    digitalReportInfo.externalPlatformFilter === 'WebSearch'
                      ? undefined
                      : 'WebSearch',
                  ),
                )
                dispatch(setExternalSentimentFilter('all'))
              }}
            />
          </>
        )}
      </div>
      <div className="bg-baseColorHighlight p-4 rounded-2xl ">
        <div className={`flex justify-between mb-4`}>
          {reportType === EReportType.AdverseMedia ? (
            <p className="text-textNormal text-xl font-medium tracking-wide">
              {digitalReportInfo.externalSentimentFilter === 'all'
                ? t('dashboard.postList.allMediaMentions')
                : digitalReportInfo.externalSentimentFilter ===
                    'excludeNegative'
                  ? t('dashboard.postList.webNews')
                  : t('dashboard.postList.adverseMedia')}
            </p>
          ) : (
            <p className="text-textNormal text-xl font-medium tracking-wide">
              {`${
                digitalReportInfo.externalPlatformFilter === 'WebSearch'
                  ? t('dashboard.postList.webNews')
                  : digitalReportInfo.externalSentimentFilter !== 'all'
                    ? digitalReportInfo.externalSentimentFilter
                    : t('dashboard.postList.all')
              } ${t('dashboard.postList.posts')}`}
            </p>
          )}

          {digitalReportInfo.externalSentimentFilter === 'Flagged' &&
            (focusedTopics.length > 0 || coreBehaviors.length > 0) && (
              <div className="flex gap-3 items-center">
                <p className="text-neutralColor text-sm font-normal">
                  {t('dashboard.postList.sortByFlags')}
                </p>
                <TopicsPopover
                  focusedTopics={focusedTopics}
                  coreBehaviors={coreBehaviors}
                  selectedTopic={digitalReportInfo.externalFlagFilter}
                  onChangeTopic={(topic) =>
                    dispatch(setExternalFlagFilter(topic))
                  }
                />
              </div>
            )}
        </div>
        <div className="mb-4 w-min">
          {digitalReportInfo.externalSentimentFilter === 'Flagged' && (
            <Chip
              red={!isCoreBehavior}
              blue={isCoreBehavior}
              text={
                digitalReportInfo.externalFlagFilter === 'all'
                  ? t('dashboard.postList.allFlags')
                  : digitalReportInfo.externalFlagFilter
              }
              isTag
            />
          )}
        </div>
        {!isLoading && postData && postData.posts.list.length > 0 && (
          <InfiniteScroll
            className="flex flex-col gap-6 custom-scrollbar"
            dataLength={postData.posts.list.length}
            next={fetchMoreOnPagination}
            hasMore={hasMoreData}
            loader={<p>Loading...</p>}
            height={isSmallDesktop ? 430 : 520}
          >
            {postData.posts.list.map((post: SocialMediaPost, id: number) => (
              <PostCard post={post} key={id} isCoreBehavior={isCoreBehavior} />
            ))}
          </InfiniteScroll>
        )}
        {isLoading && (
          <div className="flex justify-center ">
            <LottieSpinner />
          </div>
        )}
        {postData?.posts.list.length === 0 && !isLoading && (
          <div className="flex justify-center max-md:overflow-hidden">
            <EmptyState text={t('dashboard.postList.emptyState')} />
          </div>
        )}
      </div>
    </>
  )
}
