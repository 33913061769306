import { AddCircle } from 'iconsax-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import {
  EUserPermission,
  EUserRole,
} from '@/hooks/usePermissions/permissions.type'
import { usePermissions } from '@/hooks/usePermissions/usePermissions'
import { useWorkspaceDetailsQuery } from '@/store/api/workspace/workspace.query'
import type {
  Workspace,
  WorkspaceTeam,
} from '@/store/api/workspace/workspace.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { ManageOffice } from './components/ManageOffice'
import { ManageTeam } from './components/ManageTeam'
import { RenamePopUp } from './components/RenamePopUp'
import { EWorkSpaceCardType, WorkSpaceCard } from './components/WorkSpaceCard'

export const TeamSelection = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state: workspaceId } = useLocation()

  const { data: workSpace, isLoading } = useWorkspaceDetailsQuery({
    workspaceId: workspaceId as string,
  })

  const { hasPermission } = usePermissions()

  const [isOpenRename, setIsOpenRename] = useState(false)
  const [teamToRename, setTeamToRename] = useState<WorkspaceTeam | null>(null)

  const [isOpenManageTeam, setIsOpenManageTeam] = useState(false)
  const [teamToManage, setTeamToManage] = useState<WorkspaceTeam | null>(null)

  const [isOpenManageEnterprise, setIsOpenManageEnterprise] = useState(false)

  const handleRenameTeam = (team: WorkspaceTeam) => {
    setTeamToRename(team)
    setIsOpenRename(true)
  }

  const handleManageTeam = (team: WorkspaceTeam) => {
    setTeamToManage(team)
    setIsOpenManageTeam(true)
  }

  return (
    <WeScreen
      title={workSpace?.name ?? ''}
      useNextButton={false}
      useHeaderBackButton
      isLoading={isLoading}
      rightSideHeaderContent={
        <>
          <Button
            variant="link"
            className="text-primaryColor"
            onClick={() => navigate(AppRoutes.roleGuide)}
          >
            {t('dashboard.company.roleGuide')}
          </Button>

          {workSpace?.teams.length !== 0 &&
            hasPermission(
              EUserPermission.HANDLE_WORKSPACES,
              EUserRole.WORKSPACE_ADMIN,
            ) && (
              <Button onClick={() => setIsOpenManageEnterprise(true)}>
                {t('dashboard.company.manageOffice')}
              </Button>
            )}
        </>
      }
    >
      <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4">
        {workSpace?.teams.map((team) => {
          const nameOne =
            team.members[0]?.user?.firstname ?? team.members[0]?.userEmail
          const nameTwo =
            team.members[1]?.user?.firstname ?? team.members[1]?.userEmail

          return (
            <WorkSpaceCard
              title={team.name}
              type={EWorkSpaceCardType.TEAM}
              totalWorkspaceMembers={team.members.length - 1}
              nameOne={nameOne}
              nameTwo={nameTwo}
              primaryButtonText={t(
                'dashboard.company.workspaceCard.manageTeam',
              )}
              primaryButtonOnClick={() => handleManageTeam(team)}
              secondaryButtonText={t('dashboard.company.workspaceCard.rename')}
              secondaryButtonOnClick={() => handleRenameTeam(team)}
              key={team._id}
            />
          )
        })}

        {hasPermission(
          EUserPermission.HANDLE_WORKSPACES,
          EUserRole.WORKSPACE_ADMIN,
        ) && (
          <Button
            onClick={() => {
              navigate(AppRoutes.createTeamFirstStep, { state: workspaceId })
            }}
            className="bg-baseColor flex flex-col justify-center items-center p-4 gap-4 rounded-lg w-full h-[157px]"
          >
            <AddCircle size="32" color={weCheckPalette.neutralColor} />
            <p className=" text-sm text-neutralColor60">
              {t('dashboard.company.addTeam')}
            </p>
          </Button>
        )}
      </div>

      <RenamePopUp
        oldName={teamToRename?.name}
        workspaceId={workspaceId}
        teamId={teamToRename?._id}
        isRenameTeam
        isOpen={isOpenRename}
        setIsOpen={setIsOpenRename}
      />

      <ManageTeam
        isOpen={isOpenManageTeam}
        setIsOpen={setIsOpenManageTeam}
        team={teamToManage as WorkspaceTeam}
        workspace={workSpace}
      />

      <ManageOffice
        isOpen={isOpenManageEnterprise}
        setIsOpen={setIsOpenManageEnterprise}
        workspace={workSpace as Workspace}
      />
    </WeScreen>
  )
}
