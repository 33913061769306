import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { AppRoutes } from '@/constants/appRoutes'
import { Events } from '@/constants/events'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useRudderStack } from '@/hooks/useRudderStack'
import { clearTokens } from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import {
  useSaveComplianceSignedPdfMutation,
  useSendEmailWithComplianceDocumentMutation,
  useStartNewPersonSearchMutation,
} from '@/store/api/NewSearch/NewSearch.query'
import { selectNewSearch } from '@/store/api/NewSearch/NewSearch.selector'
import { clearNewSearch } from '@/store/api/NewSearch/NewSearch.slice'
import {
  selectSelectedTeam,
  selectSelectedWorkStation,
  selectUserInfo,
} from '@/store/api/Users/Users.selector'
import { base64ToFormData } from '@/utils/binary'
import { filterEmptyFields } from '@/utils/filterEmptyFields'

import { Chip } from '../Home/components/Chip/Chip'

import { GenerateReportHeader } from './Components/GenerateReportHeader'
import { InputsSection } from './Components/InputsSection'
import { useHasCurrentStepToken } from './Guards/useNewSearchSecondStepGuard'
import { ReportSetup } from './ReportSetup'

export const NewSearchLegalDisclaimer = () => {
  useHasCurrentStepToken('finalStep')
  const { t } = useTranslation()
  const { trackEvent } = useRudderStack()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const searchInfo = useSelector(selectNewSearch)
  const userID = useSelector(selectUserInfo).userId

  const selectedWorkspace = useSelector(selectSelectedWorkStation)
  const selectedTeam = useSelector(selectSelectedTeam)

  const [isChecked, setIsChecked] = useState(false)
  const [newSearch, { isLoading: isLoadingNewSearch }] =
    useStartNewPersonSearchMutation()
  const [sendEmailWithDocument, { isLoading: isLoadingSendEmailWithDocument }] =
    useSendEmailWithComplianceDocumentMutation()
  const [saveSignedPdf, { isLoading: isLoadingSaveSignedPdf }] =
    useSaveComplianceSignedPdfMutation()

  const isLoading =
    isLoadingNewSearch ||
    isLoadingSendEmailWithDocument ||
    isLoadingSaveSignedPdf

  const sortedTopics =
    searchInfo.firstStep.topics !== null &&
    searchInfo.firstStep.topics !== undefined
      ? Object.values(searchInfo.firstStep.topics).sort()
      : []

  const makeRequestV2 = useCallback(async () => {
    try {
      const requestPayload = {
        name:
          searchInfo.subjectInfo.firstName !== ''
            ? searchInfo.subjectInfo.firstName
            : searchInfo.companyInfo.name,
        lastName: searchInfo.subjectInfo.lastName,
        nickname: searchInfo.subjectInfo.alias,
        cityShort: searchInfo.subjectInfo.cityShortName
          ? searchInfo.subjectInfo.cityShortName
          : searchInfo.companyInfo.cityShortName,
        cityLong: searchInfo.subjectInfo.cityLongName
          ? searchInfo.subjectInfo.cityLongName
          : searchInfo.companyInfo.cityLongName,
        stateShort: searchInfo.subjectInfo.stateShortName
          ? searchInfo.subjectInfo.stateShortName
          : searchInfo.companyInfo.stateShortName,
        stateLong: searchInfo.subjectInfo.stateLongName
          ? searchInfo.subjectInfo.stateLongName
          : searchInfo.companyInfo.stateLongName,
        countryLong: searchInfo.subjectInfo.countryLongName
          ? searchInfo.subjectInfo.countryLongName
          : searchInfo.companyInfo.countryLongName,
        countryShort: searchInfo.subjectInfo.countryShortName
          ? searchInfo.subjectInfo.countryShortName
          : searchInfo.companyInfo.countryShortName,
        email: searchInfo.subjectInfo.email,
        college: searchInfo.subjectInfo.collage,
        phone: searchInfo.subjectInfo.phoneNumber,
        company: searchInfo.subjectInfo.companyName,
        twitterUrl: searchInfo.socialMedia.x,
        facebookUrl: searchInfo.socialMedia.facebook,
        instagramUrl: searchInfo.socialMedia.instagram,
        tikTokUrl: searchInfo.socialMedia.tikTok,
        linkedInUrl: searchInfo.socialMedia.linkedIn,
        pinterestUrl: searchInfo.socialMedia.pinterest,
        ageOrDateOfBirth: searchInfo.subjectInfo.dob,
        pdfCoreFindings: searchInfo.reportSetup.pdfCoreFindings,
        pdfFlaggedBehaviors: searchInfo.reportSetup.pdfFlaggedBehaviors,
        pdfLanguage: searchInfo.reportSetup.pdfLanguage,
        pdfNegativePosts: searchInfo.reportSetup.pdfNegativePosts,
        pdfNeutralPosts: searchInfo.reportSetup.pdfNeutralPosts,
        pdfPositivePosts: searchInfo.reportSetup.pdfPositivePosts,
        pdfSentimentAnalysis: searchInfo.reportSetup.pdfSentimentAnalysis,
        pdfSocialMediaAccounts: searchInfo.reportSetup.pdfSocialMediaAccounts,
        pdfWebSearchPosts: searchInfo.reportSetup.pdfWebSearchPosts,
        pdfPersonalityAndSkills: searchInfo.reportSetup.pdfPersonalityAndSkills,
        pdfEducationAndCareer: searchInfo.reportSetup.pdfEducationAndCareer,
        topics: sortedTopics,
        topicsIds: searchInfo.firstStep.topicsIds || [],
        reportType: searchInfo.selectSearchType.searchType,
        useCase: searchInfo.useCases.selectedUseCaseId,
        optionalCompliance: searchInfo.useCases.consentSwitchValue,
        website: searchInfo.companyInfo.website,
        industry: searchInfo.companyInfo.industry,
        picture: searchInfo.subjectInfo.image,
      }
      const filteredPayload = filterEmptyFields(requestPayload, 'lastName')

      const newSearchRequest = await newSearch({
        ...filteredPayload,
        workspaceId: selectedWorkspace?._id,
        teamId: selectedTeam?._id,
      }).unwrap()
      const { info } = newSearchRequest

      if (
        searchInfo.consent.file !== null &&
        searchInfo.consent.file !== undefined &&
        searchInfo.consent.file
      ) {
        const formData = base64ToFormData(searchInfo.consent.file, 'signed.pdf')
        await saveSignedPdf({
          id: info.id,
          document: formData,
        }).unwrap()
      } else if (searchInfo.useCases.consentSwitchValue) {
        await sendEmailWithDocument({
          id: info.id,
          documentToSend: searchInfo.consent.subjectLocationValue,
          businessDocument: searchInfo.consent.businessLocationValue,
          email: searchInfo.consent.consentEmail,
        }).unwrap()
      }

      trackEvent(Events.REPORT_NEW, userID, {
        type_of_report: searchInfo.selectSearchType.searchType,
        type_of_topics: searchInfo.firstStep.topics.join(', '),
      })

      const consentSentEmail = searchInfo.consent.consentEmail

      dispatch(clearNewSearch())
      dispatch(clearTokens())

      navigate(AppRoutes.reportInProgress, {
        state: {
          consentSentEmail: consentSentEmail,
        },
      })
    } catch (e) {
      catchWithSentry(e)
      toast.error(t('feedback.toast.errorOnSearch'))
    }
  }, [searchInfo])

  const handleCheckboxChange = (newChecked: boolean) => {
    setIsChecked(newChecked)
  }

  return (
    <div className="flex flex-col gap-8 h-full">
      <GenerateReportHeader
        form="socialMedia"
        onClickNext={makeRequestV2}
        isLoading={isLoading}
        disabled={!isChecked}
        customButton={
          <Button
            variant="secondary"
            onClick={() => navigate(AppRoutes.dashboard)}
          >
            {t('dashboard.newSearch.legalDisclaimer.cancel')}
          </Button>
        }
      />

      <div className="flex flex-col gap-8 lg:px-8 px-4 py-4 flex-1 justify-between">
        <div className="flex flex-col justify-center items-center gap-6">
          <div className="flex flex-col items-center gap-3 justify-center text-center">
            <p className="text-neutralColor text-textNormal text-xl font-medium tracking-wide">
              {t('dashboard.newSearch.legalDisclaimer.doubleCheckYourInputs')}
            </p>
          </div>

          <div className="flex flex-col items-center gap-6">
            {/* Inputs Section */}
            <InputsSection searchInfo={searchInfo} />

            {/* Focused Topics */}
            {searchInfo?.firstStep.topics.length !== 0 && (
              <div className="flex flex-col items-center gap-3">
                <div className="flex flex-row gap-3">
                  {searchInfo?.firstStep.topics?.map((topic) => (
                    <Chip green isBigChip text={topic} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div>
          <ReportSetup />
        </div>

        <div className="flex flex-col items-center gap-4 mb-10">
          <div className="flex gap-2 items-center">
            <Checkbox
              className="w-5 h-5 rounded-md"
              onCheckedChange={handleCheckboxChange}
            />

            <p className="text-neutralColor text-textNormal text-sm font-medium tracking-tight">
              {t('dashboard.newSearch.legalDisclaimer.checkingBox')}
              <span
                className="text-linkColor"
                role="button"
                onClick={() =>
                  window.open(
                    t('dashboard.common.termsAndConditionsLink'),
                    '_blank',
                  )
                }
                tabIndex={0}
              >
                &nbsp;
                {t('dashboard.newSearch.legalDisclaimer.termsAndConditions')}
              </span>
              {t('dashboard.newSearch.legalDisclaimer.and')}&nbsp;
              <span
                className="text-linkColor"
                role="button"
                onClick={() =>
                  window.open(t('dashboard.common.privacyPolicyLink'), '_blank')
                }
                tabIndex={0}
              >
                {t('dashboard.newSearch.legalDisclaimer.privacyPolicy')}
              </span>
              . 1 {t('dashboard.newSearch.legalDisclaimer.creditDeducted')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
