import { zodResolver } from '@hookform/resolvers/zod'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { LottieSpinner } from '@/components/LottieSpinner/LottieSpinner'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
} from '@/components/ui/select'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import {
  useUpdateUserDataMutation,
  useUserInfoQuery,
} from '@/store/api/Users/Users.query'
import type { profileType } from '@/utils/schemas'
import { profileSchema } from '@/utils/schemas'

export const Profile = () => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation()

  const { data, isLoading: isLoadingUserData } = useUserInfoQuery()
  const [updateUserData, { isLoading: isLoadingUpdateData }] =
    useUpdateUserDataMutation()

  const [selectedLanguage, setSelectedLanguage] = useState<string>(language)

  const form = useForm<profileType>({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      firstName: data?.firstname,
      lastName: data?.lastname,
      phoneNumber: data?.phoneNumber,
      mail: data?.email,
    },
    mode: 'onChange',
  })
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = form

  const handleUpdateUserData = async (formData: profileType) => {
    try {
      await updateUserData({
        phoneNumber: formData.phoneNumber,
        firstName: formData.firstName,
        lastName: formData.lastName,
        language: selectedLanguage,
      }).unwrap()
      toast.success(t('feedback.toast.changesSaved'))
    } catch (error) {
      toast.error(t('feedback.toast.errorSavingChanges'))
      catchWithSentry(error)
    }
  }

  const onSubmit = (formData: profileType) => {
    changeLanguage(selectedLanguage)
    handleUpdateUserData(formData)
  }

  const handleLanguageChange = (newLanguage: string) => {
    setSelectedLanguage(newLanguage)
  }

  const initials = useMemo(() => {
    if (!data?.firstname || !data.lastname) {
      return ''
    }

    const firstInitial = data.firstname[0]
      ? data.firstname[0][0].toUpperCase()
      : ''
    const secondInitial = data.lastname[2]
      ? data.lastname[0][0].toUpperCase()
      : ''
    return `${firstInitial} ${secondInitial}`
  }, [data?.firstname, data?.lastname])

  if (isLoadingUserData) {
    return (
      <LottieSpinner
        width={320}
        height={320}
        text={t('feedback.common.loading')}
      />
    )
  }

  return (
    <div>
      <div className="flex flex-row gap-4 items-center mb-3">
        {data?.firstname && (
          <div className="flex justify-center items-center rounded-full w-20 h-20 bg-baseColor">
            {initials}
          </div>
        )}
        <p className="text-neutralColor text-xl">
          {data?.firstname} {data?.lastname}
        </p>
      </div>

      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className="text-neutralColor text-xl font-medium tracking-wide font-satoshi mb-4">
            {t('dashboard.account.languageTitle')}
          </p>
          <p className="text-neutralColor80 text-sm font-medium mb-2">
            {t('dashboard.account.languageContent')}
          </p>
          <div className="flex gap-2 mb-4">
            <Select onValueChange={handleLanguageChange}>
              <SelectTrigger className="w-full lg:w-2/6 rounded-3xl">
                {selectedLanguage === 'es'
                  ? t('dashboard.account.spanish')
                  : t('dashboard.account.english')}
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel className="h-[34px] px-2 py-1.5 text-neutralColor text-base font-medium tracking-tight">
                    {t('dashboard.account.selectLanguage')}
                  </SelectLabel>
                  <SelectItem value="es">
                    {t('dashboard.account.spanish')}
                  </SelectItem>
                  <SelectItem value="en">
                    {t('dashboard.account.english')}
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col ">
            <p className="text-neutralColor text-xl font-semibold tracking-wide font-satoshi mb-4">
              {t('dashboard.account.yourDetails')}
            </p>
            <div className="flex lg:flex-row flex-col lg:justify-between mb-6">
              <FormField
                disabled={false}
                control={control}
                name="firstName"
                render={({ field }) => (
                  <FormItem className="lg:w-[48%] w-full">
                    <FormLabel className="text-neutralColor80 text-sm font-medium tracking-tight">
                      {t('dashboard.account.firstName')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder={data?.firstname}
                        className="rounded-3xl"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                disabled={false}
                control={control}
                name="lastName"
                rules={{ required: false }}
                render={({ field }) => (
                  <FormItem className="lg:w-[48%] w-full">
                    <FormLabel className="text-neutralColor80 text-sm font-medium tracking-tight">
                      {t('dashboard.account.lastName')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder={data?.lastname}
                        className="rounded-3xl"
                      />
                    </FormControl>
                    <FormMessage data-name="lastName" />
                  </FormItem>
                )}
              />
            </div>
            <p className="text-neutralColor text-xl font-semibold tracking-wide font-satoshi mb-4">
              {t('dashboard.account.contactInfo')}
            </p>
            <div className="flex lg:flex-row flex-col lg:justify-between mb-6">
              <FormField
                disabled={false}
                control={control}
                name="phoneNumber"
                rules={{ required: false }}
                render={({ field }) => (
                  <FormItem className="lg:w-[48%] w-full">
                    <FormLabel className="text-sm text-neutralColor80 font-medium tracking-tight">
                      {t('dashboard.account.phoneNumber')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder={data?.phoneNumber}
                        className="rounded-3xl"
                      />
                    </FormControl>
                    <FormMessage data-name="phoneNumber" />
                  </FormItem>
                )}
              />
              <FormField
                disabled={true}
                control={control}
                defaultValue={data?.email}
                name="mail"
                rules={{ required: false }}
                render={({ field }) => (
                  <FormItem className="lg:w-[48%] w-full">
                    <FormLabel className="text-sm font-medium tracking-tight text-neutralColor80">
                      {t('dashboard.account.email')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder={data?.email}
                        className="rounded-3xl"
                      />
                    </FormControl>
                    <FormMessage data-name="mail" />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="flex  lg:justify-end lg:items-end">
            <Button
              type="submit"
              className="mt-6 w-full lg:w-[75px] px-4 py-2 h-[38px]"
              isLoading={isLoadingUpdateData}
              disabled={!isDirty}
            >
              {t('dashboard.account.save')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
