import { createSlice } from '@reduxjs/toolkit'

import type { WorkspaceCredits } from '../workspace/workspace.types'

import type {
  Enterprise,
  pdfCustomConfig,
  Team,
  WorkStation,
} from './Users.types'

const defaultPdfCustomConfig: pdfCustomConfig = {
  pdfCoreFindings: false,
  pdfFlaggedBehaviors: false,
  pdfFocusedTopics: false,
  pdfLanguage: 'en',
  pdfNegativePosts: false,
  pdfNeutralPosts: false,
  pdfPositivePosts: false,
  pdfSentimentAnalysis: false,
  pdfSocialMediaAccounts: false,
  pdfWebSearchPosts: false,
  pdfPersonalityAndSkills: false,
  pdfEducationAndCareer: false,
}
export interface userSliceState {
  userId: string
  firstname: string
  lastname: string
  email: string
  enterprise: Enterprise | undefined
  phoneNumber: string
  role: string
  permissions: string[]
  enterpriseName: string
  hasMinorSearch: boolean
  status: string
  from: string
  findPeoplePrice: string
  analyzePeoplePrice: string | null
  pdfConfig: pdfCustomConfig
  aiReportCredits: number
  aiAnalystReportCredits: number
  adverseMediaReportCredits: number
  assistedReportCredits: number
  facialMatchingCredits: number
  workStations: WorkStation[]
  selectedWorkStation: WorkStation | null
  selectedTeam: Team | null
  workspaceCredits: WorkspaceCredits | null
}

export const userInfoSliceInitialState: userSliceState = {
  userId: '',
  firstname: '',
  lastname: '',
  email: '',
  enterprise: undefined,
  phoneNumber: '',
  role: '',
  permissions: [],
  enterpriseName: '',
  status: '',
  from: '',
  hasMinorSearch: false,
  findPeoplePrice: '',
  analyzePeoplePrice: null,
  pdfConfig: defaultPdfCustomConfig,
  aiReportCredits: 0,
  aiAnalystReportCredits: 0,
  adverseMediaReportCredits: 0,
  assistedReportCredits: 0,
  facialMatchingCredits: 0,
  workStations: [],
  selectedWorkStation: null,
  selectedTeam: null,
  workspaceCredits: null,
}

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: userInfoSliceInitialState,
  reducers: {
    setUserInfo: (state, action) => {
      const userInfo = action.payload
      state.userId = userInfo.id
      state.firstname = userInfo.firstname
      state.lastname = userInfo.lastname
      state.email = userInfo.email
      state.enterprise = userInfo.enterprise
      state.phoneNumber = userInfo.phoneNumber
      state.role = userInfo.role
      state.permissions = userInfo.permissions
      state.enterpriseName = userInfo.enterprise.name
      state.status = userInfo.status
      state.hasMinorSearch = userInfo.enterprise.minorSearch
      state.findPeoplePrice = userInfo.enterprise.findPeoplePrice
      state.analyzePeoplePrice = userInfo.enterprise.analyzePeoplePrice
      state.pdfConfig = userInfo.pdfConfig
      state.facialMatchingCredits =
        userInfo.enterprise.credits.facialMatchingCredits
      state.adverseMediaReportCredits =
        userInfo.enterprise.credits.adverseMediaReportCredits
      state.aiAnalystReportCredits =
        userInfo.enterprise.credits.aiAnalystReportCredits
      state.assistedReportCredits =
        userInfo.enterprise.credits.assistedReportCredits
      state.findPeoplePrice = userInfo.enterprise.credits.findPeoplePrice
      state.workStations = userInfo.workStations
      // TODO persist on BE the selected workStation and team
      if (userInfo.workStations && userInfo.workStations.length > 0) {
        state.selectedWorkStation = userInfo.workStations[0]
        state.selectedTeam = userInfo.workStations[0]?.teams?.[0] || null
      } else {
        state.selectedWorkStation = null
        state.selectedTeam = null
      }
    },
    setUserOrigin: (state, action) => {
      state.from = action.payload
    },
    setSelectedWorkStation: (state, action) => {
      state.selectedWorkStation = action.payload
    },
    setSelectedTeam: (state, action) => {
      state.selectedTeam = action.payload
    },
    setSelectedWorkspaceById: (state, action) => {
      const workspaceId = action.payload

      if (workspaceId === 'all') {
        state.selectedWorkStation = null
      } else {
        const selectedWorkspace = state.workStations.find(
          (w) => w._id === workspaceId,
        )

        if (selectedWorkspace) {
          state.selectedWorkStation = selectedWorkspace
        }
      }
    },
    setSelectedTeamById: (state, action) => {
      const teamId = action.payload

      if (teamId === 'all') {
        state.selectedTeam = null
      } else {
        const selectedTeam = state.selectedWorkStation?.teams.find(
          (t) => t._id === teamId,
        )
        if (selectedTeam) {
          state.selectedTeam = selectedTeam
        }
      }
    },
    setWorkspaceCredits: (state, action) => {
      state.workspaceCredits = action.payload
    },
  },
})
export const {
  setUserInfo,
  setUserOrigin,
  setSelectedWorkStation,
  setSelectedTeam,
  setWorkspaceCredits,
  setSelectedTeamById,
  setSelectedWorkspaceById,
} = userInfoSlice.actions

export default userInfoSlice.reducer
