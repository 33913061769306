import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'
import type { IPwnedBreach } from '@/store/api/Person/Person.types'

import { EmptyStateCard, EmptyStateCardIcon } from './EmptyStateCard'
import { InfoDisplayItem } from './InfoDisplayItem'

interface PwnedBreachesWidgetProps {
  pwnedBreaches: IPwnedBreach[]
}

export const PwnedBreachesWidget = ({
  pwnedBreaches,
}: PwnedBreachesWidgetProps) => {
  const { t } = useTranslation()
  return (
    <Card className="p-4 flex flex-col gap-5 max-h-[335px] overflow-y-scroll custom-scrollbar rounded-2xl">
      <p className="text-neutralColor text-xl font-medium">
        {t('dashboard.reportGeneralInfo.pwnedBreachesTitle')}
      </p>
      {pwnedBreaches.length !== 0 ? (
        pwnedBreaches.map((item, index) => (
          <InfoDisplayItem
            topText={item.Name}
            bottomText={`${item.Domain || 'Domain not available'} - ${
              item.BreachDate
            }`}
            imgUrl={item.LogoPath || 'useEmptyState'}
            imgAlt={item.Title}
            profileUrl={
              item.Domain.includes('http')
                ? item.Domain
                : `https://${item.Domain}`
            }
            key={index}
            disabled={item.Domain === ''}
            hoverInfo={item.Description}
          />
        ))
      ) : (
        <EmptyStateCard
          title={t('dashboard.reportGeneralInfo.pwnedBreachesEmpty')}
          icon={EmptyStateCardIcon.WORLD}
        />
      )}
    </Card>
  )
}
