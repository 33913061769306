import React from 'react'

import type { IPersonAttribute } from '@/store/api/Person/Person.types'

interface PersonaSnapshotCardTypes {
  title: string
  text: IPersonAttribute[]
  itemColor: string
}

export const PersonaSnapshotCard = ({
  title,
  text,
  itemColor,
}: PersonaSnapshotCardTypes) => {
  const formattedText = text.map((attribute) => attribute.trait).join(' - ')

  return (
    <div className="flex flex-row gap-3">
      <div className={`max-w-2 min-w-2 h-auto rounded-full ${itemColor}`} />
      <p className="text-neutralColor80 text-base font-normal">
        <span className="font-bold"> {`${title}: `}</span>
        {formattedText}
      </p>
    </div>
  )
}
