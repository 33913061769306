import Lottie from 'lottie-react'

import LoaderLottie from '../../assets/Loader.json'

export const LottieSpinner = ({
  width,
  height,
  text,
}: {
  width?: number
  height?: number
  text?: string
}) => {
  return (
    <div>
      <Lottie animationData={LoaderLottie} width={width} height={height} loop />
      <p className="text-base font-medium  text-center neutral-500 text-textAccent tracking-wider pb-6">
        {text}
      </p>
    </div>
  )
}
