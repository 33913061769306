import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'
import type { ISentimentPercentage } from '@/store/api/Person/Person.types'

interface SentimentBreakDownWidgetTypes {
  overview: string
  sentimentPercentages?: ISentimentPercentage[]
}

export const SentimentBreakDownWidget = ({
  overview,
  sentimentPercentages,
}: SentimentBreakDownWidgetTypes) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-5">
      <Card className="bg-baseColor flex flex-col gap-2 p-4">
        <p className="text-neutralColor text-xl font-medium">
          {t('dashboard.reportGeneralInfo.sentimentBreakdown')}
        </p>
        <p className="text-neutralColor80 text-base font-normal">{overview}</p>
      </Card>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          {sentimentPercentages?.map((sentiment, index) => (
            <Card
              key={index}
              className="flex flex-col gap-2 w-[30%] p-4 bg-baseColor"
            >
              <p className="text-neutralColor text-2xl font-medium">
                {sentiment.percentage.toFixed(1)}%
              </p>
              <div className="flex items-center justify-between">
                <p className="text-neutralColor80 text-sm font-normal">
                  {sentiment.sentiment}
                </p>
                <div className="w-2 h-2 rounded-full bg-dynamicGreen" />
              </div>
            </Card>
          ))}
        </div>
        <p className="text-neutralColor60 text-sm font-normal">
          {t('dashboard.reportGeneralInfo.sentimentBreakdownDescription')}
        </p>
      </div>
    </div>
  )
}
