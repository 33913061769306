import React from 'react'

import { Card } from '@/components/ui/card'
import type {
  IOnlineBehavior,
  IRedFlag,
  IStrengthWeakness,
} from '@/store/api/Person/Person.types'

interface ItemsListTypes {
  title: string
  items: IOnlineBehavior[] | IRedFlag[] | IStrengthWeakness[]
  propertyKey: 'behavior' | 'flag' | 'description'
}

export const ItemsList = ({ title, items, propertyKey }: ItemsListTypes) => {
  return (
    <Card className="flex flex-col gap-4 p-4 rounded-2xl bg-baseColor">
      <p className="text-neutralColor text-xl font-medium">{title}</p>
      <ul className="list-disc pl-5 flex flex-col gap-3">
        {items.map((item, index) => (
          <li key={index} className="text-neutralColor80 text-base font-normal">
            {(item as Record<string, any>)[propertyKey]}
          </li>
        ))}
      </ul>
    </Card>
  )
}
