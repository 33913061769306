import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'
import { Chip } from '@/pages/Home/components/Chip/Chip'
import type { ISkills } from '@/store/api/Person/Person.types'

import { EmptyStateCard, EmptyStateCardIcon } from './EmptyStateCard'

interface SkillsWidgetTypes {
  skills: ISkills[]
}

export const SkillsWidget = ({ skills }: SkillsWidgetTypes) => {
  const { t } = useTranslation()
  return (
    <Card className="flex flex-col bg-baseColor p-4 gap-4">
      <p className="flex text-neutralColor text-xl font-medium">
        {t('dashboard.reportGeneralInfo.skills')}
      </p>
      {skills.length !== 0 ? (
        <div className="flex gap-3 flex-wrap items-start">
          {skills.slice(0, 16).map((skill, index) => (
            <Chip text={skill.name} key={index} blue isMediumChip />
          ))}
        </div>
      ) : (
        <EmptyStateCard
          title={t('dashboard.reportGeneralInfo.skillsEmpty')}
          icon={EmptyStateCardIcon.PERSON}
        />
      )}
    </Card>
  )
}
