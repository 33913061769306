import { ArrowRight } from 'iconsax-react'
import React from 'react'
import { useDispatch } from 'react-redux'

import {
  setExternalFlagFilter,
  setExternalSentimentFilter,
  setSelectedTab,
} from '@/store/api/DigitalReport/digitalReport.slice'
import { FilterByTypeEnum } from '@/store/api/Person/Person.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface FlagCardTypes {
  name: string
  count: number
  isFocusedTopic?: boolean
}

export const FlagCard = ({
  name,
  count,
  isFocusedTopic = false,
}: FlagCardTypes) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(setSelectedTab('posts'))
    dispatch(setExternalSentimentFilter(FilterByTypeEnum.FLAGGED))
    dispatch(setExternalFlagFilter(name))
  }

  return (
    <div
      className={`flex flex-row py-2 px-3 justify-between hover:cursor-pointer hover:opacity-80 ${
        isFocusedTopic ? 'bg-alertRedBase' : 'bg-alertBlueBase'
      } rounded-[22px]`}
      onClick={handleClick}
    >
      <div className="flex flex-row items-center gap-2">
        <div
          className={`rounded-full h-4 w-4 px-1 py-0 ${
            isFocusedTopic ? 'bg-alertRedSecondary' : 'bg-alertBlueSecondary'
          } flex items-center justify-center`}
        >
          <p
            className={`${
              isFocusedTopic ? 'text-alertRedBase' : 'text-alertBlueBase'
            } text-xs font-medium`}
          >
            {count}
          </p>
        </div>
        <p
          className={`text-xs font-medium ${
            isFocusedTopic
              ? 'text-alertRedSecondary'
              : 'text-alertBlueSecondary'
          }`}
        >
          {name}
        </p>
      </div>
      <ArrowRight
        color={
          isFocusedTopic
            ? weCheckPalette.alertRedSecondary
            : weCheckPalette.alertBlueSecondary
        }
        height={16}
        width={17}
      />
    </div>
  )
}
