import React from 'react'

import EducationIcon from '@/assets/DigitalReport/EducationIcon.svg?react'
import JobIcon from '@/assets/DigitalReport/JobIcon.svg?react'
import PersonIcon from '@/assets/DigitalReport/PersonIcon.svg?react'
import WorldIcon from '@/assets/DigitalReport/WorldIcon.svg?react'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export enum EmptyStateCardIcon {
  EDUCATION = 'education',
  JOB = 'job',
  PERSON = 'person',
  WORLD = 'world',
}
interface EmptyStateCardTpes {
  title: string
  icon: EmptyStateCardIcon
}
export const EmptyStateCard = ({ title, icon }: EmptyStateCardTpes) => {
  return (
    <div className="flex items-center gap-3 w-full">
      <div className="flex items-center justify-center w-8 h-8">
        {icon === EmptyStateCardIcon.EDUCATION && (
          <EducationIcon color={weCheckPalette.neutralColor} />
        )}
        {icon === EmptyStateCardIcon.JOB && (
          <JobIcon color={weCheckPalette.neutralColor} />
        )}
        {icon === EmptyStateCardIcon.PERSON && (
          <PersonIcon color={weCheckPalette.neutralColor} />
        )}
        {icon === EmptyStateCardIcon.WORLD && (
          <WorldIcon color={weCheckPalette.neutralColor} />
        )}
      </div>
      <p className="text-neutralColor80 text-base font-normal">{title}</p>
    </div>
  )
}
