import React from 'react'

import useMediumMobileMediaQuery from '@/hooks/useMediumMobileMediaQuery'
import { textEllipsis } from '@/utils/strings'

interface ChipChartTypes {
  color: string
  company: string
  duration: string
}

export const ChipChart = ({ color, company, duration }: ChipChartTypes) => {
  const isMobile = useMediumMobileMediaQuery()
  return (
    <div className="flex gap-2 h-6 items-center w-[23%] max-md:w-[45%]">
      <div className="w-3 h-3 rounded-sm" style={{ backgroundColor: color }} />

      <div className="text-neutralColor text-xs font-medium">{duration}</div>
      <div className="text-neutralColor text-xs font-medium max-md:text-nowrap">
        {textEllipsis(company, isMobile ? 6 : 18)}
      </div>
    </div>
  )
}
