import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Card } from '@/components/ui/card'
import { AppRoutes } from '@/constants/appRoutes'
import { type SocialMediaAccount } from '@/store/api/Person/Person.types'

import { SocialMediaItem } from './SocialMediaItem'

interface SocialMediaAccountsWidgetProps {
  accounts: SocialMediaAccount[]
  socialCheckId: string
  subjectId: string
}

export const SocialMediaAccountsWidget = ({
  accounts,
  socialCheckId,
  subjectId,
}: SocialMediaAccountsWidgetProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Card className="p-4 max-h-[366px] flex flex-col gap-6 rounded-2xl bg-baseColor overflow-y-scroll custom-scrollbar">
      <div className="flex flex-row items-center justify-between">
        <h2 className="text-xl font-medium text-neutralColor">
          {t('dashboard.reportGeneralInfo.socialMediaAccounts')}
        </h2>
      </div>

      <div className="flex flex-col gap-6">
        {accounts.map((account) => (
          <SocialMediaItem
            userName={account.userName || 'Nameless'}
            profileUrl={account.profileLink}
            key={account.id}
            onClick={() => {
              navigate(AppRoutes.socialMediaProfile, {
                state: {
                  reportId: socialCheckId,
                  socialMedia: account.name,
                  socialMediaId: account.id,
                  subjectId,
                },
              })
            }}
          />
        ))}
      </div>
    </Card>
  )
}
