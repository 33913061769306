import type {
  getSubjectBasicInformationResponse,
  ISentimentAnalysisResponse,
} from '@/store/api/Person/Person.types'
import { EReportType } from '@/store/api/Person/Person.types'

import { CareerWidget } from '../Components/CareerWidget'
import { DigitalFootprintWidget } from '../Components/DigitalFootprintWidget'
import { DigitalPersonaSnapshotWidget } from '../Components/DigitalPersonaSnapshotWidget'
import { EducationWidget } from '../Components/EducationWidget'
import { FlagsWidget } from '../Components/FlagsWidget'
import { HorizontalBarChart } from '../Components/HorizontalBarChart'
import { PwnedBreachesWidget } from '../Components/PwnedBreachesWidget'
import { SentimentBreakDownWidget } from '../Components/SentimentBreakDownWidget'
import { SkillsWidget } from '../Components/SkillsWidget'
import { SocialMediaAccountsWidget } from '../Components/SocialMediaAccountsWidget'

interface DigitalProfileTypes {
  data: getSubjectBasicInformationResponse
  reportId: string
  reportType: EReportType
  subjectId: string
}

export const DigitalProfile = ({
  data,
  reportId,
  reportType,
  subjectId,
}: DigitalProfileTypes) => {
  const sentimentAnalysis = data?.info?.summary?.sentimentAnalysis
  const sentimentPercentages = data?.info?.sentimentPercentages || []
  const isNewReport = data?.info?.summary
    ? 'contentRadar' in data?.info?.summary
    : false

  const allPercentagesZero = sentimentPercentages.every(
    (percentage) => percentage.percentage.toFixed(0) === '0',
  )

  return (
    <div className="flex flex-col py-5 gap-5">
      {data?.info?.socialMedia?.length !== 0 &&
        reportType !== EReportType.AdverseMedia && (
          <SocialMediaAccountsWidget
            accounts={data?.info?.socialMedia || []}
            socialCheckId={reportId}
            subjectId={subjectId}
          />
        )}
      {!allPercentagesZero && sentimentPercentages[0].percentage !== null && (
        <SentimentBreakDownWidget
          overview={
            typeof sentimentAnalysis === 'string'
              ? sentimentAnalysis
              : sentimentAnalysis?.overview || ''
          }
          sentimentPercentages={sentimentPercentages}
        />
      )}
      <FlagsWidget risks={data?.info?.risks || []} />
      {(data?.info?.summary as ISentimentAnalysisResponse)
        ?.digitalPersonSnapshot && (
        <DigitalPersonaSnapshotWidget
          attributes={
            isNewReport
              ? (data.info.summary as ISentimentAnalysisResponse)
                  .digitalPersonSnapshot
              : undefined
          }
        />
      )}

      <HorizontalBarChart chartData={data?.info?.channelsValue || []} />
      <SkillsWidget skills={data?.info?.skills || []} />
      {data?.info?.career?.length !== 0 && (
        <CareerWidget career={data?.info?.career || []} />
      )}
      {data?.info?.education?.length !== 0 && (
        <EducationWidget education={data?.info?.education || []} />
      )}
      {data?.info?.digitalFootprints?.length !== 0 && isNewReport && (
        <DigitalFootprintWidget
          digitalFootprints={data?.info?.digitalFootprints || []}
        />
      )}
      <PwnedBreachesWidget
        pwnedBreaches={data?.info?.pwnedBreaches.breaches || []}
      />
    </div>
  )
}
